/* STYLE Document */
/* ===== IMPORT ====== */
/* ===== IMPORTS ====== */
/* ===== PADDING ====== */
/* ===== MARGIN ====== */
/* ===== TEXT - SIZE ====== */
/* ===== BUTTON ====== */
/* ===== BACKGROUND COLOR ====== */
/* ===== CAROUSEL ====== */
#carousel {
  position: relative;
  margin-top: 114px;
  overflow: hidden;
}
#carousel .carousel-img-txt {
  display: flex;
  justify-content: center;
}
#carousel .img {
  width: 100%;
  height: 100%;
}
#carousel button {
  border-radius: 0px;
  background: rgba(0, 0, 0, 0);
  cursor: pointer;
  padding: 1rem;
  display: flex;
  transform: scale(0.4);
}
#carousel button .arrow {
  border: solid #000;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 10px;
  transition: transform 0.3s ease-out;
  height: 0;
}
#carousel .btn {
  top: 88%;
  height: 58px;
  width: 58px;
}
#carousel .btn .slide2 {
  border-color: #fff;
}
#carousel #right-btn {
  right: -10px;
  position: absolute;
  margin: auto 0 auto -2rem;
}
#carousel #right-btn .arrow {
  transform: translateX(-5px) rotate(-45deg);
}
#carousel #left-btn {
  right: 45px;
  position: absolute;
  z-index: 1;
  margin: auto -2rem auto 0;
}
#carousel #left-btn .arrow {
  transform: translateX(5px) rotate(135deg);
}
.carousel-text-block-1,
.carousel-text-block-2,
.carousel-text-block-3 {
  display: flex;
  flex-direction: column;
  position: absolute;
  color: #fff;
  width: 100%;
  height: 100%;
  overflow: hidden;
  justify-content: center;
}
.carousel-text-block-1 div,
.carousel-text-block-2 div,
.carousel-text-block-3 div {
  display: flex;
  flex-direction: column;
}
.carousel-text-block-1 .text-principal,
.carousel-text-block-2 .text-principal,
.carousel-text-block-3 .text-principal,
.carousel-text-block-1 .text-secondaire,
.carousel-text-block-2 .text-secondaire,
.carousel-text-block-3 .text-secondaire {
  font-family: "worksans-bold";
}
.carousel-text-block-1 {
  padding-left: 30px;
  padding-right: 36%;
}
.carousel-text-block-1 .text-principal {
  line-height: 75px;
  font-size: 60px;
}
.carousel-text-block-2 {
  align-items: flex-end;
  justify-content: center;
}
.carousel-text-block-2 div {
  justify-content: center;
}
.carousel-text-block-2 .text-principal {
  font-size: 40px;
  color: #0483a3;
  text-align: center;
}
.carousel-text-block-2 .text-secondaire {
  font-size: 40px;
  text-align: center;
}
.carousel-text-block-3 {
  padding-left: 30px;
  padding-right: 60%;
  background: linear-gradient(90deg, #000000 -150%, rgba(255, 255, 255, 0) 60%);
}
.carousel-text-block-3 .text-principal {
  line-height: 75px;
  font-size: 60px;
}
@media (max-width: 1025px) {
  #carousel .btn {
    top: 85%;
  }
}
@media (max-width: 940px) {
  #carousel .btn {
    top: 82%;
  }
  .carousel-text-block-1 .text-principal,
  .carousel-text-block-3 .text-principal {
    line-height: 65px;
    font-size: 45px;
  }
  .carousel-text-block-2 .text-principal,
  .carousel-text-block-2 .text-secondaire {
    font-size: 30px;
  }
}
@media (max-width: 770px) {
  #carousel .btn {
    top: 77%;
  }
  .carousel-text-block-1 .text-principal,
  .carousel-text-block-3 .text-principal {
    line-height: 40px;
    font-size: 35px;
  }
  .carousel-text-block-2 .text-principal,
  .carousel-text-block-2 .text-secondaire {
    font-size: 23px;
  }
}
@media (max-width: 576px /*576*/) {
  #carousel {
    margin-top: 79px;
  }
  #carousel .btn {
    top: 73%;
  }
  .carousel-text-block-1 .text-principal,
  .carousel-text-block-3 .text-principal {
    line-height: 35px;
    font-size: 30px;
  }
  .carousel-text-block-2 .text-principal,
  .carousel-text-block-2 .text-secondaire {
    font-size: 20px;
  }
}
@media (max-width: 500px) {
  #carousel .btn {
    top: 88%;
  }
  #carousel button {
    transform: scale(0.5);
    border-radius: 50%;
    background: #0483a3;
  }
  #carousel button .arrow {
    border: solid 1px #fff;
    border-width: 0 4px 4px 0;
  }
  #carousel #left-btn {
    left: -10px;
    right: unset;
  }
  #carousel #right-btn {
    right: -10px;
  }
  .carousel-text-block-1 {
    align-items: center;
  }
  .carousel-text-block-1 .text-principal {
    text-align: center;
    padding-bottom: 16%;
    padding-right: 50px;
    line-height: 40px;
    font-size: 35px;
  }
  .carousel-text-block-2 div {
    padding-top: 75px;
    padding-right: 15px;
  }
  .carousel-text-block-2 .text-principal,
  .carousel-text-block-2 .text-secondaire {
    font-size: 25px;
  }
  .carousel-text-block-3 {
    align-items: flex-end;
    justify-content: flex-start;
    padding-top: 5%;
    padding-right: 0;
    padding-left: 63%;
    background: 0;
  }
  .carousel-text-block-3 .text-principal {
    line-height: 40px;
    font-size: 30px;
  }
}
@media (max-width: 450px) {
  #carousel .btn {
    top: 85%;
  }
  .carousel-text-block-1 .text-principal {
    line-height: 40px;
    font-size: 30px;
    padding-top: 20px;
    padding-right: 25px;
  }
  .carousel-text-block-2 div {
    padding-top: 67px;
  }
  .carousel-text-block-2 .text-principal,
  .carousel-text-block-2 .text-secondaire {
    font-size: 22px;
  }
  .carousel-text-block-3 .text-principal {
    line-height: 35px;
    font-size: 25px;
  }
}
@media (max-width: 376px) {
  .carousel-text-block-2 {
    padding-right: 0;
    padding-top: 40px;
  }
  .carousel-text-block-2 .text-principal,
  .carousel-text-block-2 .text-secondaire {
    font-size: 20px;
  }
  .carousel-text-block-3 .text-principal {
    line-height: 30px;
    font-size: 20px;
  }
}
@media (max-width: 350px) {
  #carousel .btn {
    top: 85%;
  }
  .carousel-text-block-1 {
    padding-top: 20.5%;
  }
  .carousel-text-block-1 .text-principal {
    font-size: 25px;
    padding-right: 45px;
  }
  .carousel-text-block-2 {
    padding-top: 35px;
  }
}
/* ===== IMPORT ====== */
/* ===== PROPERSO ====== */
#properso-selector {
  transform: translateY(-60px);
}
@media screen and (max-width: 576px) {
  #properso-selector {
    transform: translateY(-40px);
  }
}
/* ===== UTILITIES ====== */
#utilities-selector {
  width: 100%;
  margin-top: 40px;
  /*	overflow: scroll;*/
}
#utilities-selector::-webkit-scrollbar {
  background-color: red !important;
  width: 0 !important;
  height: 0 !important;
}
@media screen and (max-width: 576px) {
  #utilities-selector {
    margin-top: 25px;
    margin-left: 10px;
  }
}
#utilities-selector .utility-item {
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;
  cursor: pointer;
}
@media screen and (max-width: 576px) {
  #utilities-selector .utility-item {
    padding: 0 20px 20px 0;
    margin: 0;
  }
}
#utilities-selector .utility-item div.icon-container {
  height: 100px;
  width: 100px;
  z-index: 1;
  background-color: white;
  border-radius: 50%;
  transition: 0.25s ease 0s;
}
@media screen and (max-width: 576px) {
  #utilities-selector .utility-item div.icon-container {
    height: 70px !important;
    width: 70px !important;
  }
}
#utilities-selector .utility-item div.icon-container img {
  height: 100%;
  width: 100%;
  transform: scale(0.7);
}
#utilities-selector .utility-item p {
  padding-top: 25px;
  font-family: worksans-light, sans-serif !important;
  font-weight: lighter !important;
  font-size: 18px;
  transition: 0.25s ease 0s;
  margin-left: 20px;
}
#utilities-selector .utility-item.checked div.icon-container {
  background-color: #ffdeca;
  transform: scale(1.2);
}
#utilities-selector .utility-item.checked p {
  font-size: 21px;
  color: #202020 !important;
}
#utilities-selector .utility-item div.icon-step-one {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  border: 0.1px solid #0d4b5b60;
}
@media screen and (max-width: 576px) {
  #utilities-selector .utility-item div.icon-step-one {
    height: 100px !important;
    width: 100% !important;
    justify-content: start;
  }
  #utilities-selector .utility-item div.icon-step-one img {
    height: 70px !important;
    width: 70px !important;
    margin: 0 10px 0 15px !important;
  }
}
#utilities-selector .utility-item div.icon-step-one img {
  height: 100px;
  width: 100px;
  margin: 0 20px 0 25px;
}
#utilities-selector .utility-item div.icon-step-one p.text-step-one {
  padding-top: 25px;
  font-family: worksans-light, sans-serif !important;
  font-weight: lighter !important;
  font-size: 18px;
  transition: 0.25s ease 0s;
  margin-left: 0px;
  width: 50%;
  color: #073e4b;
}
@media screen and (max-width: 576px) {
  #utilities-selector .utility-item div.icon-step-one p.text-step-one {
    width: unset;
    margin-left: 10px;
  }
}
#utilities-selector .utility-item.checked div.icon-step-one {
  background-color: #ffdeca;
}
#utilities-selector .utility-item.checked div.icon-step-one p.text-step-one {
  font-size: 21px;
  font-weight: 900 !important;
  color: #073e4b !important;
  padding-bottom: 10px;
}
div.btn-rattachement:first-child {
  margin-right: 15px;
}
.btn-rattachement {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 120px;
  background-color: white;
  border-radius: 10px;
  border: 0.1px solid #0d4b5b60;
}
.btn-rattachement p {
  margin: 0;
  color: #0d4c5b;
}
.btn-rattachement-check {
  background-color: #ffdeca;
}
.btn-rattachement-check p {
  color: #073e4b !important;
  font-weight: 900 !important;
  font-size: 18px;
}
.btn-rattachement-disabled {
  background-color: #f2f9fa;
  color: #a4a4a4 !important;
}
/* ===== NUMBER SELECTOR ====== */
.number-selector {
  height: 52px;
  width: 140px;
  position: relative;
}
.number-selector select {
  height: 100%;
  width: 100%;
  background-color: rgba(4, 131, 163, 0.05);
  border-radius: 66px;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  text-indent: 50%;
  font-family: worksans-regular, sans-serif !important;
  font-weight: normal !important;
}
.number-selector img {
  position: absolute;
  top: 50%;
  right: 15px;
  height: 15px;
  width: auto;
  transform: translateY(-40%);
  pointer-events: none;
  cursor: pointer;
}
/* ===== UNITS SELECTOR ====== */
.units-selector {
  height: 52px;
  width: 140px;
  position: relative;
}
.units-selector select {
  height: 100%;
  width: 100%;
  background-color: rgba(4, 131, 163, 0.05);
  border-radius: 66px;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  text-indent: 25%;
  font-family: montserrat, sans-serif !important;
  font-weight: normal !important;
}
.units-selector img {
  position: absolute;
  top: 50%;
  right: 15px;
  height: 15px;
  width: auto;
  transform: translateY(-40%);
  pointer-events: none;
  cursor: pointer;
}
/* ===== IMPORT ====== */
/* ===== GLOBAL ====== */
.dashboard .title {
  font-family: montserrat-bold, sans-serif !important;
  font-weight: bold !important;
  font-size: 26px;
}
@media screen and (max-width: 576px) {
  .dashboard .title {
    line-height: 30px;
  }
}
.dashboard .subtitle {
  font-family: worksans-regular, sans-serif !important;
  font-weight: normal !important;
  font-size: 16px;
}
/* ===== IMPORT ====== */
/* ===== GLOBAL ====== */
#page .title-container {
  background-color: #0483a3;
  color: #ffffff;
  padding-bottom: 90px;
  margin-left: 0px;
  margin-right: 0px;
}
#page .title-container.light {
  background-color: rgba(4, 131, 163, 0.05);
  margin-left: 0px;
  margin-right: 0px;
}
#page .title-container.light h1 {
  color: #0d4c5b;
}
#page .title-container.light span {
  color: #676767;
}
#page .title-container h1 {
  font-family: montserrat-bold, sans-serif !important;
  font-weight: bold !important;
  font-size: 28px;
  margin-bottom: 0 !important;
}
@media screen and (max-width: 576px) {
  #page .title-container h1 {
    line-height: 45px !important;
  }
}
#page .title-container h2 {
  font-family: montserrat-bold, sans-serif !important;
  font-weight: bold !important;
  font-size: 36px;
  margin: 0;
}
@media screen and (max-width: 576px) {
  #page .title-container h2 {
    line-height: 45px !important;
  }
}
@media screen and (max-width: 450px) {
  #page .title-container h2 {
    font-size: 1.5rem;
  }
}
#page .title-container span {
  font-family: worksans-light, sans-serif !important;
  font-weight: lighter !important;
  font-size: 18px;
}
#page .board {
  transform: translateY(-110px);
}
/* ===== BOARD PAGE ====== */
#board {
  margin-left: 0px;
  margin-right: 0px;
  min-height: 850px !important;
}
/* ===== MOVING PAGE ====== */
#moving-start {
  min-height: 850px !important;
}
/* ===== BELL INSPECT ====== */
#bell-inspect {
  min-height: 900px !important;
}
/* ===== CONTACT ROLES ====== */
#contact-roles {
  min-height: 600px;
}
/* ===== PURCHASE UNITS ====== */
#purchase-units {
  min-height: 500px;
}
#account-invoices {
  min-height: 400px;
}
/* ===== ETAPE PAGE ====== */
.etape {
  font-size: 1.7rem;
  font-family: montserrat-bold, sans-serif !important;
  font-weight: bold !important;
  text-transform: uppercase;
  color: #0d4c5b;
}
@media screen and (max-width: 768px) {
  .etape {
    font-size: 1.4rem;
  }
}
/* nicolasC */
.title-containerEtape {
  background-color: #0d4c5b !important;
  padding: 10px 5px !important;
}
@media screen and (max-width: 450px) {
  .title-containerEtape h1 {
    font-size: 20px;
  }
}
.padding-bot-40 {
  padding-bottom: 40px !important;
}
/* ===== IMPORT ====== */
/* ===== GLOBAL CONFIG ====== */
#cookies,
#modal-container {
  z-index: 2000;
}
/* ===== COOKIES ====== */
#cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 60px;
  background-color: rgba(32, 32, 32, 0.9);
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
  padding: 40px 15vw;
  font-family: worksans-light, sans-serif;
  font-weight: lighter;
  text-align: justify;
}
@media screen and (max-width: 1694px) {
  #cookies {
    padding: 40px 5vw;
  }
}
@media screen and (max-width: 576px) {
  #cookies {
    flex-direction: column;
  }
}
#cookies a {
  cursor: pointer;
  color: #f75c02;
}
#cookies a:hover {
  text-decoration: underline;
}
#cookies button {
  margin-left: 25px;
  min-width: 140px;
}
@media screen and (max-width: 576px) {
  #cookies button {
    margin-left: 0;
    margin-top: 25px;
  }
}
/* ===== COOKIES ====== */
#modal-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(32, 32, 32, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
#modal-container #cookies-modal {
  position: relative;
  width: 30vw;
  background-color: #ffffff;
  border-radius: 60px;
  padding: 50px 50px 0 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 1680px) {
  #modal-container #cookies-modal {
    width: 50vw;
  }
}
@media screen and (max-width: 1024px) {
  #modal-container #cookies-modal {
    width: 70vw;
  }
}
@media screen and (max-width: 576px) {
  #modal-container #cookies-modal {
    width: 90vw;
  }
}
#modal-container #cookies-modal button {
  margin: 35px 0;
}
#modal-container #cookies-modal svg {
  height: 25px;
  width: 25px;
  cursor: pointer;
  position: absolute;
  top: 50px;
  right: 50px;
}
#modal-container #cookies-modal .cookies-modal-content {
  width: 100%;
  height: 410px;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
#modal-container #cookies-modal .cookies-modal-content h1,
#modal-container #cookies-modal .cookies-modal-content h2 {
  font-family: worksans-bold, sans-serif;
  font-weight: bold;
  margin: 0 0 15px 0;
  padding: 0;
}
#modal-container #cookies-modal .cookies-modal-content h1 {
  font-size: 1.4rem;
  line-height: 25px;
}
#modal-container #cookies-modal .cookies-modal-content h1 ~ p {
  max-width: 80%;
}
#modal-container #cookies-modal .cookies-modal-content p {
  font-family: worksans-light, sans-serif;
  font-weight: lighter;
}
#modal-container #cookies-modal .cookies-modal-content .cookie-presentation {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
#modal-container #cookies-modal .cookies-modal-content .cookie-presentation .textual-content {
  max-width: 70%;
}
#modal-container #cookies-modal .cookies-modal-content .cookie-presentation .textual-content h2 {
  margin-top: 25px;
}
#modal-container #cookies-modal .cookies-modal-content .cookie-presentation svg,
#modal-container #cookies-modal .cookies-modal-content .cookie-presentation .toggle {
  position: absolute;
  top: 25px;
  right: 0;
}
#modal-container #cookies-modal .cookies-modal-content .cookie-presentation svg {
  height: 35px;
  width: 35px;
}
/* ===== IMPORT ====== */
/* ===== GLOBAL ====== */
div[class*="claim"] .subtitle,
div#register .subtitle {
  font-family: worksans-light, sans-serif !important;
  font-weight: lighter !important;
  font-size: 16px;
}
/* ===== UNREGISTERED ====== */
#claim-unregistered .header {
  height: 272px;
  background-color: rgba(4, 131, 163, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#claim-unregistered .header .title {
  font-family: montserrat-bold, sans-serif;
  font-weight: bold;
  font-size: 36px;
}
#claim-unregistered .header .subtitle {
  font-family: worksans-light, sans-serif;
  font-weight: lighter;
  font-size: 18px;
  color: #626f73;
}
#claim-unregistered .products {
  margin-bottom: 90px;
}
#claim-unregistered .products .step-card.claim {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 120px;
  width: 100%;
}
#claim-unregistered .products .step-card.claim .illustration {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transform: scale(2);
  min-height: 100px;
  width: 100%;
  margin-bottom: 38px;
}
#claim-unregistered .products .step-card.claim div.text-content {
  font-family: worksans-light, sans-serif;
  font-weight: lighter;
  font-size: 18px;
  margin-top: 25px;
}
#claim-unregistered .products a.button {
  height: 52px;
  min-width: 52px;
  font-family: worksans-regular;
  font-size: 16px;
  border-radius: 25.5px;
  padding: 0 20px;
  background-color: #f75c02;
  color: #ffffff;
  border: none;
  outline: none;
}
#claim-unregistered .products a.button.step {
  min-width: 140px !important;
}
#claim-unregistered .products a.button.grey-bg {
  background-color: #a4a4a4 !important;
  cursor: not-allowed;
}
#claim-unregistered .products a.button.blueOpacity-bg {
  background-color: #0d4b5b60 !important;
  cursor: not-allowed;
}
#claim-unregistered .products a.button.blueOk-bg {
  background-color: #0d4c5b !important;
}
#claim-unregistered .products a.button.orangeOpacity-bg {
  background-color: #f75c023f !important;
  cursor: not-allowed;
}
#claim-unregistered .products .text-content {
  font-family: worksans-light, sans-serif;
  font-weight: lighter;
  font-size: 18px;
  color: #626f73;
}
@media screen and (max-width: 576px) {
  #claim-unregistered .mobile-section div[class~="col-12"] {
    padding: 0;
  }
}
#claim-unregistered .mobile-section button.next-mobile-btn {
  background-color: #f75c02 !important;
}
#claim-unregistered .mobile-section .steps-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0px;
  border-radius: 75px;
  background-color: #ffffff;
  transform: translateY(-50px);
}
@media screen and (max-width: 450px) {
  #claim-unregistered .mobile-section .steps-section {
    padding: 15px 20px 0px 20px;
  }
}
#claim-unregistered .mobile-section .steps-section .container {
  display: flex;
  width: 330px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
@media screen and (max-width: 450px) {
  #claim-unregistered .mobile-section .steps-section .container {
    width: 100% !important;
  }
}
#claim-unregistered .mobile-section .steps-section .container .step-container {
  margin: 25px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
#claim-unregistered .mobile-section .steps-section .container .step-container .text {
  margin-left: 15px;
  font-family: worksans-regular, sans-serifk, sans-serif;
  font-weight: normal;
  font-size: 14px;
  color: #626f73;
}
#claim-unregistered .mobile-section .info-section {
  background-color: rgba(4, 131, 163, 0.05);
  min-height: 50px;
  padding: 80px 0 !important;
  font-family: worksans-regular, sans-serif;
  font-weight: normal;
  color: #626f73;
}
/* ===== USAGE ====== */
#claim-usage {
  /*	background-color: rgba(@blue600, 5%);*/
}
#claim-usage #utilities-selector {
  align-items: center;
}
@media screen and (min-width: 450px) {
  #claim-usage #utilities-selector {
    justify-content: space-around !important;
  }
}
#claim-usage .white-space {
  min-height: 350px;
}
@media screen and (max-width: 576px) {
  #claim-usage .white-space {
    min-height: 200px;
  }
}
/* ===== CUSTOMIZE ====== */
#claim-customize h2.home-message {
  line-height: 35px !important;
}
@media screen and (max-width: 576px) {
  #claim-customize h2.home-message {
    font-size: 1.43rem !important;
    line-height: 25px !important;
  }
}
/* ===== CUSTOMIZE PARCOURS-CLIENT NicolasC ====== */
.claimPadding20 {
  padding: 20px;
}
.btnRevenirSuivant {
  margin: 0 auto;
}
.withNext {
  justify-content: space-between;
}
.btnRevenirSuivant img {
  width: 10px;
  margin-left: 15px;
}
.bouttonRevenir button {
  background-color: transparent !important;
  color: #0d4c5b;
}
.step-class-h1 h1 {
  font-size: 20px !important;
}
.claimTitle {
  text-align: left;
}
@media screen and (min-width: 576px) {
  .claimTitle {
    text-align: center;
  }
}
.claimTitleCenter {
  text-align: center;
}
.claimText {
  text-align: center;
}
.claim-bold span {
  font-weight: normal;
  font-size: 1rem;
}
.claim-bold p {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 5px;
}
.color-blue600 {
  color: #0d4c5b;
}
.upper {
  text-transform: uppercase;
}
.claimFormALert {
  padding: 0 20px;
}
.claimBackground {
  background-color: #f2f9fa;
}
.claimLink {
  display: flex;
  justify-content: center;
}
.claimLink a {
  font-size: 1.3rem;
  color: #0d4c5b;
}
.claimLink a:hover {
  color: #087b98;
}
.infoAlert {
  /* font-style: oblique; */
  color: #0d4b5bd0;
  font-size: 0.85rem;
}
.bold {
  font-weight: bold;
}
.cgu {
  font-size: 0.82rem;
}
#btn-icons a:hover {
  color: white;
}
#btn-icons a:active {
  background-color: #073e4b;
}
.claimBackground a {
  text-decoration: none !important;
}
.btn-board {
  color: white;
  background-color: #0d4c5b;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto !important;
  width: 300px !important;
}
@media screen and (min-width: 576px) {
  .btn-board {
    min-width: 390px;
  }
}
.btn-board:hover {
  color: white;
}
.btn-icon img {
  width: 25px;
  margin-right: 15px;
}
.info-title {
  font-size: 1.2rem;
}
.color-title {
  color: #0d4c5b;
}
.info-text {
  font-size: 0.9rem;
  font-style: oblique;
}
.no-form-container .form-container {
  background-color: transparent;
  padding: 0;
  margin: 35px 0;
}
.labelPassword {
  left: 10px !important;
}
.claim-textarea {
  border: 0.5px solid #0483a3;
  border-radius: 10px;
  background-color: #fff;
}
.claim-textarea textarea {
  padding: 15px;
  border: none;
  background: none;
  box-shadow: none;
  resize: none;
  width: 100%;
  text-align: left !important;
  font-size: 0.9rem;
}
.label-for-textarea {
  color: #0483a3;
  transform: scale(0.75);
  left: -15px !important;
  position: absolute;
  top: 23px;
}
.label-for-message {
  color: #0483a3;
  transform: scale(0.75);
  left: 5px !important;
  position: absolute;
  top: 23px;
}
/* CLAIM_DONE */
.claim-done-title {
  margin-top: 20px;
}
.claim-done-title p:first-child {
  font-size: 1rem;
  font-weight: 400;
}
.claim-done-title .claim-done-item {
  font-size: 1.3rem;
  margin: 10px 0;
}
.claim-done-title .claim-done-horaire {
  font-size: 0.9rem;
}
/* PERSONNALISATION */
.btn-suppr-contact {
  background-color: transparent;
  border: 1px solid red;
  color: #f00;
  height: 40px;
  width: 230px;
}
#timepicker-content {
  color: #0d4c5b;
  padding: 0;
  justify-content: space-between;
  width: 100%;
}
@media screen and (min-width: 576px) {
  #timepicker-content {
    width: 60%;
  }
}
#timepicker-content .timepicker-group:first-child {
  margin-right: 30px;
}
#timepicker-content .time-picker {
  position: relative;
}
#timepicker-content .time-picker .prefix {
  color: #0483a3;
  top: -28px;
  left: -5px;
  transform: scale(0.75);
  position: absolute;
}
#timepicker-content .time-picker select:after {
  background-color: red !important;
}
#timepicker-content .time-picker .selector {
  margin: 0;
  color: #0483a3;
  border: 1px solid #0d4c5b;
  background-color: white;
}
#timepicker-content .time-picker .separator {
  margin: 0 5px;
}
/* CLAIM CUSTOMIZE */
#toggle-customize .custom-control {
  min-height: 2.5rem;
}
#toggle-customize .custom-switch {
  width: 100%;
}
.responsive-toggle {
  width: 100%;
}
@media screen and (min-width: 576px) {
  .responsive-toggle {
    width: 60%;
  }
}
/* Premier appel */
span.block-premier-appel {
  border-radius: 10% !important;
  background-color: #0d4c5b;
  color: white;
}
span.block-appel-suivant {
  border-radius: 10% !important;
  border: 1px solid #0d4c5b;
  background-color: white;
  color: white;
}
#claimForm #step-revenir img,
#sign-in #step-revenir img {
  margin-left: 0;
}
/* ===== IMPORT ====== */
/* ===== VARIABLES ====== */
/* ===== GLOBAL ====== */
#home-bar.purchase {
  position: fixed;
}
#home-bar.purchase + div#purchase.desktop {
  margin-top: 75px;
}
.serviceCardMobileVersion .service-card {
  width: 100% !important;
  flex-direction: column !important;
  align-items: center !important;
}
.serviceCardMobileVersion .service-card .textual-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.serviceCardMobileVersion .service-card .textual-container p {
  text-align: center;
}
.serviceCardMobileVersion .service-card .right-content {
  flex-direction: column;
}
.serviceCardMobileVersion .service-card .right-content div.column {
  margin-left: 0;
  margin-top: 20px;
}
.serviceCardMobileVersion .service-card .right-content .button {
  margin-top: 25px;
  display: flex;
}
.serviceCardMobileVersion .service-card .right-content .delete-button {
  display: none;
}
.grid-view {
  margin: 40px 0;
  padding: 0 40px;
  display: grid;
  grid-template-columns: auto 300px;
  grid-template-rows: auto;
  align-items: start;
}
@media screen and (max-width: 450px) {
  .grid-view {
    margin-top: 0;
    padding: 0 4%;
  }
}
@media screen and (max-width: 1090px) {
  .grid-view.purchase-support {
    grid-template-columns: 100% !important;
    grid-row-gap: 80px !important;
    justify-items: stretch !important;
  }
  .grid-view.purchase-support .right-menu > div {
    width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .grid-view.purchase-support {
    padding: 0;
  }
}
@media screen and (max-width: 980px) {
  .grid-view.purchase-services {
    grid-template-columns: 100% !important;
    grid-row-gap: 80px !important;
    justify-items: stretch !important;
  }
  .grid-view.purchase-services .right-menu > div {
    width: 100%;
  }
}
@media screen and (max-width: 1150px) {
  .grid-view.purchase-delivery {
    grid-template-columns: 100% !important;
    grid-row-gap: 80px !important;
    justify-items: stretch !important;
  }
  .grid-view.purchase-delivery .right-menu > div {
    width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .grid-view.purchase-delivery {
    padding: 0;
    grid-template-columns: 100vw;
  }
}
@media screen and (max-width: 1380px) {
  .grid-view.purchase-confirm {
    grid-template-columns: 100% !important;
    grid-row-gap: 80px !important;
    justify-items: stretch !important;
  }
  .grid-view.purchase-confirm .right-menu > div {
    width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .grid-view.purchase-confirm {
    width: 100vw !important;
  }
}
@media screen and (max-width: 450px) {
  .right-menu {
    padding: 0 3%;
  }
}
.right-menu-content {
  width: 300px;
  min-height: 200px;
  border-radius: 66px;
  position: relative;
  margin-bottom: 25px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
.right-menu-content:last-of-type {
  margin-bottom: 0;
}
/* ===== DELETE BUTTON ====== */
.delete-button {
  width: 31px;
  height: 31px;
  border-radius: 31px;
  border: 2px solid #f75c02;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.delete-button svg {
  height: 70%;
  width: auto;
}
.delete-button svg path {
  fill: #f75c02;
}
/* ===== SERVICE CARD ====== */
.service-card {
  width: 95%;
  min-height: 124px;
  border-radius: 66px;
  padding: 20px 60px;
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media screen and (max-width: 450px) {
  .service-card {
    padding: 30px !important;
  }
  .service-card .textual-container {
    width: 90% !important;
    margin-bottom: 30px;
  }
  .service-card .textual-container p {
    width: 100% !important;
  }
  .service-card div.right-content {
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
  }
  .service-card div.right-content .select-container {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .service-card div.right-content div.column {
    margin-left: 0;
  }
}
.service-card .textual-container .title {
  font-family: montserrat-regular, sans-serif !important;
  font-weight: normal !important;
  font-size: 16px;
  line-height: 24px !important;
  text-align: center;
  color: #073e4b;
}
.service-card .textual-container .description,
.service-card .textual-container .more-info {
  font-size: 14px;
  line-height: 20px;
}
.service-card .textual-container .description {
  font-family: worksans-regular, sans-serif !important;
  font-weight: normal !important;
  height: 40px;
  width: 250px;
  color: #a4a4a4;
  margin-top: 8px;
  overflow: hidden;
}
.service-card .textual-container .description.full-info {
  height: auto;
}
.service-card .textual-container .more-info {
  font-family: worksans-bold, sans-serif !important;
  font-weight: bold !important;
  color: #f75c02;
  cursor: pointer;
}
.service-card .quota-container span:last-of-type,
.service-card .right-content .select-container span {
  font-family: worksans-regular, sans-serif !important;
  font-weight: normal !important;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #0483a3;
}
.service-card .quota-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.service-card .quota-container span {
  color: #073e4b;
  text-align: center;
}
.service-card .quota-container span:first-of-type {
  font-family: montserrat-regular, sans-serif !important;
  font-weight: normal !important;
  font-size: 14px;
  line-height: 30px;
  color: #073e4b;
}
.service-card .right-content {
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.service-card .right-content > div.column {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  min-width: 0px;
  margin-left: 40px;
}
.service-card .right-content > div.column .select-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}
.service-card .right-content > div.column .select-container.disabled select {
  color: #a4a4a4;
  border-color: #a4a4a4;
}
.service-card .right-content > div.column .select-container.disabled span {
  color: #a4a4a4;
}
.service-card .right-content > div.column .select-container select {
  width: 80px;
  height: 40px;
  border-radius: 66px;
  border: 2px solid #0483a3;
  color: #0483a3;
  text-indent: 50%;
  margin-bottom: 10px;
}
.service-card .right-content > div.column .toggle {
  width: 75px;
  margin-bottom: 10px;
  background-color: #073e4b;
}
.service-card .right-content > div.column .toggle.enabled {
  background-color: #a3ce54;
}
.service-card .right-content > div.column .toggle.enabled .toggle-circle {
  left: 60%;
}
.service-card .right-content > div.column .price {
  font-family: montserrat-regular, sans-serif !important;
  font-weight: normal !important;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #073e4b;
}
.service-card .right-content .button {
  display: none;
}
.service-card .right-content .delete-button {
  margin-left: 25px;
  display: flex;
}
/* ===== BACK BUTTON ====== */
.back-button-container {
  width: 100%;
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 60px;
  cursor: pointer;
}
@media screen and (max-width: 450px) {
  .back-button-container {
    justify-content: flex-start;
    height: 10px;
  }
  .back-button-container #back-button {
    border: none !important;
    color: transparent !important;
    width: 50px;
    position: relative;
  }
  .back-button-container #back-button .circle {
    position: absolute;
    top: -58px;
  }
}
.back-button-container #back-button {
  width: 160px;
  border: 1px solid #f75c02;
  border-radius: 66px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 52px;
  padding: 0 20px;
  color: #f75c02;
}
.back-button-container #back-button .circle {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border: 1px solid #f75c02;
  border-radius: 30px;
  margin-right: 15px;
}
.back-button-container #back-button .circle svg {
  height: 15px !important;
  width: 15px !important;
}
.back-button-container #back-button .circle svg path {
  fill: #f75c02;
}
/* ===== SUPPORT INFO TABS ====== */
#support-info-tabs .title {
  font-family: montserrat-bold, sans-serif !important;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 38px;
  color: #073e4b;
  text-transform: none;
  padding-left: 20px;
}
#support-info-tabs .content-container {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
@media screen and (max-width: 1040px) {
  #support-info-tabs .content-container {
    flex-direction: column;
  }
  #support-info-tabs .content-container .asset-card {
    margin: 20px 0 20px 0px !important;
  }
}
#support-info-tabs .content-container .asset-card {
  width: 220px;
  background: rgba(4, 131, 163, 0.05);
  border-radius: 66px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 30px 20px 30px;
  min-height: 220px;
}
#support-info-tabs .content-container .asset-card .title {
  font-family: worksans-regular, sans-serif !important;
  font-weight: bold !important;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #626f73;
  text-transform: none;
  margin-top: 20px;
}
#support-info-tabs .content-container .asset-card .title strong {
  font-family: worksans-bold, sans-serif !important;
  font-weight: bold !important;
}
#support-info-tabs .content-container .asset-card img {
  height: 90px !important;
  width: auto;
}
#support-info-tabs .content-container .asset-card .title {
  font-family: montserrat-regular, sans-serif !important;
  font-weight: normal !important;
  color: #073e4b;
  font-size: 16px;
}
#support-info-tabs .content-container svg.plus-icon {
  margin: 0 40px;
}
/* ===== CART ====== */
#cart {
  background-color: rgba(247, 92, 2, 0.05);
}
#cart span,
#cart div {
  text-align: right;
}
#cart > div:first-of-type {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
#cart > div:first-of-type span {
  font-family: montserrat-regular, sans-serif !important;
  font-weight: normal !important;
  font-size: 24px;
  line-height: 46px;
  color: #073e4b;
  margin-left: 15px;
}
#cart .spinner-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#cart .spinner-container #spinner > div {
  background-color: #f75c02;
}
#cart .products-container,
#cart .services-container,
#cart .total-category-container {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}
#cart .cart-item {
  font-family: worksans-regular, sans-serif !important;
  font-weight: normal !important;
  font-size: 12px;
  line-height: 14px;
  color: #626f73;
  margin: 4px 0;
}
#cart .cart-item strong {
  font-family: worksans-bold, sans-serif !important;
  font-weight: bold !important;
}
#cart .cart-category,
#cart .total-category {
  font-family: worksans-regular, sans-serif !important;
  font-weight: normal !important;
  font-size: 12px;
  color: #073e4b;
}
#cart .cart-category {
  line-height: 16px;
  margin: 4px;
}
#cart .total-category {
  line-height: 24px;
}
#cart .total {
  font-family: worksans-regular, sans-serif !important;
  font-weight: normal !important;
  font-size: 16px;
  line-height: 24px;
  color: #f75c02;
}
#cart .total strong {
  font-family: worksans-bold, sans-serif !important;
  font-weight: bold !important;
}
#cart .button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
#cart .button-container .button {
  width: 221px;
  margin: 20px 0;
}
/* ===== CONFIGURATOR HELPER ====== */
#configurator-helper {
  background: rgba(163, 206, 84, 0.05);
}
#configurator-helper .title {
  font-family: "montserrat-semi-bold", sans-serif !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 22px;
  text-transform: none;
  text-align: left;
  margin-bottom: 20px;
}
#configurator-helper .text {
  font-family: "worksans-regular", sans-serif !important;
  font-style: normal;
  font-weight: normal !important;
  font-size: 14px;
  line-height: 20px;
  color: #626f73;
}
#configurator-helper .button {
  background-color: #ffffff;
  border: 1.2px solid #a3ce54;
  color: #202020;
  font-size: 12px;
  width: 220px;
  margin-top: 20px;
  text-align: center;
}
/* ===== PURCHASE STEPPER ====== */
#purchase-stepper {
  z-index: 1;
}
#purchase-stepper > .desktop {
  width: 900px;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 45px;
  position: relative;
}
@media screen and (max-width: 940px) {
  #purchase-stepper > .desktop {
    width: 500px;
  }
}
#purchase-stepper > .desktop.finish .step-card .circle {
  border: 3px solid #0483a3;
}
#purchase-stepper > .desktop .step-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}
#purchase-stepper > .desktop .step-card span {
  font-family: "worksans-regular", sans-serif !important;
  font-style: normal;
  font-weight: normal !important;
  font-size: 12px;
  line-height: 24px;
  color: #0483a3;
}
#purchase-stepper > .desktop .step-card .circle {
  height: 30px;
  width: 30px;
  margin-top: 5px;
  border-radius: 30px;
  background-color: #f75c02;
  cursor: not-allowed;
}
#purchase-stepper > .desktop .step-card .circle.visited {
  background-color: #a3ce54;
  cursor: pointer;
}
#purchase-stepper > .desktop .line {
  width: 90%;
  height: 2px;
  background-color: #0483a3;
  position: absolute;
  top: 43px;
  left: 65px;
  z-index: 1;
}
@media screen and (max-width: 940px) {
  #purchase-stepper > .desktop .line {
    width: 80%;
  }
}
#purchase-stepper > .mobile > div:first-of-type {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  min-height: 20px;
  padding-right: 20px;
  background-color: rgba(4, 131, 163, 0.05);
}
#purchase-stepper > .mobile > div:first-of-type span {
  font-family: "montserrat-regular", sans-serif !important;
  font-style: normal;
  font-weight: bold !important;
  font-size: 14px;
  line-height: 46px;
  color: #073e4b !important;
}
#purchase-stepper > .mobile > div:first-of-type svg {
  margin-left: 10px;
  transform: rotate(90deg);
  transition: all 100ms ease-in;
}
#purchase-stepper > .mobile > div:first-of-type svg.showMenu {
  transform: rotate(0);
}
#purchase-stepper > .mobile .menu {
  width: 100%;
  height: 0;
  background: #0d4c5b;
  border-radius: 0 0 30px 30px;
  transition: height 300ms ease-in-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
}
#purchase-stepper > .mobile .menu.showMenu {
  height: 180px;
}
#purchase-stepper > .mobile .menu .menu-items {
  width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 35px;
  border-bottom: 0.5px solid rgba(98, 111, 115, 0.3);
}
#purchase-stepper > .mobile .menu .menu-items:last-of-type {
  border-bottom: none;
}
#purchase-stepper > .mobile .menu .menu-items svg {
  height: 12 px;
  width: 12 px;
  transform: rotate(270deg);
  margin-right: 5px;
}
#purchase-stepper > .mobile .menu .menu-items svg path {
  fill: #f75c02 !important;
}
#purchase-stepper > .mobile .menu .menu-items span {
  font-family: "montserrat-light", sans-serif !important;
  font-weight: lighter !important;
  font-size: 12px;
  line-height: 30px;
  color: rgba(255, 255, 255, 0.85);
}
#purchase-stepper > .mobile .menu .menu-items span.active {
  color: #626f73;
}
#purchase-stepper > .mobile .menu .menu-items span.current {
  color: #0483a3;
}
/* ===== PURCHASE HEADER ====== */
#purchase-header .title {
  font-family: "montserrat-bold", sans-serif !important;
  font-style: normal;
  font-weight: normal !important;
  font-size: 36px;
  line-height: 46px;
  text-transform: none;
  margin-bottom: 8px;
}
@media screen and (max-width: 450px) {
  #purchase-header .title {
    font-size: 12px;
  }
}
#purchase-header .title > strong {
  text-transform: uppercase;
  font-weight: bold !important;
}
#purchase-header .subtitle {
  font-family: "worksans-regular", sans-serif !important;
  font-weight: normal !important;
  font-size: 16px;
  line-height: 20px;
  color: #626f73;
  display: inline-block !important;
}
#purchase-header > .desktop {
  background-color: rgba(4, 131, 163, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 282px;
}
#purchase-header > .mobile {
  position: fixed !important;
  top: 75px;
  width: 100%;
  background-color: #f4f9fa !important;
  opacity: 1 !important;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
#purchase-header > .mobile .title,
#purchase-header > .mobile .title strong {
  font-size: 20px;
  line-height: 25px;
  margin: 0;
}
#purchase-header > .mobile .title {
  margin-bottom: 20px;
}
#purchase-header > .mobile .subtitle {
  font-size: 14px;
  text-align: center;
}
/* ===== SUPPORTS NAVIGATION ====== */
#supports-navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  margin: 0 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 450px) {
  #supports-navigation {
    padding: 0 10px !important;
  }
}
#supports-navigation svg {
  cursor: pointer;
}
#supports-navigation div[class*="arrow-ctn"] {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 30px;
  margin-right: 0;
}
#supports-navigation div[class*="arrow-ctn"].enabled .arrow {
  background-color: #0483a3;
}
#supports-navigation div[class*="arrow-ctn"].enabled span {
  color: #0483a3;
}
#supports-navigation div[class*="arrow-ctn"]:last-of-type {
  margin-left: 0;
  margin-right: 30px;
}
#supports-navigation div[class*="arrow-ctn"]:last-of-type .arrow {
  margin-left: 20px;
  margin-right: 0;
}
#supports-navigation div[class*="arrow-ctn"]:last-of-type .arrow .right-arrow {
  transform: rotate(180deg);
}
#supports-navigation div[class*="arrow-ctn"] .arrow {
  height: 45px;
  width: 56px;
  background: #626f73;
  border-radius: 25.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}
#supports-navigation div[class*="arrow-ctn"] span {
  font-family: montserrat-regular, sans-serif !important;
  font-weight: normal;
  font-size: 12px;
  line-height: 46px;
  text-transform: uppercase;
  color: #626f73;
}
/* ===== AREA RESERVED FOR DUPLICATE CODE BETWEEN CLASSES====== */
#purchase-support,
#purchase-services {
  padding: 0 30px;
}
/* ===== PURCHASE SUPPORTS SELECTION ====== */
#purchase-supports-selection ~ div {
  clear: both;
}
#purchase-supports-selection .information-container {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #073e4b;
  padding: 40px;
}
@media screen and (max-width: 450px) {
  #purchase-supports-selection .information-container {
    padding: 40px 10px;
  }
}
#purchase-supports-selection .information-container .title {
  font-family: montserrat-bold, sans-serif !important;
  font-weight: bold !important;
  font-size: 40px;
  line-height: 46px;
  text-align: center;
  color: #ffffff;
  text-transform: none;
  margin-bottom: 20px;
}
@media screen and (max-width: 450px) {
  #purchase-supports-selection .information-container .title {
    font-size: 20px;
    line-height: 25px;
  }
}
#purchase-supports-selection .information-container .subtitle {
  font-family: worksans-light, sans-serif !important;
  font-weight: lighter !important;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #626f73;
}
@media screen and (max-width: 768px) {
  #purchase-supports-selection .information-container .subtitle {
    display: block;
  }
}
#purchase-supports-selection .content {
  width: 100%;
  height: 300px;
  position: relative;
  margin-right: 40px !important;
}
@media screen and (max-width: 450px) {
  #purchase-supports-selection .content {
    width: 100vw !important;
    margin-right: 0 !important;
  }
  #purchase-supports-selection .content .list-container {
    width: 100% !important;
    right: 0 !important;
    transform: translateX(0) !important;
    padding: 60px 10px !important;
  }
}
#purchase-supports-selection .content .list-container {
  position: relative;
  right: 50%;
  top: -180px;
  transform: translateX(50%);
  float: right;
  width: 1166px;
  height: auto !important;
  background: #f2f9fa;
  border-radius: 119px 38px 43px 92px;
  padding: 80px;
}
@media screen and (max-width: 1200px) {
  #purchase-supports-selection .content .list-container {
    width: 98%;
  }
}
@media screen and (max-width: 768px) {
  #purchase-supports-selection .content .list-container {
    border-radius: 43px !important;
    height: auto;
  }
}
@media screen and (max-width: 450px) {
  #purchase-supports-selection .content .list-container {
    right: 0 !important;
    width: 100% !important;
  }
}
#purchase-supports-selection .content .list-container .list {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  justify-items: center;
  row-gap: 40px;
}
@media screen and (max-width: 1200px) {
  #purchase-supports-selection .content .list-container .list {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 768px) {
  #purchase-supports-selection .content .list-container .list {
    grid-template-columns: 1fr;
  }
}
#purchase-supports-selection .content .list-container .list .support-card {
  width: 288px;
  height: 410px;
  background: #ffffff;
  border-radius: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}
#purchase-supports-selection .content .list-container .list .support-card .header-text {
  font-family: worksans-regular, sans-serif !important;
  font-weight: normal !important;
  font-size: 15px;
  line-height: 24px;
  margin-top: 5px;
}
#purchase-supports-selection .content .list-container .list .support-card .header-text.green {
  color: #a3ce54;
}
#purchase-supports-selection .content .list-container .list .support-card .header-text.blue {
  color: #0483a3;
}
#purchase-supports-selection .content .list-container .list .support-card .header-text.orange {
  color: #f75c02;
}
#purchase-supports-selection .content .list-container .list .support-card img {
  width: 100%;
  height: auto;
  margin: 10px;
}
#purchase-supports-selection .content .list-container .list .support-card .title {
  font-family: montserrat-bold, sans-serif !important;
  font-weight: bold !important;
  font-size: 22px;
  line-height: 25px;
  text-align: center;
  color: #073e4b;
}
#purchase-supports-selection .content .list-container .list .support-card .text {
  font-family: worksans-regular, sans-serif !important;
  font-weight: normal !important;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #626f73;
  margin: 10px 0;
  min-height: 60px;
}
#purchase-supports-selection .content .list-container .list .support-card .button {
  background-color: #0483a3;
}
/* ===== PURCHASE SUPPORT ====== */
#purchase-support {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
@media screen and (max-width: 1820px) {
  #purchase-support {
    padding: 0;
  }
}
#purchase-support .upper {
  display: grid;
  grid-template-columns: 340px auto;
  grid-template-rows: auto;
  padding-bottom: 40px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 1090px) {
  #purchase-support .upper .right-content {
    padding-left: 20px !important;
    width: 100% !important;
  }
}
@media screen and (max-width: 710px) {
  #purchase-support .upper {
    grid-template-columns: 100%;
    border-bottom: none;
  }
  #purchase-support .upper .left-content > div,
  #purchase-support .upper .right-content > div {
    width: 100%;
  }
  #purchase-support .upper .left-content .title-and-price-small-device {
    display: block !important;
    padding: 30px 0 10px 60px;
  }
  #purchase-support .upper .left-content .title-and-price-small-device.blue {
    background-color: rgba(4, 131, 163, 0.05);
  }
  #purchase-support .upper .left-content .title-and-price-small-device.green {
    background-color: rgba(163, 206, 84, 0.05);
  }
  #purchase-support .upper .left-content .title-and-price-small-device.orange {
    background-color: rgba(247, 92, 2, 0.05);
  }
  #purchase-support .upper .left-content .title-and-price-small-device .title {
    text-align: left;
  }
  #purchase-support .upper .left-content .product-card {
    border-radius: 0;
  }
  #purchase-support .upper .left-content .product-card .card-header {
    justify-content: flex-start;
    padding-left: 20px;
  }
  #purchase-support .upper .right-content {
    margin-top: 40px;
    padding: 0 0 0 30px !important;
  }
  #purchase-support .upper .right-content .title,
  #purchase-support .upper .right-content .price {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  #purchase-support .upper .left-content .title-and-price-small-device {
    padding: 30px 0 10px 40px;
  }
  #purchase-support .upper .product-card .card-header {
    padding-left: 0px !important;
  }
}
#purchase-support .upper .left-content > .title-and-price-small-device {
  display: none;
}
#purchase-support .upper .product-card {
  box-shadow: none;
  width: 340px;
}
#purchase-support .upper .product-card .card-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
#purchase-support .upper .product-card img.product-illustration {
  height: 180px;
  width: auto;
}
#purchase-support .upper .product-card .product-information {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
#purchase-support .upper .product-card .product-information span {
  font-family: montserrat-regular, sans-serif !important;
  font-weight: normal !important;
  font-size: 12px;
  color: #626f73;
  margin-bottom: 5px;
}
#purchase-support .upper .product-card .product-information span strong {
  font-family: montserrat-bold, sans-serif !important;
  font-weight: bold !important;
}
#purchase-support .upper .product-card .product-information img {
  background-color: red;
}
#purchase-support .upper .right-content {
  padding-left: 30px;
  width: 90%;
}
#purchase-support .upper .right-content .title {
  font-family: montserrat-bold, sans-serif !important;
  font-weight: bold !important;
  font-size: 36px;
  line-height: 40px;
  color: #073e4b;
  text-align: left;
  margin-bottom: 25px;
}
#purchase-support .upper .right-content .price {
  font-family: montserrat-regular, sans-serif !important;
  font-weight: normal !important;
  font-size: 24px;
  color: #0c4f5e;
  margin-bottom: 20px;
}
#purchase-support .upper .right-content .information {
  font-family: worksans-regular, sans-serif !important;
  font-weight: normal !important;
  font-size: 16px;
  color: #626f73;
}
#purchase-support .upper .right-content hr {
  margin: 20px 0;
}
#purchase-support .upper .right-content .color-container,
#purchase-support .upper .right-content .quantity {
  margin-top: 40px;
}
#purchase-support .upper .right-content .color-container span {
  font-family: montserrat-regular, sans-serif !important;
  font-weight: normal !important;
  font-size: 14px;
  text-transform: uppercase;
  color: #0483a3;
}
#purchase-support .upper .right-content .color-container > div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 15px;
}
#purchase-support .upper .right-content .color-container .color-selector {
  width: 40px !important;
  height: 40px !important;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
}
#purchase-support .upper .right-content .color-container .color-selector:last-of-type {
  margin-right: 0;
}
#purchase-support .upper .right-content .color-container .color-selector.selected .inner-circle {
  height: 30px;
  width: 30px;
  border-radius: 30px;
  border: 1.4px solid #ffffff;
}
#purchase-support .upper .right-content .color-container .color-selector.blue {
  background-color: #0483a3;
}
#purchase-support .upper .right-content .color-container .color-selector.green {
  background-color: #a3ce54;
}
#purchase-support .upper .right-content .color-container .color-selector.orange {
  background-color: #f75c02;
}
#purchase-support .upper .right-content .quantity {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 52px;
}
#purchase-support .upper .right-content .quantity .number-selector {
  margin-right: 15px;
  width: 100px;
}
#purchase-support .upper .right-content .quantity .button {
  font-size: 14px;
  background-color: #073e4b;
}
#purchase-support .lower {
  border: none !important;
  min-height: 300px;
  margin-top: 25px;
}
#purchase-support .lower {
  border: 1px solid #202020;
}
/* ===== PURCHASE SERVICES ====== */
#purchase-services {
  padding-left: 0;
}
@media screen and (max-width: 1970px) {
  #purchase-services > div[class*="container"] .service-card {
    width: 95%;
  }
}
@media screen and (max-width: 980px) {
  #purchase-services {
    padding: 0;
  }
  #purchase-services .service-card {
    width: 100% !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  #purchase-services .service-card .textual-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  #purchase-services .service-card .textual-container p {
    text-align: center;
  }
  #purchase-services .service-card .right-content {
    flex-direction: column;
  }
  #purchase-services .service-card .right-content div.column {
    margin-left: 0;
    margin-top: 20px;
  }
  #purchase-services .service-card .right-content .button {
    margin-top: 25px;
    display: flex;
  }
  #purchase-services .service-card .right-content .delete-button {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  #purchase-services > div[class*="container"] .title {
    font-size: 18px !important;
    line-height: 22px !important;
  }
}
#purchase-services > div[class*="container"] {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#purchase-services > div[class*="container"] .title {
  font-family: montserrat-bold, sans-serif !important;
  font-weight: bold !important;
  font-size: 24px;
  line-height: 46px;
  text-align: center;
  color: #073e4b;
  text-transform: none;
  margin: 30px 0 20px 0;
}
#purchase-services .free-services-container span.title {
  margin-top: 0 !important;
}
#purchase-services .free-services-container .service-card {
  background-color: rgba(247, 92, 2, 0.05);
}
#purchase-services .a-la-carte-services-container .service-card,
#purchase-services .pack-services-container .service-card {
  background-color: rgba(4, 131, 163, 0.05);
}
#purchase-services .pack-services-container .button {
  margin-left: 60px !important;
  margin-top: 40px !important;
}
#purchase-services .owned-services-container .service-card {
  background-color: rgba(163, 206, 84, 0.05);
}
/* ===== PURCHASE DELIVERY ====== */
#purchase-delivery {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 1280px) {
  #purchase-delivery > form {
    width: 90% !important;
  }
}
@media screen and (max-width: 1150px) {
  #purchase-delivery > form {
    width: 100% !important;
  }
  #purchase-delivery .button {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  #purchase-delivery {
    padding: 0 3%;
  }
}
#purchase-delivery > form {
  width: 800px;
  background-color: rgba(4, 131, 163, 0.05);
}
@media screen and (max-width: 450px) {
  #purchase-delivery > form {
    width: 95%;
    padding: 15px 40px 50px 40px !important;
  }
  #purchase-delivery > form input {
    font-size: 13px;
  }
}
/* ===== PURCHASE CONFIRM ====== */
#purchase-confirm-cart {
  width: 100%;
  padding-right: 15px;
}
@media screen and (max-width: 930px) {
  #purchase-confirm-cart .cart-container .products-container .products-list {
    background-color: transparent !important;
    padding: 0 !important;
  }
  #purchase-confirm-cart .cart-container .products-container .products-list .product-item {
    background-color: rgba(247, 92, 2, 0.05);
    padding: 40px 30px !important;
    border-radius: 66px;
    margin: 20px 0;
    flex-direction: column !important;
    height: auto !important;
  }
  #purchase-confirm-cart .cart-container .products-container .products-list .product-item > * {
    margin-right: 0 !important;
  }
  #purchase-confirm-cart .cart-container .products-container .products-list .product-item img.product-illustration {
    margin: 0 0 10px 0;
    height: 160px !important;
    width: auto;
  }
  #purchase-confirm-cart .cart-container .products-container .products-list .product-item span.name {
    font-size: 22px;
    text-align: center;
  }
  #purchase-confirm-cart .cart-container .products-container .products-list .product-item .number-selector {
    margin: 20px 0;
  }
  #purchase-confirm-cart .cart-container .products-container .products-list .product-item .price {
    font-size: 18px !important;
  }
  #purchase-confirm-cart .cart-container .products-container .products-list .product-item .delete-button {
    display: none !important;
  }
  #purchase-confirm-cart .cart-container .products-container .products-list .product-item .button {
    display: flex !important;
    margin-top: 40px;
    width: 200px;
  }
  #purchase-confirm-cart .cart-container .services-container .service-card {
    width: 100% !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  #purchase-confirm-cart .cart-container .services-container .service-card .textual-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  #purchase-confirm-cart .cart-container .services-container .service-card .textual-container p {
    text-align: center;
  }
  #purchase-confirm-cart .cart-container .services-container .service-card .right-content {
    flex-direction: column;
  }
  #purchase-confirm-cart .cart-container .services-container .service-card .right-content div.column {
    margin-left: 0;
    margin-top: 20px;
  }
  #purchase-confirm-cart .cart-container .services-container .service-card .right-content .button {
    margin-top: 25px;
    display: flex;
  }
  #purchase-confirm-cart .cart-container .services-container .service-card .right-content .delete-button {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  #purchase-confirm-cart {
    margin-top: 30px;
    width: 100%;
    padding: 0;
  }
  #purchase-confirm-cart .cards-container {
    grid-template-columns: 80% !important;
  }
  #purchase-confirm-cart span.category-total-price {
    margin-top: 25px;
    width: 100% !important;
    padding: 0 !important;
    text-align: center !important;
    font-size: 16px !important;
  }
  #purchase-confirm-cart .payment-container .payment-item:last-of-type span {
    font-size: 18px !important;
  }
}
#purchase-confirm-cart div.info-message {
  font-family: worksans-regular, sans-serif !important;
  font-weight: normal;
  text-align: center;
  margin-top: 20px;
}
#purchase-confirm-cart div.info-message:last-of-type {
  margin-top: 100px;
}
#purchase-confirm-cart span.category-total-price {
  padding: 30px 60px 0 0;
  display: block;
  width: 100%;
  text-align: right;
  font-family: montserrat-regular, sans-serif !important;
  font-weight: normal !important;
  font-size: 18px;
  line-height: 24px;
  color: #073e4b;
}
#purchase-confirm-cart .cards-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-row-gap: 30px;
  justify-items: center;
}
@media screen and (max-width: 1380px) {
  #purchase-confirm-cart .cards-container {
    grid-template-columns: 100% !important;
  }
  #purchase-confirm-cart .cards-container .recap-card {
    width: 100% !important;
  }
}
#purchase-confirm-cart .cards-container .recap-card {
  width: 320px;
  min-height: 280px;
  background: rgba(4, 131, 163, 0.05);
  border-radius: 66px;
  padding: 40px;
  display: flex !important;
  flex-direction: column !important;
}
#purchase-confirm-cart .cards-container .recap-card h5 {
  font-family: montserrat-regular, sans-serif !important;
  font-weight: normal !important;
  font-size: 20px;
  color: #073e4b;
  margin-bottom: 20px;
  text-align: left;
  text-transform: none;
}
#purchase-confirm-cart .cards-container .recap-card strong,
#purchase-confirm-cart .cards-container .recap-card span {
  display: block;
}
#purchase-confirm-cart .cards-container .recap-card strong {
  font-family: montserrat-bold, sans-serif !important;
  font-weight: bold !important;
  font-size: 16px;
}
#purchase-confirm-cart .cards-container .recap-card span {
  font-family: worksans-regular, sans-serif !important;
  font-weight: normal !important;
  font-size: 16px;
  color: #626f73;
}
#purchase-confirm-cart .cards-container .recap-card .button {
  width: 148px;
  background-color: #ffffff;
  border: 1px solid #0483a3;
  color: #0483a3;
  margin-top: 20px;
}
#purchase-confirm-cart .cart-container {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#purchase-confirm-cart .cart-container > div:first-of-type {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 30px 30px;
}
#purchase-confirm-cart .cart-container > div:first-of-type span {
  font-family: montserrat-bold, sans-serif !important;
  font-weight: bold !important;
  font-size: 24px;
  color: #073e4b;
  margin-left: 15px;
}
#purchase-confirm-cart .cart-container .products-container,
#purchase-confirm-cart .cart-container .services-container {
  width: 100%;
}
#purchase-confirm-cart .cart-container .products-container .products-list {
  width: 100%;
  min-height: 80px;
  border-radius: 66px;
  background-color: rgba(247, 92, 2, 0.05);
  padding: 30px 60px;
}
#purchase-confirm-cart .cart-container .products-container .products-list .product-item {
  height: 120px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 10px 0;
  border-bottom: 2px solid rgba(136, 60, 16, 0.08);
}
#purchase-confirm-cart .cart-container .products-container .products-list .product-item:last-of-type {
  padding: 10px 0 0 0;
  border-bottom: none;
}
#purchase-confirm-cart .cart-container .products-container .products-list .product-item > * {
  margin-right: 20px;
}
#purchase-confirm-cart .cart-container .products-container .products-list .product-item > img {
  height: 100px;
  width: auto;
}
#purchase-confirm-cart .cart-container .products-container .products-list .product-item > .name,
#purchase-confirm-cart .cart-container .products-container .products-list .product-item .price {
  font-family: "montserrat-bold", sans-serif !important;
  font-weight: bold !important;
  font-size: 16px;
  color: #073e4b;
}
#purchase-confirm-cart .cart-container .products-container .products-list .product-item > .price {
  font-size: 22px;
}
#purchase-confirm-cart .cart-container .products-container .products-list .product-item .number-selector select {
  background-color: #ffffff;
  border: 2px solid #0483a3;
}
#purchase-confirm-cart .cart-container .products-container .products-list .product-item .delete-button {
  position: absolute;
  right: 0;
}
#purchase-confirm-cart .cart-container .products-container .products-list .product-item .button {
  display: none;
}
#purchase-confirm-cart .cart-container .services-container {
  margin-top: 100px;
}
#purchase-confirm-cart .cart-container .services-container .service-card {
  width: 100%;
  background-color: rgba(4, 131, 163, 0.05);
}
#purchase-confirm-cart .payment-container {
  margin: 100px 0;
  width: 100%;
}
#purchase-confirm-cart .payment-container .payment-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
#purchase-confirm-cart .payment-container .payment-item.taxes {
  justify-content: flex-end;
}
#purchase-confirm-cart .payment-container .payment-item.taxes span {
  font-size: 12px !important;
}
#purchase-confirm-cart .payment-container .payment-item:nth-of-type(2) {
  margin-top: 20px;
}
#purchase-confirm-cart .payment-container .payment-item:nth-of-type(2) span {
  font-family: montserrat-bold, sans-serif !important;
  font-weight: bold !important;
  font-size: 22px;
  color: #073e4b;
  text-transform: uppercase;
}
#purchase-confirm-cart .payment-container .payment-item span,
#purchase-confirm-cart .payment-container .payment-item strong {
  font-size: 16px;
  color: #626f73;
}
#purchase-confirm-cart .payment-container .payment-item span {
  font-family: worksans-regular, sans-serif !important;
  font-weight: normal !important;
}
#purchase-confirm-cart .payment-container .payment-item strong {
  font-family: worksans-bold, sans-serif !important;
  font-weight: bold !important;
}
#purchase-confirm-cart .payment-container .fetching {
  font-family: montserrat-bold, sans-serif !important;
  font-weight: bold !important;
  text-align: center;
}
#purchase-confirm-cart .buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 80px 0;
}
@media screen and (max-width: 768px) {
  #purchase-confirm-cart .buttons-container {
    flex-direction: column;
    width: 100%;
  }
  #purchase-confirm-cart .buttons-container .button {
    width: 90vw !important;
  }
  #purchase-confirm-cart .buttons-container .button:first-of-type {
    margin-bottom: 50px;
  }
}
#purchase-confirm-cart .buttons-container .button {
  width: 320px;
}
#purchase-confirm-cart .buttons-container .button:first-of-type {
  background-color: #ffffff;
  color: #0483a3;
  border: 1px solid #0483a3;
}
/* ===== PURCHASE DONE ====== */
#purchase-done-cart {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 450px) {
  #purchase-done-cart .title {
    font-size: 16px !important;
    line-height: 25px !important;
    margin: 40px 20px !important;
  }
  #purchase-done-cart .border-box {
    width: 90% !important;
    height: 80px !important;
  }
  #purchase-done-cart .border-box span {
    font-size: 18px !important;
  }
  #purchase-done-cart svg {
    width: 90% !important;
    height: auto !important;
    margin: 40px 0 !important;
  }
  #purchase-done-cart .buttons-container {
    margin: 0 0 60px 0 !important;
  }
}
#purchase-done-cart .title {
  font-family: "montserrat-semi-bold", sans-serif !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 24px;
  line-height: 46px;
  margin: 60px 0;
  text-transform: none;
}
#purchase-done-cart .border-box {
  width: 508px;
  height: 124px;
  background: #f2f9fa;
  border-radius: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#purchase-done-cart .border-box span {
  font-family: "montserrat-regular", sans-serif !important;
  font-weight: normal !important;
  font-size: 20px;
  line-height: 24px;
}
#purchase-done-cart .border-box span strong {
  margin-right: 20px;
}
#purchase-done-cart svg {
  margin: 60px 0;
  height: 250px;
  width: auto;
}
#purchase-done-cart .buttons-container {
  margin: 40px 0 80px 0;
  height: 135px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (max-width: 700px) {
  #purchase-done-cart .buttons-container {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transform: translateX(0);
  }
}
#purchase-done-cart .buttons-container button {
  background-color: #073e4b;
}
@media screen and (max-width: 700px) {
  #purchase-done-cart .buttons-container button {
    margin: 30px 0;
  }
}
#purchase-done-cart .buttons-container button:first-of-type {
  width: 200px;
}
#purchase-done-cart .buttons-container button:last-of-type {
  width: 290px;
}
/* ===== PURCHASE SIGN ====== */
@media screen and (max-width: 450px) {
  #purchase-sign {
    min-height: 120px !important;
  }
}
/* ================================================================== */
/* old purchase version : TODO to be removed */
/* ===== GLOBAL ====== */
#step-container {
  min-height: 1000px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
#step-container > div {
  min-height: 1000px;
  width: 1000px;
  border-radius: 129px;
  padding: 80px 100px;
  background-color: white;
  transform: translateY(-100px);
}
@media screen and (max-width: 1000px) {
  #step-container > div {
    width: 100%;
  }
}
@media screen and (max-width: 576px) {
  #step-container > div {
    transform: translateY(-60px);
    border-radius: 60px;
    padding: 80px 15px;
  }
}
/* ===== PURCHASE START ====== */
#purchase-start button {
  margin-top: 10vh;
}
@media screen and (max-width: 450px) {
  #purchase-start button {
    margin-top: 20vh;
  }
}
/* ===== PURCHASE FORM ====== */
#purchase-form {
  position: relative;
}
#purchase-form #products-slideshow .desktop-section {
  width: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 450px) {
  #purchase-form #products-slideshow .desktop-section {
    display: none;
  }
}
#purchase-form #products-slideshow .desktop-section svg {
  height: 30px;
  width: 30px;
  cursor: pointer;
  margin: 0 30px;
  transform: translateY(-70px);
  fill: #626f73;
}
#purchase-form #products-slideshow .desktop-section svg.active {
  fill: #202020;
}
@media screen and (max-width: 660px) {
  #purchase-form #products-slideshow .desktop-section svg {
    margin: 0;
  }
}
#purchase-form #products-slideshow .mobile-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (min-width: 450px) {
  #purchase-form #products-slideshow .mobile-section {
    display: none;
  }
}
#purchase-form .provider-products {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media screen and (max-width: 950px) {
  #purchase-form .provider-products {
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 850px) {
  #purchase-form .spoors-products .purchase-product .product-card {
    width: 300px;
  }
}
#purchase-form .purchase-product {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 450px) {
  #purchase-form .purchase-product {
    width: 90vw;
  }
}
#purchase-form .purchase-product .product-card {
  margin: 20px 0;
  width: 360px;
}
@media screen and (max-width: 450px) {
  #purchase-form .purchase-product .product-card {
    width: 100%;
  }
}
#purchase-form .purchase-product .product-card.green .information-container {
  color: #a3ce54;
}
#purchase-form .purchase-product .product-card.green .information-container .information strong {
  color: #a3ce54;
}
#purchase-form .purchase-product .product-card.green div.title {
  color: #a3ce54;
}
#purchase-form .purchase-product .product-card.orange .information-container {
  color: #f75c02;
}
#purchase-form .purchase-product .product-card.orange .information-container .information strong {
  color: #f75c02;
}
#purchase-form .purchase-product .product-card.orange div.title {
  color: #f75c02;
}
#purchase-form .purchase-product .product-card.blue .information-container {
  color: #0483a3;
}
#purchase-form .purchase-product .product-card.blue .information-container .information strong {
  color: #0483a3;
}
#purchase-form .purchase-product .product-card.blue div.title {
  color: #0483a3;
}
#purchase-form .purchase-product .product-card div.title {
  margin-top: 40px;
  line-height: 30px;
}
#purchase-form .purchase-product .product-card div.title span {
  font-family: montserrat-regular, sans-serif !important;
  font-weight: normal !important;
  font-size: 24px;
}
#purchase-form .purchase-product .product-card div.title span strong {
  font-family: montserrat-bold, sans-serif !important;
  font-weight: bold !important;
}
#purchase-form .purchase-product .product-card .information-container {
  text-align: center;
  margin-top: 20px;
}
#purchase-form .purchase-product .product-card .information-container span {
  cursor: pointer;
  font-family: worksans-bold, sans-serif !important;
  font-weight: bold !important;
}
@media screen and (max-width: 450px) {
  #purchase-form .purchase-product .product-card .information-container {
    padding: 0 20px;
  }
}
#purchase-form .purchase-product .product-card .information-container .information {
  display: flex;
  flex-direction: column;
  max-height: 0;
  overflow-y: hidden;
  transition: max-height 250ms ease-out;
  text-align: left;
  margin-bottom: 20px;
}
#purchase-form .purchase-product .product-card .information-container .information.visible {
  max-height: 600px;
}
#purchase-form .purchase-product .product-card .information-container .information > .item {
  font-family: worksans-light, sans-serif !important;
  font-weight: lighter;
  font-size: 15px;
  margin: 12px 0;
  color: #626f73 !important;
}
#purchase-form .purchase-product .product-card .information-container .information > .item strong {
  margin-right: 10px;
}
#purchase-form .purchase-product .quantity-selector {
  margin: 40px;
}
#purchase-form .purchase-product .quantity-selector span {
  font-family: montserrat-regular, sans-serif !important;
  font-weight: normal !important;
  margin-right: 15px;
}
#purchase-form .purchase-product .quantity-selector select {
  padding: 5px 15px;
  border-radius: 25px;
  border: 1.2px solid #202020;
  background-color: #ffffff;
}
/* ===== PURCHASE CONFIRM ====== */
#purchase-confirm .recap,
#purchase-confirm .purchase-recap {
  background-color: rgba(8, 123, 152, 0.05);
  border-radius: 30px;
  padding: 50px 30px;
}
@media screen and (max-width: 450px) {
  #purchase-confirm .recap,
  #purchase-confirm .purchase-recap {
    padding: 30px 20px;
  }
}
#purchase-confirm .recap h3,
#purchase-confirm .purchase-recap h3 {
  font-family: montserrat-bold, sans-serif !important;
  font-weight: bold !important;
  font-size: 24px;
  color: #0c4f5e;
  margin: 0 0 35px 0;
}
#purchase-confirm .recap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
#purchase-confirm .recap .container {
  display: flex;
  flex-direction: column;
}
#purchase-confirm .recap .container span {
  font-family: worksans-regular, sans-serif !important;
  font-weight: normal !important;
  font-size: 16px;
  color: #a4a4a4;
}
@media screen and (max-width: 450px) {
  #purchase-confirm .recap .container span {
    font-size: 14px;
  }
}
#purchase-confirm .recap a {
  height: 55px;
  width: 180px;
  background-color: #0d4c5b;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 330px;
  color: white;
}
#purchase-confirm div.purchase-recap {
  position: relative;
  margin-top: 80px;
  display: flex;
  justify-content: center;
  min-height: 300px;
  width: 100%;
}
#purchase-confirm div.purchase-recap > div {
  width: 400px;
}
#purchase-confirm div.purchase-recap > div .product {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  font-family: worksans-light, sans-serif !important;
  font-weight: lighter !important;
  margin: 10px 0;
  font-size: 12px !important;
}
@media screen and (max-width: 450px) {
  #purchase-confirm div.purchase-recap > div .product {
    font-size: 14px;
  }
}
#purchase-confirm div.purchase-recap > div .product > div span:first-child {
  margin-right: 14px;
}
#purchase-confirm div.purchase-recap > div .delivery,
#purchase-confirm div.purchase-recap > div .total-price {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  font-family: worksans-bold, sans-serif !important;
  font-weight: bold !important;
  color: #202020;
  font-size: 16px;
}
#purchase-confirm div.purchase-recap > div .total-price {
  color: #0483a3;
  font-size: 18px;
}
#purchase-confirm div.purchase-recap > div #buttons-container {
  position: absolute;
  left: 50%;
  transform: translateX(-70%);
  bottom: -65px;
}
@media screen and (max-width: 576px) {
  #purchase-confirm div.purchase-recap > div #buttons-container a {
    margin-left: 50px !important;
  }
}
/* ===== PURCHASE DONE ====== */
#purchase-done .image {
  transform: translateY(-80px);
}
#purchase-done .content {
  transform: translateY(-60px);
}
#purchase-done .step {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin: 50px 0;
}
@media screen and (max-width: 576px) {
  #purchase-done .step {
    margin: 50px 25px;
  }
}
#purchase-done .step:first-of-type {
  margin-top: 100px;
}
#purchase-done .step:last-of-type {
  margin-bottom: 120px;
}
#purchase-done .step .step-number-box {
  min-width: 70px;
}
#purchase-done .step span {
  margin-left: 40px;
  text-align: left;
}
/* ===== IMPORT ====== */
.asset-card {
  text-align: center;
}
.asset-card .title {
  font-family: montserrat-bold, sans-serif;
  font-weight: bold;
  text-transform: none;
  color: #0c4f5e;
  font-size: 20px;
}
.asset-card .image-container {
  min-height: 250px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
/* ===== IMPORT ====== */
/* ===== GLOBAL ====== */
#product-information-container {
  overflow-x: hidden;
}
#product-information-container * {
  font-family: montserrat-regular, sans-serif;
}
/* ===== PRODUCT HEADER ====== */
#product-header {
  position: relative;
  min-height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: stretch;
  margin-top: 79px;
}
@media screen and (max-width: 643px) {
  #product-header {
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 450px) {
  #product-header {
    margin-bottom: 150px;
  }
}
#product-header .cover-container {
  width: 60%;
  height: 60%;
}
@media screen and (max-width: 1265px) {
  #product-header .cover-container {
    width: 80%;
    height: 80%;
  }
}
@media screen and (max-width: 768px) {
  #product-header .cover-container {
    width: 100%;
    height: 100%;
  }
}
#product-header .blue-box {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 50%;
  background-color: #087b98;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 130px;
}
@media screen and (max-width: 643px) {
  #product-header .blue-box {
    padding-top: 15px;
    width: 100%;
    border-radius: 45px 38px 43px 9px;
    transform: translateY(90%);
    height: 320px;
  }
}
@media screen and (max-width: 576px) {
  #product-header .blue-box {
    transform: translateY(78%);
  }
}
@media screen and (max-width: 450px) {
  #product-header .blue-box {
    transform: translateY(50%);
  }
}
#product-header .blue-box .information-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 90%;
  padding-left: 8%;
}
@media screen and (max-width: 960px) {
  #product-header .blue-box .information-container {
    padding-left: 8%;
  }
}
#product-header .blue-box .information-container .title {
  font-family: "montserrat-extra-bold", sans-serif;
  font-weight: bolder !important;
  font-size: 62px;
  line-height: 62px;
  text-transform: inherit;
  color: #ffffff;
  margin-bottom: 15px;
}
@media screen and (max-width: 1500px) {
  #product-header .blue-box .information-container .title {
    font-size: 45px;
    line-height: 45px;
  }
}
@media screen and (max-width: 960px) {
  #product-header .blue-box .information-container .title {
    font-size: 34px;
    line-height: 34px;
    margin-bottom: 10px;
  }
}
#product-header .blue-box .information-container .spoors-baseline {
  font-family: montserrat-extra-light, sans-serif;
  font-weight: lighter;
  font-size: 25px;
  line-height: 46px;
}
@media screen and (max-width: 960px) {
  #product-header .blue-box .information-container .spoors-baseline {
    font-size: 20px;
  }
}
#product-header .blue-box .information-container ul {
  list-style-type: none;
  margin-top: 20px;
}
@media screen and (max-width: 960px) {
  #product-header .blue-box .information-container ul {
    margin-top: 0;
  }
}
#product-header .blue-box .information-container li {
  font-family: worksans-bold, sans-serif !important;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
}
@media screen and (max-width: 960px) {
  #product-header .blue-box .information-container li {
    font-size: 14px;
  }
}
/* ===== PRODUCT CARD ====== */
#product-card {
  position: relative;
  transform: translateX(-8%);
  margin: 8% 0;
}
#product-card * {
  text-align: right !important;
}
@media screen and (max-width: 992px) {
  #product-card {
    margin: 8% 3%;
  }
}
@media screen and (max-width: 767px) {
  #product-card {
    transform: translate(0);
    margin-bottom: 45vh;
  }
  #product-card * {
    text-align: left !important;
  }
}
@media screen and (max-width: 643px) {
  #product-card {
    margin: 25% 0 45vh 0 !important;
  }
}
@media screen and (max-width: 450px) {
  #product-card {
    margin-bottom: 400px !important;
  }
}
#product-card button {
  position: absolute;
  bottom: 0;
  right: 37%;
  transform: translateY(50%);
}
@media screen and (max-width: 767px) {
  #product-card button {
    top: auto;
    bottom: 0;
    right: 50%;
    transform: translate(50%, 30vh);
  }
}
@media screen and (max-width: 576px) {
  #product-card button {
    transform: translate(50%, 260px);
  }
}
#product-card img {
  position: absolute;
  top: 60%;
  right: 0;
  max-height: 200%;
  width: 70%;
  transform: translate(50%, -60%);
}
@media screen and (max-width: 992px) {
  #product-card img {
    transform: translate(40%, -55%);
  }
}
@media screen and (max-width: 767px) {
  #product-card img {
    top: auto;
    bottom: 0;
    right: 50%;
    transform: translate(50%, 60%);
  }
}
@media screen and (max-width: 450px) {
  #product-card img {
    width: 100%;
  }
}
#product-card .product-card-container {
  padding: 100px 50px;
  background-color: rgba(4, 131, 163, 0.05);
  border-radius: 119px 38px 43px 92px;
}
@media screen and (max-width: 643px) {
  #product-card .product-card-container {
    padding-top: 235px;
    text-align: left;
    border-radius: 0 0 43px 92px;
  }
}
@media screen and (max-width: 450px) {
  #product-card .product-card-container {
    padding-top: 180px;
  }
}
#product-card .product-card-container span {
  font-family: worksans-bold, sans-serif;
  font-weight: bold;
  color: #626f73;
}
#product-card .title {
  font-family: montserrat-semi-bold, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  color: #0c4f5e;
}
/* ===== PRODUCT ASSETS ====== */
#product-assets {
  background-color: rgba(4, 131, 163, 0.05);
  width: 100% !important;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
@media screen and (max-width: 768px) {
  #product-assets {
    border-top-left-radius: 119px;
    border-top-right-radius: 38px;
  }
}
@media screen and (min-height: 992px) and screen and (max-width: 992px) {
  #product-assets .margin-left {
    margin-left: 30%;
  }
}
@media screen and (min-height: 992px) and screen and (max-width: 992px) {
  #product-assets .margin-right {
    margin-right: 30%;
  }
}
/* ===== IMPORT ====== */
#blue-container {
  text-align: center;
  min-height: 350px;
  background-color: #0483a3;
  color: #ffffff;
  padding: 0 35px;
}
@media screen and (max-width: 450px) {
  #blue-container {
    padding: 0 10px;
  }
}
#blue-container .title {
  color: #ffffff;
}
@media screen and (max-width: 576px) {
  #blue-container .title {
    line-height: 2.2rem;
  }
}
#blue-container .body-1 {
  color: #ffffff;
}
@media screen and (max-width: 576px) {
  #blue-container .body-1 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
/* ===== IMPORT ====== */
/* ===== MIXINS ====== */
/* ===== NON-FLUID-CONTAINER ====== */
.screen-container {
  display: flex;
  background-color: #0c4f5e;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow-x: hidden;
}
.non-fluid-container {
  position: relative;
  background-color: #f2f9fa;
  -webkit-box-shadow: 0px 4px 11px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 4px 11px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 4px 11px -3px rgba(0, 0, 0, 0.75);
  width: 75%;
}
@media screen and (max-width: 1820px) {
  .non-fluid-container {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .non-fluid-container .row {
    margin: 0;
  }
}
@media screen and (max-width: 576px) {
  .non-fluid-container {
    overflow-x: hidden;
  }
}
/* ===== IMPORT ====== */
/* ===== COMPONENT ====== */
#shopping-cart-icon {
  position: relative;
  cursor: pointer;
  height: 100%;
}
#shopping-cart-icon svg {
  height: 100%;
}
#shopping-cart-icon svg path {
  fill: #073e4b;
}
#shopping-cart-icon .counter-container {
  position: absolute;
  right: -10px;
  top: -10px;
  height: 25px;
  width: 25px;
  background-color: #f75c02;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-family: worksans-light, sans-serif !important;
  font-weight: lighter !important;
}
section#home-bar-top {
  top: 0;
  z-index: 100;
  box-sizing: border-box;
  height: 35px;
  background-color: #007691;
  padding: 0 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
section#home-bar-top.fixed {
  position: fixed;
  width: 75%;
}
section#home-bar-top a {
  color: #fff;
  padding-left: 60px;
  font-size: 12px;
}
section#home-bar-top img {
  padding-right: 5px;
}
@media screen and (max-width: 1820px) {
  section#home-bar-top {
    width: 100% !important;
  }
}
section#home-bar {
  z-index: 100;
  box-sizing: border-box;
  height: 79px;
  background-color: #0483a3;
  padding: 9px 0;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  position: relative;
  border: 1px solid #0483a3 !important;
}
section#home-bar.fixed {
  top: 35px;
  position: fixed;
  width: 75%;
}
@media (max-width: 576px) {
  section#home-bar.fixed {
    top: 0;
  }
}
@media screen and (max-width: 1820px) {
  section#home-bar {
    width: 100% !important;
  }
}
section#home-bar .brand-container {
  height: 60px;
  padding-left: 2vw;
  cursor: pointer;
}
@media screen and (max-width: 576px) {
  section#home-bar .brand-container {
    padding-left: 10px;
    height: 40px;
  }
}
section#home-bar .links-container {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  align-items: stretch;
  padding-left: 1rem;
  padding-right: 1rem;
}
section#home-bar .links-container a {
  color: #ffffff;
  font-family: worksans-light, sans-serif !important;
  font-weight: lighter !important;
  font-size: 16px;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}
section#home-bar .buttons-container {
  position: absolute;
  right: 0;
  padding-right: 2vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
section#home-bar .buttons-container button {
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: worksans-light, sans-serif !important;
  font-weight: lighter !important;
  font-size: 18px;
}
section#home-bar .buttons-container button.menu-btn {
  background-color: #073e4b;
}
@media screen and (max-width: 576px) {
  section#home-bar .buttons-container button {
    height: 40px;
    width: 53px;
  }
}
section#home-bar .menu-container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
@media screen and (max-width: 990px) {
  section#home-bar .menu-container {
    position: static;
  }
}
section#home-bar .menu-container .menu {
  position: absolute;
  top: 65px;
  right: 0;
  width: 220px;
  border-radius: 0 0 40px 40px;
  box-sizing: content-box;
  background-color: #0d4c5b;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 40px;
  margin: 0;
  z-index: 10;
}
@media screen and (max-width: 576px) {
  section#home-bar .menu-container .menu {
    top: 59.2px;
  }
}
@media screen and (max-width: 990px) {
  section#home-bar .menu-container .menu {
    right: 0;
    border-bottom-right-radius: 0;
  }
}
section#home-bar .menu-container .menu .menu-items {
  display: flex;
  flex-direction: column;
}
section#home-bar .menu-container .menu .menu-items a,
section#home-bar .menu-container .menu .menu-items span {
  display: block;
  color: #ffffff;
  font-family: worksans-regular, sans-serif;
  font-size: 14px;
  margin-top: 0.35rem;
  margin-bottom: 0.35rem;
}
section#home-bar .menu-container .menu .menu-items a.sign,
section#home-bar .menu-container .menu .menu-items span.sign {
  color: #26c5f0;
}
section#home-bar .menu-container .menu .menu-items div {
  height: 2px;
  border-radius: 5px;
  width: 150px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #f75c02;
}
section#home-bar .menu-container .menu .menu-items span {
  cursor: pointer;
}
section#home-bar .menu-container .menu .menu-items span:hover {
  text-decoration: underline;
}
@media screen and (max-width: 1820px) {
  section#home-bar {
    width: 100% !important;
  }
}
/* ===== COLORS ====== */
/* ===== SCREEN SIZE ====== */
/* ===== CENTER CONTAINER SIZE ====== */
/* ===== FONT SIZE ====== */
/* ===== SHADOW ====== */
/* ===== FONTS ====== */
/*
@font-light: "montserrat-light";
@font-regular: "montserrat-regular";
@font-medium: "montserrat-medium";
@font-bold: "montserrat-bold";
*/
/* ===== IMPORT ====== */
/* ===== GLOBAL CONFIG ====== */
body {
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
}
body #app {
  min-height: 100vh;
}
body .container-fluid,
body .container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
body a {
  color: #0483a3;
  cursor: pointer;
}
body a#step-back,
body a#step-next {
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #ffffff !important;
  margin: 45px 30px;
}
body a#step-back {
  position: unset !important;
  margin: 0 !important;
  width: 59px;
  background-color: #073e4b !important;
  border-radius: 330px;
}
body a#step-next {
  width: 221px;
  font-size: 16px;
  font-family: worksans-regular, sans-serif !important;
  font-weight: normal !important;
  background-color: #f75c02;
  border-radius: 25px;
}
body a#step-revenir {
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  color: #0d4c5b;
  margin: 45px 30px;
  position: unset !important;
  margin: 0 !important;
  min-width: 100px;
  border-radius: 330px;
  text-decoration: none;
}
body a#step-revenir img {
  margin-right: 15px;
}
body *::-webkit-scrollbar {
  height: 0;
  width: 0;
}
button {
  height: 52px;
  min-width: 52px;
  font-family: worksans-regular;
  font-size: 16px;
  border-radius: 25.5px;
  padding: 0 20px;
  background-color: #f75c02;
  color: #ffffff;
  border: none;
  outline: none;
}
button.step {
  min-width: 140px !important;
}
button.grey-bg {
  background-color: #a4a4a4 !important;
  cursor: not-allowed;
}
button.blueOpacity-bg {
  background-color: #0d4b5b60 !important;
  cursor: not-allowed;
}
button.blueOk-bg {
  background-color: #0d4c5b !important;
}
button.orangeOpacity-bg {
  background-color: #f75c023f !important;
  cursor: not-allowed;
}
.btn-edit-profil {
  background-color: transparent;
  border: 1px solid #0184a4;
  height: 40px;
  width: 125px;
  position: absolute;
  right: 20px;
  top: 0px;
}
.btn-edit-profil img {
  padding-right: 10px;
}
.btn-edit-profil span {
  color: #0184a4 !important;
}
.screen {
  min-height: 20vh;
  flex: 1;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.spoors-baseline {
  font-family: montserrat-extra-light, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 46px;
  letter-spacing: 0.5em;
  color: #ffffff;
}
.no-margin {
  margin: 0;
}
.no-padding {
  padding: 0;
}
.containt {
  min-height: 80vh;
}
.pointer {
  cursor: pointer;
}
.inputTop {
  height: 100px;
  background-color: #0c4f5e;
  display: flex;
  align-items: center;
  font-family: montserrat, sans-serif;
}
.inputTop a img {
  width: 20px;
}
.inputTop img {
  width: 40px;
  cursor: pointer;
}
.inputTop input {
  font-weight: 700;
  font-size: 1.5rem;
  color: white;
  border-bottom: 0px;
  text-align: center;
}
.inputTop label {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.5rem;
  color: white;
  border-bottom: 0px;
  text-align: center;
}
/* ===== FONTS ====== */
@font-face {
  font-family: "roboto-light";
  src: url("../fonts/Roboto-Light.ttf") format("truetype");
}
@font-face {
  font-family: "roboto-regular";
  src: url("../fonts/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "roboto-medium";
  src: url("../fonts/Roboto-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "roboto-bold";
  src: url("../fonts/Roboto-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "montserrat-extra-light";
  src: url("../fonts/Montserrat/Montserrat-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "montserrat-light";
  src: local("Montserrat"), url("../fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
}
@font-face {
  font-family: "montserrat-regular";
  src: url("../fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "montserrat-medium";
  src: url("../fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "montserrat-semi-bold";
  src: url("../fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "montserrat-bold";
  src: url("../fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "montserrat-extra-bold";
  src: url("../fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: worksans-extra-light;
  src: url("../fonts/WorkSans/WorkSans-ExtraLight.ttf");
}
@font-face {
  font-family: worksans-light;
  src: url("../fonts/WorkSans/WorkSans-Light.ttf");
}
@font-face {
  font-family: worksans-regular;
  src: url("../fonts/WorkSans/WorkSans-Regular.ttf");
}
@font-face {
  font-family: worksans-medium;
  src: url("../fonts/WorkSans/WorkSans-Medium.ttf");
}
@font-face {
  font-family: worksans-bold;
  src: url("../fonts/WorkSans/WorkSans-SemiBold.ttf");
}
.text-light {
  color: #ffffff;
}
.text-primary {
  color: #0483a3 !important;
}
.text-secondary {
  color: #26c5f0 !important;
}
.text-dark {
  color: #202020 !important;
}
.montserrat {
  font-family: montserrat-regular, sans-serif !important;
  font-weight: normal !important;
}
.montserrat-light {
  font-family: montserrat-light, sans-serif !important;
  font-weight: lighter !important;
}
.montserrat-bold {
  font-family: montserrat-bold, sans-serif !important;
  font-weight: bold !important;
}
.worksans {
  font-family: worksans-regular !important;
  font-weight: normal !important;
}
.worksans-light {
  font-family: worksans-light, sans-serif !important;
  font-weight: lighter !important;
}
.worksans-bold {
  font-family: worksans-bold, sans-serif !important;
  font-weight: bold !important;
}
/* ===== TEXT SIZE ====== */
.title {
  font-size: 36px;
  text-transform: uppercase;
  text-align: center;
  font-family: montserrat-bold, sans-serif;
  color: #0d4c5b;
}
@media screen and (max-width: 768px) {
  .title {
    font-size: 30px;
  }
}
.subtitle {
  font-size: 16px;
}
@media screen and (max-width: 768px) {
  .subtitle {
    /*		display: none;*/
  }
}
.body-1,
.body-2 {
  font-family: "worksans-light", sans-serif;
  font-weight: 100;
  line-height: 1.5rem;
  color: #626f73;
}
.body-1 {
  font-size: 20px;
}
@media screen and (max-width: 768px) {
  .body-1 {
    font-size: 22px;
  }
}
.body-2 {
  font-size: 16px;
}
/* ===== TEXT WEIGHT ====== */
.text-small {
  font-size: 80% !important;
}
.font-weight-light {
  font-family: "roboto-light" !important;
}
.font-weight-regular {
  font-family: "roboto-regular" !important;
}
.font-weight-medium {
  font-family: "roboto-medium" !important;
}
.font-weight-bold {
  font-family: "roboto-bold" !important;
}
/* ===== BACKGROUND COLOR ====== */
.blue-bg {
  background-color: rgba(4, 131, 163, 0.05);
}
/* ===== STEPS ====== */
div.step-number-box {
  height: 45px;
  width: 65px;
  border-radius: 18px;
  background-color: #f75c02;
  color: #ffffff;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  -webkit-box-shadow: 0px 8px 4px 0px rgba(247, 92, 2, 0.3);
  -moz-box-shadow: 0px 8px 4px 0px rgba(247, 92, 2, 0.3);
  box-shadow: 0px 8px 4px 0px rgba(247, 92, 2, 0.3);
}
/* ===== TOGGLE ====== */
.toggle-container {
  height: 33px;
  width: 60px;
  border-radius: 25px;
  background-color: #5b5b5b;
  display: flex;
  justify-content: start;
  align-items: center;
  transition: all 200ms ease-out;
}
.toggle-container.enabled {
  background-color: #f75c02;
}
.toggle-container.enabled .toggle-circle {
  left: 50%;
}
.toggle-container .toggle-circle {
  position: relative;
  left: 8%;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  background-color: white;
  cursor: pointer;
}
.block {
  padding-top: 20px;
  padding-bottom: 40px;
}
@media (max-width: 576px) {
  .block {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.pt-sm-block {
  padding-top: 20px;
}
@media (max-width: 576px) {
  .pt-sm-block {
    padding-top: 0;
  }
}
.pt-block {
  padding-top: 20px;
}
.pb-sm-block {
  padding-bottom: 40px;
}
@media (max-width: 576px) {
  .pb-sm-block {
    padding-bottom: 20px;
  }
}
.pb-block {
  padding-bottom: 40px;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-60 {
  margin-top: 60px !important;
}
@media (max-width: 576px) {
  .mt-sm-30 {
    margin-top: 30px;
  }
}
@media (max-width: 576px) {
  .mt-sm-60 {
    margin-top: 60px;
  }
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-60 {
  margin-bottom: 60px !important;
}
.my-30 {
  margin-bottom: 30px !important;
  margin-top: 30px !important;
}
.my-60 {
  margin-bottom: 60px !important;
  margin-top: 60px !important;
}
.inactif {
  opacity: 0.3;
}
@media (max-width: 350px) {
  .txt-mail {
    display: block;
    margin: 0 !important;
    position: relative;
    left: 50%;
    transform: translateX(-61%);
  }
}
/* ===== CGU ====== */
.CGU #CFC-cookies-modal {
  color: #0483a3;
  font-weight: bold;
  cursor: pointer;
}
.CGU p,
.CGU li {
  font-size: 12px;
}
.CGU h3 {
  font-size: 14px;
}
.CGU h2 {
  font-size: 16px;
}
.CGU h1 {
  font-size: 18px;
}
.CGU ul {
  padding-inline-start: 15px;
}
/* ===== STEP ====== */
#step-title {
  padding-bottom: 180px;
  background-color: rgba(4, 131, 163, 0.05);
}
@media screen and (max-width: 576px) {
  #step-title {
    padding-bottom: 150px;
  }
}
#step-title h1 {
  padding-top: 40px;
  font-family: montserrat-bold, sans-serif !important;
  font-weight: bold !important;
  font-size: 36px !important;
  color: #073e4b !important;
  margin: 0;
}
/* ===== PRODUCTS ====== */
#products-container {
  position: relative;
  min-height: 90px;
  width: 100%;
}
#products-container::after {
  content: "";
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  will-change: opacity;
  pointer-events: none;
  transition: all 400ms ease-in;
}
#products-container.hide::after {
  opacity: 0;
}
#products-container #scroller {
  min-height: 90px !important;
  width: 100% !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: scroll;
}
#products-container #scroller .product {
  min-height: 100% !important;
  min-width: 90px;
  background-color: indianred;
  cursor: pointer;
}
/* ===== BUTTONS CONTAINER ====== */
#buttons-container {
  display: flex !important;
  flex-direction: row;
  justify-content: center !important;
  margin: 60px 0 40px 0;
}
#buttons-container div {
  min-width: 250px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#buttons-container div .long-text {
  margin-left: 30px;
}
/* ===== HOME SIGN-IN / HOME RENEW / RENEW   BOARD ====== */
#home-signin,
#home-renew,
#renew {
  height: 700px;
}
#home-signin .board,
#home-renew .board,
#renew .board {
  transform: translateY(-150px);
}
/* ===== HORIZONTAL LIST ====== */
.horizontal-list {
  position: relative;
}
.horizontal-list.should-fade .hl-shadow {
  display: block;
  height: 100%;
  width: 80px;
  position: absolute;
  top: 0;
  right: 0;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  will-change: opacity;
  pointer-events: none;
}
.horizontal-list.withScrollVisible {
  scrollbar-width: thin;
  scrollbar-color: #073e4b;
}
@media screen and (max-width: 450px) {
  .horizontal-list.withScrollVisible ::-webkit-scrollbar {
    height: 0;
  }
}
.horizontal-list.withScrollVisible ::-webkit-scrollbar {
  padding: 20px !important;
  height: 10px;
  border: 1px solid #d5d5d5;
  border-radius: 10px;
}
.horizontal-list.withScrollVisible ::-webkit-scrollbar-thumb:horizontal {
  height: 10px;
  background-color: #073e4b;
  border-radius: 10px;
}
.horizontal-list .scroller {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow: auto;
}
/* ===== PRODUCT-CARD ====== */
.product-card {
  position: relative;
  min-height: 500px;
  width: 390px;
  background-color: #ffffff;
  border-radius: 53px;
  padding: 20px 35px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  -webkit-box-shadow: 0px 2px 5px 0px #c2c2c2;
  -moz-box-shadow: 0px 2px 5px 0px #c2c2c2;
  box-shadow: 0px 2px 5px 0px #c2c2c2;
}
@media screen and (max-width: 450px) {
  .product-card {
    width: 100% !important;
  }
}
.product-card.orange .header span {
  color: #f75c02;
}
.product-card.green .header span {
  color: #a3ce54;
}
.product-card.blue .header span {
  color: #0483a3;
}
.product-card .header {
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center !important;
  position: absolute;
  top: 20px;
  left: 30px;
  height: auto;
  width: 200px;
}
.product-card .header-image {
  display: inline-block;
  height: 30px !important;
  width: auto !important;
}
.product-card .header span {
  font-size: 12px !important;
  font-family: "worksans-regular", sans-serif !important;
  font-weight: normal !important;
}
.product-card img.product {
  width: 280px;
  height: auto;
  margin: 20px 0;
}
.product-card .card-text {
  color: #626f73;
  font-family: worksans-regular, sans-serif !important;
  font-weight: normal !important;
  font-size: 1rem;
  height: 60px !important;
}
/* ===== COUNTER - BLOC ====== */
div.counter-bloc {
  position: relative;
  top: 0;
  left: 0px;
  cursor: pointer;
}
/* NicolasC */
/* ===== POSITION - ELEMENT ====== */
.position-relative {
  position: relative;
}
.padd20 {
  padding: 20px;
}
/* Background Document */
/* background.less
Backgrounds
            ================================================== */
input,
select,
textarea,
form,
button {
  outline: none !important;
}
.bg-white {
  background: #ffffff;
}
.bg-primary {
  background-color: #0483a3 !important;
  color: #ffffff;
}
.bg-secondary {
  background-color: #26c5f0 !important;
  color: #ffffff;
}
.bg-primary h1,
.bg-secondary h1,
.bg-primary h2,
.bg-secondary h2,
.bg-primary h3,
.bg-secondary h3,
.bg-primary h4,
.bg-secondary h4,
.bg-primary div,
.bg-secondary div,
.bg-primary span,
.bg-secondary span,
.bg-primary p,
.bg-secondary p {
  color: #ffffff !important;
}
.bg-warning {
  background-color: #f2c94c !important;
  color: #ffffff;
}
.bg-car {
  background-color: #f7b538 !important;
  color: #ffffff;
}
.bg-building {
  background-color: #0d4c5b !important;
  color: #ffffff;
}
.bg-img.form-row {
  background-image: url("../img/bg-img.png");
  background-size: auto;
  background-position: center center;
  background-repeat: no-repeat;
}
.bg-img:not(.form-row) {
  border: 0.5px solid #0483a3;
  border-radius: 10px;
  background-color: #f7fcfd;
  background-image: url("../img/bg-img.png");
  background-size: auto;
  background-position: center center;
  background-repeat: no-repeat;
}
.bg-btn-sticky {
  background: #ffffff;
  box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.25);
}
.fix {
  display: block;
  width: 100%;
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
}
@media (max-width: 576px) {
  .fix {
    height: 140px;
  }
}
.carton-head-primary,
.carton-head-primary + .carton-body a[role="tab"].active div.icon {
  background-color: #0483a3;
  border-color: #0483a3;
}
.carton-head-secondary,
.carton-head-secondary + .carton-body a[role="tab"].active div.icon {
  background-color: #0d4c5b;
  border-color: #0d4c5b;
}
.carton-head-car,
.carton-head-car + .carton-body a[role="tab"].active div.icon {
  background-color: #f7b538;
  border-color: #f7b538;
}
.carton-head-building,
.carton-head-building + .carton-body a[role="tab"].active div.icon {
  background-color: #0d4c5b;
  border-color: #0d4c5b;
}
.carton-head-danger,
.carton-head-danger + .carton-body a[role="tab"].active div.icon {
  background-color: #e6233e;
  border-color: #e6233e;
}
.carton-head-success,
.carton-head-success + .carton-body a[role="tab"].active div.icon {
  background-color: #43cf36;
  border-color: #43cf36;
}
.carton-head-primary h1,
.carton-head-secondary h1,
.carton-head-car h1,
.carton-head-building h1,
.carton-head-danger h1,
.carton-head-primary h2,
.carton-head-secondary h2,
.carton-head-car h2,
.carton-head-building h2,
.carton-head-danger h2,
.carton-head-primary h3,
.carton-head-secondary h3,
.carton-head-car h3,
.carton-head-building h3,
.carton-head-danger h3,
.carton-head-primary h4,
.carton-head-secondary h4,
.carton-head-car h4,
.carton-head-building h4,
.carton-head-danger h4,
.carton-head-primary div,
.carton-head-secondary div,
.carton-head-car div,
.carton-head-building div,
.carton-head-danger div,
.carton-head-primary span,
.carton-head-secondary span,
.carton-head-car span,
.carton-head-building span,
.carton-head-danger span,
.carton-head-primary p,
.carton-head-secondary p,
.carton-head-car p,
.carton-head-building p,
.carton-head-danger p {
  color: #ffffff !important;
}
.carton-body-primary {
  border-color: #0483a3;
}
.carton-body-secondary {
  border-color: #26c5f0;
}
.carton-body-secondary a[role="tab"].active div.icon {
  background-color: #26c5f0;
}
.carton-body-warning {
  border-color: #f2c94c;
}
.carton-body-warning a[role="tab"].active div.icon {
  background-color: #f2c94c;
}
.carton-body-danger {
  border-color: #e6233e;
}
.carton-body-danger a[role="tab"].active div.icon {
  background-color: #e6233e;
}
.carton-body-success {
  border-color: #43cf36;
}
.carton-body-success a[role="tab"].active div.icon {
  background-color: #43cf36;
}
/* Typo Document */
/* typo.less
Typo
            ================================================== */
h1,
.h1 {
  margin: 20px auto;
  font-size: 32px;
  line-height: 46px;
  font-family: "roboto-medium";
}
@media (max-width: 576px) {
  h1,
  .h1 {
    font-size: 26px;
    line-height: 31px;
  }
}
h2,
.h2 {
  font-size: 18px;
  line-height: 20px;
  font-family: "roboto-medium";
  margin: 20px auto 16px;
}
@media (max-width: 576px) {
  h2,
  .h2 {
    font-size: 18px;
    line-height: 20px;
    margin: 15px auto 10px;
  }
}
h3,
.h3 {
  font-size: 18px;
  line-height: 20px;
  font-family: "roboto-light";
  margin: 30px auto 16px;
}
@media (max-width: 576px) {
  h3,
  .h3 {
    font-size: 18px;
    line-height: 20px;
  }
}
h4,
.h4 {
  font-size: 18px;
  line-height: 22px;
  font-family: "roboto-medium";
  margin: 20px auto 16px;
}
@media (max-width: 576px) {
  h4,
  .h4 {
    font-size: 18px;
    line-height: 22px;
    margin: 15px auto 10px;
  }
}
p,
table {
  font-size: 16px;
  line-height: 20px;
  font-family: "roboto-light";
  margin-bottom: 12px;
}
strong {
  font-family: "roboto-bold";
}
a {
  color: #26c5f0;
}
a:hover {
  color: #26c5f0;
}
ol {
  padding-left: 0;
}
ol > li:not([data-target="#carouselSpoors"]) {
  position: relative;
  list-style: none;
  padding-left: 20px;
  font-family: "roboto-light";
}
ol > li:not([data-target="#carouselSpoors"]) > span.number {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 16px;
  line-height: 1.5rem;
  font-family: "roboto-medium";
  color: #0483a3;
}
em {
  font-style: inherit;
  font-size: 11px;
  line-height: 11px;
  display: block;
}
h3 + em {
  margin-bottom: 16px;
}
.font-size-1 {
  font-size: 1rem;
}
.font-size-1-1 {
  font-size: 1.1rem;
}
.font-size-1-2 {
  font-size: 1.2rem;
}
.font-size-1-3 {
  font-size: 1.3rem;
}
.font-size-1-4 {
  font-size: 1.4rem;
}
.font-size-1-5 {
  font-size: 1.5rem;
}
.font-size-2 {
  font-size: 2rem;
}
/* Buttons Document */
/* buttons.less
Buttons
            ================================================== */
.btn {
  border-radius: 25px;
  margin: 20px 0;
  font-size: 26px;
  line-height: 30px;
  font-family: "roboto-medium";
  padding: 5px 50px;
}
@media (max-width: 576px) {
  .btn {
    font-size: 20px;
    line-height: 23px;
    padding: 5px 40px;
  }
}
.btn:focus,
.btn.focus {
  box-shadow: none;
}
.btn-primary {
  color: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.btn-primary,
.btn-primary:hover,
.btn-primary:focus {
  background-color: #f75c02;
  border-color: #f75c02;
}
.btn-primary.white {
  color: #f75c02;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.btn-primary.white,
.btn-primary.white:hover,
.btn-primary.white:focus {
  color: #f75c02;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-secondary {
  color: #ffffff;
  text-decoration: none;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  background-color: #a4a4a4;
  border-color: #a4a4a4;
  text-decoration: none;
}
.btn-primary:hover,
.btn-secondary:hover,
.btn-primary:focus,
.btn-secondary:focus,
.btn-primary.focus,
.btn-secondary.focus {
  box-shadow: none;
}
.btn-primary.disabled,
.btn-secondary.disabled,
.btn-primary:disabled,
.btn-secondary:disabled {
  color: #ffffff;
  background-color: #a4a4a4 !important;
  border-color: #a4a4a4 !important;
  opacity: 1;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.show > .btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #f75c02;
  border-color: #f75c02;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-link {
  font-family: "roboto-regular";
  color: #f75c02;
  text-decoration: underline;
}
.btn-link:hover {
  color: #f75c02;
}
.btn-link:hover,
.btn-link:focus {
  text-decoration: underline;
  box-shadow: none;
}
.btn-link:disabled,
.btn-link.disabled {
  color: #a4a4a4;
  pointer-events: none;
}
.btn-title {
  font-size: 44px;
  line-height: 53px;
  padding: 0.25rem 0;
}
@media (max-width: 576px) {
  .btn-title {
    font-size: 14px;
    line-height: 18px;
    padding: 0.2rem 1rem;
    margin-right: 5%;
  }
}
.btn-small {
  font-size: 16px;
  padding: 5px 35px;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
@media (max-width: 576px) {
  .btn-sm-block {
    display: block;
    width: 100%;
  }
}
.btn-block {
  padding: 5px 0 !important;
}
.btn-align-v-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-height: 635px) {
  .btn-align-v-center {
    top: inherit;
    position: inherit;
  }
}
.sticky {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  bottom: -126px;
  z-index: 1000;
}
.sticky-btn-bg {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  bottom: 0;
  z-index: 1000;
}
.sticky-top {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 30px;
  z-index: 1000;
}
/* buttons.less
Buttons - RETOUR
            ================================================== */
a.retour {
  color: #a4a4a4;
}
@media (max-width: 576px) {
  a.retour {
    position: relative;
    left: -15px;
  }
}
a.retour:hover {
  color: #a4a4a4;
}
.back {
  position: absolute;
  top: 20%;
  left: 5%;
  z-index: 10;
}
@media (max-width: 576px) {
  .back {
    top: 22%;
  }
}
.back-more {
  top: 27%;
}
.back a {
  color: #a4a4a4 !important;
}
.back a:hover {
  color: #a4a4a4;
}
.back-big {
  position: absolute;
  top: 40%;
  left: -10%;
  z-index: 10;
}
.back-big a {
  color: #a4a4a4 !important;
  font-family: "roboto-light";
}
.back-big a:hover {
  color: #a4a4a4;
}
/* buttons.less
Buttons icone - cercle
            ================================================== */
.btn-circle {
  padding: 0;
  width: 50px;
  height: 50px;
  background-color: #e8e8e8;
  border: 1px solid #e8e8e8;
  border-radius: 50%;
  transition: all 0.25s ease 0s;
}
.btn-circle img {
  filter: brightness(1) contrast(1);
  transition: all 0.25s ease 0s;
}
.btn-circle:hover,
.btn-circle:focus {
  background-color: #f75c02;
  border-color: #f75c02;
}
.btn-circle:hover img,
.btn-circle:focus img {
  filter: brightness(100) contrast(100);
}
/* NAVBAR Document */
/* navbar.less
Navbar
            ================================================== */
.navbar {
  padding: 9px 0;
}
@media (max-width: 576px) {
  .navbar {
    padding: 10px 0;
  }
}
.navbar.bg-primary {
  background-color: #0483a3 !important;
}
.navbar.bg-primary .navbar-brand {
  display: block;
  margin: auto;
  padding: 0;
}
.navbar.bg-primary .navbar-brand img {
  height: 25px;
}
@media (max-width: 576px) {
  .navbar.bg-primary .navbar-brand img {
    height: 25px;
    width: auto;
  }
}
.navbar.bg-primary .navbar-toggler {
  position: absolute;
  right: 8.5%;
  border: 0;
  display: block;
  height: 19px;
  font-size: 1px;
  line-height: 0;
}
@media (max-width: 576px) {
  .navbar.bg-primary .navbar-toggler {
    height: 14px;
    line-height: 0;
  }
}
.navbar.bg-primary .navbar-toggler:focus {
  outline: none;
}
.navbar.bg-primary .navbar-toggler.collapsed .navbar-toggler-icon,
.navbar.bg-primary .navbar-toggler[aria-expanded="false"] .navbar-toggler-icon {
  background-image: none;
  display: inline-block;
}
.navbar.bg-primary .navbar-toggler.collapsed .navbar-toggler-icon:after,
.navbar.bg-primary .navbar-toggler[aria-expanded="false"] .navbar-toggler-icon:after,
.navbar.bg-primary .navbar-toggler.collapsed .navbar-toggler-icon:before,
.navbar.bg-primary .navbar-toggler[aria-expanded="false"] .navbar-toggler-icon:before {
  content: "";
  display: block;
  position: absolute;
}
.navbar.bg-primary .navbar-toggler.collapsed .navbar-toggler-icon,
.navbar.bg-primary .navbar-toggler[aria-expanded="false"] .navbar-toggler-icon,
.navbar.bg-primary .navbar-toggler.collapsed .navbar-toggler-icon:after,
.navbar.bg-primary .navbar-toggler[aria-expanded="false"] .navbar-toggler-icon:after,
.navbar.bg-primary .navbar-toggler.collapsed .navbar-toggler-icon:before,
.navbar.bg-primary .navbar-toggler[aria-expanded="false"] .navbar-toggler-icon:before {
  width: 20px;
  height: 3px;
  border-radius: 1.5px;
  background: #ffffff;
}
@media (max-width: 576px) {
  .navbar.bg-primary .navbar-toggler.collapsed .navbar-toggler-icon,
  .navbar.bg-primary .navbar-toggler[aria-expanded="false"] .navbar-toggler-icon,
  .navbar.bg-primary .navbar-toggler.collapsed .navbar-toggler-icon:after,
  .navbar.bg-primary .navbar-toggler[aria-expanded="false"] .navbar-toggler-icon:after,
  .navbar.bg-primary .navbar-toggler.collapsed .navbar-toggler-icon:before,
  .navbar.bg-primary .navbar-toggler[aria-expanded="false"] .navbar-toggler-icon:before {
    width: 15px;
    height: 2px;
  }
}
.navbar.bg-primary .navbar-toggler.collapsed .navbar-toggler-icon:before,
.navbar.bg-primary .navbar-toggler[aria-expanded="false"] .navbar-toggler-icon:before {
  top: 0;
  transform-origin: center center;
  transform: translateY(0) rotate(0deg);
  transition: all 0.25s ease 0s;
}
.navbar.bg-primary .navbar-toggler.collapsed .navbar-toggler-icon:after,
.navbar.bg-primary .navbar-toggler[aria-expanded="false"] .navbar-toggler-icon:after {
  bottom: 0;
  transform-origin: center center;
  transform: translateY(0) rotate(0deg);
  transition: all 0.25s ease 0s;
}
.navbar.bg-primary .navbar-toggler:not(collapsed)[aria-expanded="true"] .navbar-toggler-icon {
  background-image: none;
  display: inline-block;
  width: 20px;
  height: 3px;
  background: none;
}
@media (max-width: 576px) {
  .navbar.bg-primary .navbar-toggler:not(collapsed)[aria-expanded="true"] .navbar-toggler-icon {
    width: 15px;
    height: 2.35px;
  }
}
.navbar.bg-primary .navbar-toggler:not(collapsed)[aria-expanded="true"] .navbar-toggler-icon:after,
.navbar.bg-primary .navbar-toggler:not(collapsed)[aria-expanded="true"] .navbar-toggler-icon:before {
  content: "";
  display: block;
  position: absolute;
}
.navbar.bg-primary .navbar-toggler:not(collapsed)[aria-expanded="true"] .navbar-toggler-icon:after,
.navbar.bg-primary .navbar-toggler:not(collapsed)[aria-expanded="true"] .navbar-toggler-icon:before {
  width: 20px;
  height: 3px;
  border-radius: 1.5px;
  background: #ffffff;
}
@media (max-width: 576px) {
  .navbar.bg-primary .navbar-toggler:not(collapsed)[aria-expanded="true"] .navbar-toggler-icon:after,
  .navbar.bg-primary .navbar-toggler:not(collapsed)[aria-expanded="true"] .navbar-toggler-icon:before {
    width: 15px;
    height: 2.35px;
  }
}
.navbar.bg-primary .navbar-toggler:not(collapsed)[aria-expanded="true"] .navbar-toggler-icon:before {
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  transition: all 0.25s ease 0s;
}
.navbar.bg-primary .navbar-toggler:not(collapsed)[aria-expanded="true"] .navbar-toggler-icon:after {
  bottom: 50%;
  transform: translateY(50%) rotate(45deg);
  transition: all 0.25s ease 0s;
}
#navbarToggleExternalContent {
  position: absolute;
  top: 48px;
  right: 8.5%;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15);
  border-radius: 0px 0px 8px 8px;
  z-index: 99999999;
}
@media (max-width: 576px) {
  #navbarToggleExternalContent {
    top: 50px;
    right: 0;
  }
}
#navbarToggleExternalContent > div {
  border-radius: 0px 0px 8px 8px;
}
#navbarToggleExternalContent > div ul.navbar-nav li {
  padding: 0.5rem 1.5rem;
}
#navbarToggleExternalContent > div ul.navbar-nav li,
#navbarToggleExternalContent > div ul.navbar-nav li a {
  font-size: 18px;
  color: #202020;
  font-family: "roboto-regular";
}
@media (max-width: 576px) {
  #navbarToggleExternalContent > div ul.navbar-nav li,
  #navbarToggleExternalContent > div ul.navbar-nav li a {
    font-size: 14px;
  }
}
#navbarToggleExternalContent > div ul.navbar-nav li:hover {
  background: #0483a3;
}
#navbarToggleExternalContent > div ul.navbar-nav li:hover:last-child {
  border-radius: 0 0 8px 8px;
}
#navbarToggleExternalContent > div ul.navbar-nav li:hover a {
  color: #ffffff !important;
}
.navbar.d-flex .navbar-brand {
  padding: 0 15px;
}
.navbar.d-flex .navbar-brand img {
  height: 21px;
}
.navbar.d-flex span {
  font-family: "roboto-light";
  font-size: 14px;
}
.navbar.d-flex .navbar-toggler {
  position: relative;
  right: inherit;
}
.card-body-menu {
  padding: 0;
  background: transparent;
  margin: 0 -24px;
}
.card-body-menu ul {
  padding: 0;
}
.card-body-menu ul li {
  padding-left: 2rem !important;
  list-style: none;
}
.card-body-menu ul li a {
  padding: 0 !important;
}
#navbarToggleExternalContent > div ul.navbar-nav li.collapse-item:hover {
  background: #ffffff;
}
#navbarToggleExternalContent > div ul.navbar-nav li.collapse-item:hover a {
  color: #202020 !important;
}
#navbarToggleExternalContent > div ul.navbar-nav li.collapse-item p {
  position: relative;
}
#navbarToggleExternalContent > div ul.navbar-nav li.collapse-item p .collapse-menu[aria-expanded="false"] img,
#navbarToggleExternalContent > div ul.navbar-nav li.collapse-item p .collapse-menu.collapsed[aria-expanded="false"] img {
  position: absolute;
  height: 7px;
  right: 0;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  transition: all 0.25s ease 0s;
}
#navbarToggleExternalContent > div ul.navbar-nav li.collapse-item p .collapse-menu[aria-expanded="true"] img {
  position: absolute;
  height: 7px;
  right: 0;
  top: 50%;
  transform: translateY(-50%) rotate(0deg);
  transition: all 0.25s ease 0s;
}
#navbarToggleExternalContent > div ul.navbar-nav li.collapse-item .card-body-menu ul li:hover {
  background: #0483a3;
}
#navbarToggleExternalContent > div ul.navbar-nav li.collapse-item .card-body-menu ul li:hover a {
  color: #ffffff;
}
/* SUBMENU Document */
/* Submenu
            ================================================== */
.menu-prod {
  position: absolute;
  display: block;
  width: 100%;
  background: rgba(13, 131, 163, 0.8);
  z-index: 1;
}
.menu-prod div {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
}
.menu-prod a {
  font-family: "roboto-light";
  font-size: 18px;
  line-height: 18px;
  color: #ffffff;
  position: relative;
}
@media (max-width: 576px) {
  .menu-prod a {
    font-size: 14px;
    line-height: 16px;
  }
}
.menu-prod a:hover {
  text-decoration: none;
  font-family: "roboto-medium";
}
.menu-prod a:hover:after {
  content: "";
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: -1rem;
  background: #ffffff;
  left: 0;
}
@media (max-width: 576px) {
  .menu-prod a:hover:after {
    bottom: -0.5rem;
    height: 3px;
  }
}
/* ===== IMPORT ====== */
/* ===== FOOTER CONFIG ====== */
/* ===== FOOTER ====== */
#footer {
  color: #ffffff;
  background-color: #0d4c5b;
}
#footer .spacer {
  flex-grow: 1;
}
#footer div {
  padding: 0;
  text-align: center;
}
#footer div.row {
  margin: 0;
}
#footer .textual-information {
  background-color: #0d4c5b;
}
#footer .textual-information .footer-card div {
  padding: 0;
}
#footer .textual-information .footer-card h3 {
  font-family: montserrat-medium, sans-serif !important;
  font-weight: normal !important;
  padding-bottom: 10px;
  border-bottom: 2px solid #073e4b;
}
#footer .textual-information .footer-card ul {
  padding-left: 10px;
}
#footer .textual-information .footer-card ul li {
  color: #073e4b;
}
#footer .textual-information .footer-card ul li a {
  color: #ffffff;
  font-family: worksans-light, sans-serif !important;
  font-weight: lighter !important;
  font-size: 14px;
}
#footer .textual-information .footer-card .spoors-content {
  text-align: left;
  color: #ffffff;
}
#footer .textual-information .footer-card .spoors-content p {
  font-family: worksans-light, sans-serif !important;
  font-weight: lighter !important;
  font-size: 14px;
}
#footer .textual-information .footer-card .spoors-content strong {
  font-family: worksans-bold, sans-serif !important;
  font-weight: bold !important;
  font-size: 14px;
}
#footer .textual-information .logo-container {
  background-image: url("../img/spoors-logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 50px;
}
@media screen and (max-width: 768px) {
  #footer .textual-information .logo-container {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
}
#footer .copy-right {
  font-family: worksans-light, sans-serif;
  font-size: 16px;
  background-color: #073e4b;
  text-align: left;
}
#footer .copy-right,
#footer .footer-items {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
/* ===== IMPORT ====== */
/* ===== GLOBAL ====== */
.home {
  overflow-x: hidden;
}
.home * {
  font-family: montserrat-regular, sans-serif;
}
@media screen and (max-width: 768px) {
  .home .row {
    margin: 0;
    padding: 0;
  }
}
#home-description {
  padding: 40px 10%;
  background: #fff;
}
#home-description .title {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-family: montserrat-regular, sans-serif;
  font-size: 30px;
  color: #0d4c5b;
}
#home-description .img-col {
  display: flex;
  justify-content: space-around;
}
#home-description .img-col .column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#home-description .img-col .column img {
  margin-bottom: 10px;
}
#home-description .img-col .column .img-title {
  text-transform: uppercase;
  font-family: montserrat, sans-serif;
  font-size: 21px;
  color: #0d4c5b;
}
#home-description .img-col .column .img-subtitle {
  font-family: montserrat, sans-serif;
  font-size: 13px;
  color: #0d4c5b;
}
@media (max-width: 600px) {
  #home-description .title {
    font-size: 18px;
  }
  #home-description .img-col .column img {
    transform: scale(0.8);
  }
  #home-description .img-col .column .img-title {
    font-size: 17px;
  }
  #home-description .img-col .column .img-subtitle {
    font-size: 12px;
  }
}
@media (max-width: 500px) {
  #home-description .title {
    font-size: 14px;
  }
  #home-description .img-col .column img {
    transform: scale(0.7);
  }
  #home-description .img-col .column .img-title {
    font-size: 15px;
  }
  #home-description .img-col .column .img-subtitle {
    font-size: 10px;
  }
}
@media (max-width: 370px) {
  #home-description .title {
    font-size: 13px;
  }
  #home-description .img-col {
    justify-content: space-between;
  }
  #home-description .img-col .column img {
    transform: scale(0.6);
  }
  #home-description .img-col .column .img-title {
    font-size: 13px;
  }
  #home-description .img-col .column .img-subtitle {
    font-size: 9px;
  }
}
#home-products .subtitle,
#home-functioning .subtitle {
  font-family: montserrat-regular, sans-serif;
  font-size: 36px;
  color: #0d4c5b;
  text-align: center;
}
@media (max-width: 768px) {
  #home-products .subtitle,
  #home-functioning .subtitle {
    font-size: 30px;
  }
}
#home-functioning,
#home-installation {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
#home-functioning .step,
#home-installation .home-installation-step .title {
  font-family: worksans-bold;
  font-weight: bold;
  font-size: 24px;
  text-transform: none;
  color: #0483a3;
}
@media screen and (max-width: 576px) {
  #home-functioning .step,
  #home-installation .home-installation-step .title {
    font-size: 19px;
  }
}
/* ===== HEADER ====== */
#home-header {
  height: 714px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: top;
  background-position-x: left;
  position: relative;
}
@media screen and (max-width: 576px) {
  #home-header {
    height: 800px;
    background-position: center;
    z-index: 5;
    margin-bottom: 5vh;
  }
}
@media screen and (max-width: 450px) {
  #home-header {
    height: 100vh;
    margin-bottom: 5px;
  }
}
#home-header .blue-container {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 72%;
  width: 51%;
  border-top-left-radius: 119px;
  background-color: #0483a3;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  color: #ffffff;
  padding-left: 7%;
}
@media screen and (max-width: 1040px) {
  #home-header .blue-container {
    padding-left: 5%;
    height: 62%;
    width: 70%;
  }
}
@media screen and (max-width: 576px) {
  #home-header .blue-container {
    max-height: 420px;
    width: 100vw;
    align-items: center;
    border-radius: 75px 38px 43px 10px;
    transform: translateY(40%);
  }
}
@media screen and (max-width: 450px) {
  #home-header .blue-container {
    transform: translateY(10%);
  }
}
#home-header .blue-container .information-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 15px;
}
@media screen and (max-width: 576px) {
  #home-header .blue-container .information-container {
    padding: 30px;
    height: 100%;
    width: 100%;
  }
}
#home-header .blue-container .information-container .title {
  text-align: left;
  font-family: montserrat-regular, sans-serif !important;
  font-weight: normal !important;
  color: #ffffff;
  font-size: 22px;
  text-transform: inherit;
}
@media screen and (max-width: 1040px) {
  #home-header .blue-container .information-container .title {
    font-size: 14px;
  }
}
@media screen and (max-width: 450px) {
  #home-header .blue-container .information-container .title {
    font-size: 12px;
  }
}
#home-header .blue-container .information-container .title span {
  text-align: left;
  font-family: montserrat-regular, sans-serif;
  font-weight: normal;
  color: #ffffff;
  font-size: 22px;
  text-transform: inherit;
}
@media screen and (max-width: 1040px) {
  #home-header .blue-container .information-container .title span {
    font-size: 18px;
  }
}
@media screen and (max-width: 450px) {
  #home-header .blue-container .information-container .title span {
    font-size: 18px;
  }
}
#home-header .blue-container .information-container ul {
  list-style-type: none;
  padding: 0;
  margin: 20px 0 !important;
}
@media screen and (max-width: 1040px) {
  #home-header .blue-container .information-container ul {
    margin: 10px 0 !important;
  }
}
#home-header .blue-container .information-container ul li {
  font-family: worksans-bold, sans-serif;
  font-weight: bolder;
  color: #073e4b;
  font-size: 25px;
}
@media screen and (max-width: 1040px) {
  #home-header .blue-container .information-container ul li {
    font-size: 21px;
    line-height: 21px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 450px) {
  #home-header .blue-container .information-container ul li {
    font-size: 19px;
    line-height: 19px;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
}
#home-header .blue-container .information-container .line-separator {
  height: 2px;
  width: 100%;
  border-radius: 50px;
  background-color: #073e4b;
}
#home-header .blue-container .information-container span {
  font-family: montserrat-semi-bold, sans-serif;
  font-weight: bold;
  margin: 5% 0;
  font-size: 1.1rem;
}
@media screen and (max-width: 1040px) {
  #home-header .blue-container .information-container span {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 450px) {
  #home-header .blue-container .information-container span {
    font-size: 0.8rem;
  }
}
#home-header .blue-container .information-container .buttons-container {
  min-width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media screen and (max-width: 576px) {
  #home-header .blue-container .information-container .buttons-container {
    width: 100%;
    justify-content: center;
    position: static;
  }
}
#home-header .blue-container .information-container .buttons-container a,
#home-header .blue-container .information-container .buttons-container a.try-products {
  cursor: pointer;
}
#home-header .blue-container .information-container .buttons-container a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 52px;
  min-width: 52px;
  font-family: worksans-regular;
  font-size: 16px;
  border-radius: 25.5px;
  padding: 0 20px;
  background-color: #f75c02;
  color: #ffffff;
  border: none;
  outline: none;
}
#home-header .blue-container .information-container .buttons-container a.step {
  min-width: 140px !important;
}
#home-header .blue-container .information-container .buttons-container a.grey-bg {
  background-color: #a4a4a4 !important;
  cursor: not-allowed;
}
#home-header .blue-container .information-container .buttons-container a.blueOpacity-bg {
  background-color: #0d4b5b60 !important;
  cursor: not-allowed;
}
#home-header .blue-container .information-container .buttons-container a.blueOk-bg {
  background-color: #0d4c5b !important;
}
#home-header .blue-container .information-container .buttons-container a.orangeOpacity-bg {
  background-color: #f75c023f !important;
  cursor: not-allowed;
}
@media screen and (max-width: 1040px) {
  #home-header .blue-container .information-container .buttons-container a.try-products {
    display: none;
  }
}
@media screen and (max-width: 576px) {
  #home-header .blue-container .information-container .buttons-container a {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
}
#home-header .blue-container .information-container .buttons-container a:last-of-type {
  background-color: #073e4b;
}
/* ===== PRODUCTS ====== */
#home-products {
  margin-bottom: 50px;
  background-color: rgba(4, 131, 163, 0.05);
}
#home-products .information-container {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
@media screen and (max-width: 991px) {
  #home-products .information-container {
    padding: 40px 0 0 0 !important;
  }
}
#home-products .information-container .textual-container .subtitle {
  margin-top: 8px;
  width: 155px;
}
#home-products .information-container .text-container {
  color: #626f73;
  font-family: worksans-light, sans-serif;
  font-weight: lighter;
  font-size: 19px;
  line-height: 35px;
}
@media screen and (max-width: 991px) {
  #home-products .information-container .text-container {
    font-family: "worksans-light", sans-serif;
    font-weight: 100;
    line-height: 1.5rem;
    color: #626f73;
    font-size: 16px;
  }
}
@media screen and (max-width: 991px) {
  #home-products .information-container {
    border-bottom-right-radius: 43px;
    border-bottom-left-radius: 92px;
  }
}
#home-products .products-container {
  margin-top: 200px;
}
#home-products .product-card {
  cursor: pointer;
}
@media screen and (max-width: 1500px) {
  #home-products .product-card {
    width: 340px;
  }
}
@media screen and (max-width: 1199px) {
  #home-products .product-card {
    width: 270px;
  }
}
@media screen and (max-width: 991px) {
  #home-products .product-card {
    width: 340px;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 450px) {
  #home-products .product-card {
    width: 100%;
  }
}
#home-products .product-card.orange .card-title {
  color: #f75c02;
}
#home-products .product-card.orange .redirection {
  background-color: #f75c02;
}
#home-products .product-card.green .card-title {
  color: #a3ce54;
}
#home-products .product-card.green .redirection {
  background-color: #a3ce54;
}
#home-products .product-card.blue .card-title {
  color: #0483a3;
}
#home-products .product-card.blue .redirection {
  background-color: #0483a3;
}
#home-products .product-card img.product {
  margin: 10px 0;
}
#home-products .product-card .card-title {
  font-family: "worksans-regular", sans-serif !important;
  font-weight: normal !important;
  line-height: 32px;
}
#home-products .product-card .card-title strong {
  font-family: "worksans-bold", sans-serif !important;
  font-weight: bold !important;
  font-size: 2rem;
}
@media screen and (max-width: 1199px) {
  #home-products .product-card .card-text {
    padding: 0 15px;
  }
}
#home-products .product-card a.redirection {
  display: flex;
  color: #ffffff;
  height: 50px;
  width: 200px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background-color: #202020;
  margin: 30px 50px;
}
#home-products .product-card a.redirection:hover {
  text-decoration: none;
}
#home-products .subsubtitle {
  font-family: worksans-regular, sans-serif;
  color: #626f73;
}
#home-products .list-product {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 50px;
}
#home-products .list-product--item {
  height: 445.5px;
  width: 315px;
  margin: 10px 31px;
}
#home-products .list-product--item img {
  height: 445.5px;
  width: 315px;
}
#home-products .list-product--item .product-block {
  width: 315px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  bottom: 39%;
  padding: 0 26px;
}
#home-products .list-product--item .product-block .product-name {
  font-family: montserrat-bold, sans-serif;
  padding-bottom: 7px;
  font-size: 20px;
}
#home-products .list-product--item .product-block .product-desc {
  font-family: montserrat-light, sans-serif;
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  padding-bottom: 18px;
}
#home-products .list-product--item .product-block .product-price {
  font-family: montserrat-bold, sans-serif;
  font-size: 16px;
}
#home-products .list-product--item .product-block .product-link {
  font-family: montserrat-bold, sans-serif;
  text-decoration: underline;
  color: #fff;
  font-size: 13px;
}
#home-products .list-product--more {
  display: inline-block;
  background: #0483a3;
  margin: 10px 31px;
}
#home-products .list-product--more a {
  font-family: montserrat-bold, sans-serif;
  color: #fff;
  height: 52px;
  min-width: 52px;
  font-size: 22px;
  border-radius: 25.5px;
  padding: 0 20px;
  background-color: #f75c02;
  border: none;
  outline: none;
  width: 35%;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  padding-top: 10px;
}
#home-products .list-product--more .more-block-full {
  padding: 25px 0;
  width: 100%;
  height: 175px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
#home-products .list-product--more .more-block-full .more-title {
  font-family: montserrat-bold, sans-serif;
  color: #fff;
  font-size: 40px;
}
#home-products .list-product--more .more-block-item {
  padding: 25px 15px;
  height: 445.5px;
  width: 315px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
#home-products .list-product--more .more-block-item .more-title {
  font-family: montserrat-bold, sans-serif;
  color: #fff;
  font-size: 35px;
}
#home-products .list-product--more .more-block-item a {
  width: 85%;
}
@media (max-width: 1440px) {
  #home-products .list-product--item {
    height: 356.4px;
    width: 252px;
  }
  #home-products .list-product--item img {
    height: 356.4px;
    width: 252px;
  }
  #home-products .list-product--item .product-block {
    height: 150px;
    width: 252px;
    padding: 0 21px;
  }
  #home-products .list-product--item .product-block .product-name {
    font-size: 16px;
    padding-bottom: 4px;
  }
  #home-products .list-product--item .product-block .product-desc {
    font-size: 13px;
    line-height: 15px;
    padding-bottom: 13px;
  }
  #home-products .list-product--item .product-block .product-price {
    font-size: 13px;
  }
  #home-products .list-product--item .product-block .product-link {
    font-size: 11px;
  }
  #home-products .list-product--more .more-block-full .more-title {
    font-size: 40px;
  }
  #home-products .list-product--more .more-block-item {
    height: 356.4px;
    width: 252px;
  }
  #home-products .list-product--more .more-block-item .more-title {
    font-size: 25px;
  }
  #home-products .list-product--more .more-block-item a {
    width: 80%;
    font-size: 18px;
    padding-top: 12px;
  }
}
@media (max-width: 1125px) {
  #home-products .list-product--item {
    height: 297px;
    width: 206px;
  }
  #home-products .list-product--item img {
    height: 297px;
    width: 206px;
  }
  #home-products .list-product--item .product-block {
    height: 150px;
    width: 210px;
    padding: 0 20px;
  }
  #home-products .list-product--item .product-block .product-name {
    font-size: 13px;
    padding-bottom: 7px;
  }
  #home-products .list-product--item .product-block .product-desc {
    font-size: 10px;
    padding-bottom: 14px;
    line-height: 12px;
  }
  #home-products .list-product--item .product-block .product-price {
    font-size: 11px;
  }
  #home-products .list-product--item .product-block .product-link {
    font-size: 9px;
  }
  #home-products .list-product--more .more-block-full .more-title {
    font-size: 40px;
  }
  #home-products .list-product--more .more-block-full .more-button {
    width: 35%;
    font-size: 20px;
  }
  #home-products .list-product--more .more-block-item {
    height: 297px;
    width: 206px;
  }
  #home-products .list-product--more .more-block-item .more-title {
    font-size: 25px;
  }
  #home-products .list-product--more .more-block-item .more-button {
    width: 80%;
    font-size: 18px;
  }
}
@media (max-width: 500px) {
  #home-products .list-product {
    justify-content: center;
  }
  #home-products .list-product--item {
    height: 356.4px;
    width: 248px;
  }
  #home-products .list-product--item img {
    height: 356.4px;
    width: 252px;
  }
  #home-products .list-product--item .product-block {
    width: 252px;
  }
  #home-products .list-product--item .product-block .product-name {
    font-size: 16px;
  }
  #home-products .list-product--item .product-block .product-desc {
    font-size: 13px;
    line-height: 14px;
    padding-bottom: 16px;
  }
  #home-products .list-product--item .product-block .product-price {
    font-size: 14px;
  }
  #home-products .list-product--item .product-block .product-link {
    font-size: 10px;
  }
}
/* ===== ASSETS ====== */
#assets {
  min-height: 50vh;
}
@media screen and (max-width: 768px) {
  #assets {
    background-color: rgba(4, 131, 163, 0.05);
    border-top-left-radius: 119px;
    border-top-right-radius: 38px;
    border-bottom-left-radius: 92px;
    border-bottom-right-radius: 43px;
  }
}
/* ===== HOME - FUNCTIONING ====== */
#home-functioning {
  background-color: #fef7f4;
  text-align: center;
  border-top-right-radius: 150px;
  border-bottom-right-radius: 43px;
}
@media screen and (max-width: 576px) {
  #home-functioning {
    background: none;
  }
  #home-functioning div[class~="col-12"] {
    padding: 0;
  }
}
#home-functioning .title-container {
  transform: translateX(-15%);
}
@media screen and (max-width: 768px) {
  #home-functioning .title-container .subtitle {
    padding-right: 9vw !important;
  }
}
@media screen and (max-width: 576px) {
  #home-functioning .title-container .subtitle {
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 576px) {
  #home-functioning .title-container {
    transform: translateX(0);
  }
  #home-functioning .title-container div[class~="col-12"] {
    padding: 0;
  }
}
#home-functioning .steps-container {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
#home-functioning .phone-spoors-container {
  background-image: url("../img/spoors-phone.svg");
  background-position: center;
  transform: scale(0.9);
}
@media screen and (max-width: 576px) {
  #home-functioning .phone-spoors-container {
    background-position: top;
    transform: scale(1);
  }
}
#home-functioning .phone-spoors-2-container {
  background-image: url("../img/spoors-phone-2.svg");
  transform: scale(1.2);
}
@media screen and (max-width: 576px) {
  #home-functioning .phone-spoors-2-container {
    background-image: url("../img/spoors-phone-3.svg");
    transform: scale(1);
  }
}
#home-functioning .phone-spoors-container,
#home-functioning .phone-spoors-2-container {
  background-size: contain;
  background-repeat: no-repeat;
}
#home-functioning .arrow-container {
  height: 50px;
  background-image: url("../img/arrow-wth-shadow.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: scale(5);
}
@media screen and (max-width: 576px) {
  #home-functioning .arrow-container {
    transform: scale(10);
  }
}
/* ===== HOME - INSTALLATION ====== */
#home-installation .steps-container {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
#home-installation .home-installation-step {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0;
}
#home-installation .home-installation-step .highlight {
  color: #f75c02;
}
/* ===== HOME - CURIOUS ====== */
.curious-container {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: #0483a3;
}
.curious-container div.blue-container {
  background-color: red !important;
}
.curious-container div.blue-container .title {
  font-family: montserrat-bold, sans-serif !important;
  font-weight: bold !important;
}
@media screen and (min-width: 992px) {
  .curious-container {
    margin-top: 8vh;
  }
}
/* ===== HOME - CGV ====== */
#home-cgv {
  right: 26vw;
  z-index: 100;
}
@media screen and (max-width: 450px) {
  #home-cgv {
    right: 2vw;
  }
}
#home-cgv img {
  padding: 20px;
  cursor: pointer;
}
#home-cgv-content .cgu {
  padding-top: 50px;
}
/* home.less
Carousel
            ================================================== */
.carousel {
  width: 100%;
}
.carousel .carousel-item {
  height: 500px;
  overflow: hidden;
}
.carousel .carousel-item > img {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1.35);
}
@media (max-width: 576px) {
  .carousel .carousel-item > img {
    transform: translate(-50%, -50%) scale(1);
  }
}
@media (max-width: 576px) {
  .carousel .carousel-item .btn-carousel {
    margin-top: 30px;
    transform: translateY(60px);
  }
}
.carousel ol {
  margin-bottom: 25px;
}
.carousel ol li {
  width: 8px;
  height: 8px;
  background-color: #0483a3;
  opacity: 1;
  transition: background-color 0.6s ease;
  border-radius: 50%;
  border-top: 0;
  border-bottom: 0;
}
.carousel ol .active {
  opacity: 1;
  background-color: #a4a4a4;
}
.carousel .carousel-caption {
  position: absolute;
  right: 0;
  top: 50%;
  left: 0;
  z-index: 1;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: left;
  transform: translateY(-50%);
  bottom: initial;
}
@media (max-width: 576px) {
  .carousel .carousel-caption .btn {
    font-size: 18px;
    line-height: 1;
  }
}
.carousel .carousel-caption .btn-primary:hover,
.carousel .carousel-caption .btn-primary:focus {
  background: #ffffff;
  color: #26c5f0;
}
.carousel .carousel-control-next,
.carousel .carousel-control-prev {
  opacity: 1;
}
.carousel .carousel-control-next-icon,
.carousel .carousel-control-prev-icon {
  width: 30px;
  height: 30px;
}
.carousel .carousel-control-next-icon {
  background-image: url("../img/ico-next.png");
}
.carousel .carousel-control-prev-icon {
  background-image: url("../img/ico-prev.png");
}
.title-img {
  color: #ffffff;
}
@media (max-width: 576px) {
  .title-img {
    padding-top: 75px;
  }
}
.title-img h2,
.title-img h3 {
  margin: 0 0 -1px auto;
  text-align: center;
  line-height: 2.35rem;
}
@media (max-width: 576px) {
  .title-img h2,
  .title-img h3 {
    line-height: 1.5rem;
  }
}
.title-img h2 span,
.title-img h3 span {
  background: #56ccf2;
  padding: 0 25px 2px;
}
@media (max-width: 576px) {
  .title-img h2 span,
  .title-img h3 span {
    padding: 0 5px 2px;
  }
}
.title-img h2 {
  font-size: 36px;
  font-family: "roboto-regular";
}
@media (max-width: 576px) {
  .title-img h2 {
    font-size: 22px;
  }
}
.title-img h3 {
  font-size: 24px;
  font-family: "roboto-light";
}
@media (max-width: 576px) {
  .title-img h3 {
    font-size: 16px;
  }
}
.title-img p {
  text-align: center;
}
.title-img p span {
  background: #56ccf2;
  padding: 0 25px 2px;
}
.btn-more {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5);
  border-color: #ffffff;
  padding: 0;
  padding-right: 10px;
}
.btn-more img {
  width: 40px;
}
@media (max-width: 576px) {
  .btn-more img {
    width: 29px;
  }
}
.btn-more span {
  color: #ffffff;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-family: "roboto-light";
  font-size: 20px;
}
@media (max-width: 576px) {
  .btn-more span {
    font-size: 14px;
  }
}
.btn-more:hover,
.btn-more:focus {
  color: #0483a3;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-more:hover span,
.btn-more:focus span {
  color: #a4a4a4;
}
.btn-more:hover img,
.btn-more:focus img {
  filter: invert(0.4);
}
/* home.less
Block Carte
            ================================================== */
.card-model {
  color: #ffffff;
}
.card-model img {
  width: 25%;
}
@media (max-width: 576px) {
  .card-model img {
    width: 100%;
  }
}
/* home.less
Block Fonctionnement
            ================================================== */
.fonct span {
  z-index: 1;
  position: absolute;
  display: inline-block;
  height: 100px;
  width: 100px;
  vertical-align: middle;
  background: #e8e8e8;
  font-size: 50px;
  line-height: 100px;
  font-family: "roboto-medium";
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 576px) {
  .fonct span {
    font-size: 26px;
    line-height: 52px;
    height: 52px;
    width: 52px;
    font-family: "roboto-regular";
    top: 25px;
  }
}
.fonct .block-step {
  display: block;
  margin: 55px auto 30px;
  overflow: hidden;
  border-radius: 40px;
  position: relative;
}
@media (max-width: 576px) {
  .fonct .block-step {
    border-radius: 41px;
  }
}
.fonct .block-step img {
  width: 100%;
}
/* home.less
Block Installation
            ================================================== */
.instal {
  position: relative;
}
.instal::before {
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("../img/spoors-face.png");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 50%;
  position: absolute;
  right: 5%;
  bottom: 0;
  opacity: 0.25;
}
@media (max-width: 576px) {
  .instal::before {
    background-position: center bottom;
    background-size: 90%;
    right: inherit;
  }
}
.instal ol {
  margin-top: 15%;
}
.instal ol li {
  margin: 50px 30px 0;
}
@media (max-width: 576px) {
  .instal ol li {
    margin: 20px 10px 0;
    padding: 0;
  }
}
.instal ol li > span {
  font-family: "roboto-medium";
}
@media (max-width: 576px) {
  .instal img {
    width: 100%;
  }
}
/* home.less
Block Atout
            ================================================== */
@media (max-width: 576px) {
  .atout img {
    width: 50%;
  }
}
/* home.less
Block Vignette
            ================================================== */
.vignette img {
  width: 25%;
}
@media (max-width: 576px) {
  .vignette img {
    width: 50%;
  }
}
/* ===== IMPORT ====== */
/* ===== FORM ====== */
div.form-container {
  width: 100%;
  min-height: 20px;
  border-radius: 40px;
  background-color: rgba(13, 76, 91, 0.05);
  padding: 15px 60px 25px 60px;
  margin: 35px 0;
}
div.form-container h3 {
  font-family: worksans-bold, sans-serif !important;
  font-weight: bold !important;
  font-size: 24px;
  color: #0483a3;
  text-align: left;
}
.neverTopMargin {
  margin-top: 0px !important;
}
/* form.less
Bouton radio - basic
            ================================================== */
.g-recaptcha > div {
  margin: auto !important;
}
/* form.less
Bouton radio - basic
            ================================================== */
form {
  font-family: "roboto-light";
}
.form-check {
  position: relative;
  padding-left: 25px;
}
.form-check input[type="radio"].basic {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.form-check input[type="radio"].basic,
.form-check input[type="radio"].basic ~ label:after,
.form-check input[type="radio"].basic ~ label:before {
  left: 0;
  top: 5px;
}
.form-check input[type="radio"].basic ~ label::before {
  display: block;
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  border: 1px solid #0483a3;
  background: #0483a3;
  border-radius: 50%;
}
.form-check input[type="radio"].basic ~ label::after {
  display: block;
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  border: 1px solid #0483a3;
  background: #ffffff;
  border-radius: 50%;
  transform: scale(1);
  transition: transform 0.25s ease 0s;
}
.form-check input[type="radio"].basic:checked ~ label::after {
  transform: scale(0.45);
}
/* form.less
Bouton radio - icones
            ================================================== */
.form-check {
  position: relative;
  padding-left: 25px;
}
form div.block-support {
  margin-top: 80px;
}
.up-block {
  transform: translateY(-45px);
}
/* form.less
Bouton radio - icones Plage horaires
            ================================================== */
.form-check.contact-horaires {
  position: relative;
  padding: 0 15px;
}
.form-check.contact-horaires input[type="radio"].ico-phone,
.form-check.contact-horaires input[type="radio"].ico-mail {
  position: absolute;
  z-index: -1;
  opacity: 0;
  top: 0;
}
.form-check.contact-horaires input[type="radio"].ico-phone ~ label::after,
.form-check.contact-horaires input[type="radio"].ico-mail ~ label::after,
.form-check.contact-horaires input[type="radio"].ico-phone ~ label::before,
.form-check.contact-horaires input[type="radio"].ico-mail ~ label::before {
  display: block;
  content: "";
  position: absolute;
  width: 46px;
  height: 46px;
  top: 0;
  background: #e8e8e8;
}
.form-check.contact-horaires input[type="radio"].ico-phone ~ label::before,
.form-check.contact-horaires input[type="radio"].ico-mail ~ label::before {
  left: 55%;
  border: 1px solid #e8e8e8;
  background: #e8e8e8;
  border-radius: 50%;
  transform: scale(1);
  transition: all 0.25s ease 0s;
}
.form-check.contact-horaires input[type="radio"].ico-phone ~ label::after,
.form-check.contact-horaires input[type="radio"].ico-mail ~ label::after {
  left: 55%;
  border: 1px solid transparent;
  background: transparent;
  background-size: 45%;
  background-position: center center;
  background-repeat: no-repeat;
  transform: scale(1);
  transition: all 0.25s ease 0s;
}
.form-check.contact-horaires input[type="radio"].ico-phone:checked ~ label,
.form-check.contact-horaires input[type="radio"].ico-mail:checked ~ label {
  padding-top: 70px;
  color: inherit;
}
.form-check.contact-horaires input[type="radio"].ico-phone:checked ~ label::before,
.form-check.contact-horaires input[type="radio"].ico-mail:checked ~ label::before {
  background: #26c5f0;
  border-color: #26c5f0;
  transform: scale(1.2);
}
.form-check.contact-horaires input[type="radio"].ico-phone:checked ~ label::after,
.form-check.contact-horaires input[type="radio"].ico-mail:checked ~ label::after {
  transform: scale(1.2);
}
.form-check.contact-horaires input[type="radio"].ico-phone:checked ~ label span,
.form-check.contact-horaires input[type="radio"].ico-mail:checked ~ label span {
  font-family: "roboto-bold";
}
.form-check.contact-horaires input[type="radio"].ico-phone.ico-phone ~ label::before,
.form-check.contact-horaires input[type="radio"].ico-mail.ico-phone ~ label::before {
  left: 15%;
}
.form-check.contact-horaires input[type="radio"].ico-phone.ico-phone ~ label::after,
.form-check.contact-horaires input[type="radio"].ico-mail.ico-phone ~ label::after {
  left: 15%;
  background-image: url("../img/phone.png");
}
.form-check.contact-horaires input[type="radio"].ico-phone.ico-phone:not(:checked) ~ label:after,
.form-check.contact-horaires input[type="radio"].ico-mail.ico-phone:not(:checked) ~ label:after,
.form-check.contact-horaires input[type="radio"].ico-phone.ico-phone:not(:checked) ~ label:before,
.form-check.contact-horaires input[type="radio"].ico-mail.ico-phone:not(:checked) ~ label:before {
  z-index: 10;
}
.form-check.contact-horaires input[type="radio"].ico-phone.ico-phone:not(:checked) ~ div.phone,
.form-check.contact-horaires input[type="radio"].ico-mail.ico-phone:not(:checked) ~ div.phone {
  display: none;
}
.form-check.contact-horaires input[type="radio"].ico-phone.ico-phone:checked ~ label::after,
.form-check.contact-horaires input[type="radio"].ico-mail.ico-phone:checked ~ label::after {
  background-image: url("../img/phone-white.png");
}
.form-check.contact-horaires input[type="radio"].ico-phone.ico-phone:checked ~ div.phone,
.form-check.contact-horaires input[type="radio"].ico-mail.ico-phone:checked ~ div.phone {
  display: block;
}
.form-check.contact-horaires input[type="radio"].ico-phone.ico-mail ~ label::after,
.form-check.contact-horaires input[type="radio"].ico-mail.ico-mail ~ label::after {
  background-image: url("../img/alt.png");
}
.form-check.contact-horaires input[type="radio"].ico-phone.ico-mail:not(:checked) ~ div.mail,
.form-check.contact-horaires input[type="radio"].ico-mail.ico-mail:not(:checked) ~ div.mail {
  display: none;
}
.form-check.contact-horaires input[type="radio"].ico-phone.ico-mail:checked ~ label::after,
.form-check.contact-horaires input[type="radio"].ico-mail.ico-mail:checked ~ label::after {
  background-image: url("../img/alt-white.png");
}
.form-check.contact-horaires input[type="radio"].ico-phone.ico-mail:checked ~ div.mail,
.form-check.contact-horaires input[type="radio"].ico-mail.ico-mail:checked ~ div.mail {
  display: block;
}
.block-contact-number .txt-mail {
  font-size: 16px !important;
  margin: 0 -35px !important;
}
.carton-body .txt-mail {
  font-size: 16px !important;
}
/* form.less
Form-row - block formulaire fond bleu
            ================================================== */
.contact .form-control {
  background: #ffffff;
  border: 1px solid #0483a3;
  border-radius: 10px;
  padding: 4px 15px;
  font-family: montserrat, sans-serif;
}
.contact .form-control ~ label {
  margin: 4px 0;
}
.contact .form-control:not(:placeholder-shown) ~ label,
.contact .form-control:focus ~ label {
  top: -21px !important;
}
/* form.less
Form-row - block formulaire fond bleu
            ================================================== */
.form-row {
  margin-top: 40px;
}
.form-row div.trash {
  position: absolute;
  right: 0;
  top: -30px !important;
}
.form-group {
  padding-bottom: 25px;
  margin-bottom: 0;
}
.form-group input.form-control,
.form-group textarea.form-control {
  font-family: montserrat, sans-serif;
}
.form-group input.form-control ~ label,
.form-group textarea.form-control ~ label {
  position: absolute;
  top: 0;
  left: 10px;
  padding: 0 15px;
  color: #a4a4a4;
  transform-origin: 0 0;
  transform: scale(1);
  transition: all 0.25s ease 0s;
}
.form-group input.form-control:not(:placeholder-shown) ~ label,
.form-group textarea.form-control:not(:placeholder-shown) ~ label,
.form-group input.form-control:focus ~ label,
.form-group textarea.form-control:focus ~ label {
  color: #0483a3;
  top: -15px;
  left: 10px;
  transform: scale(0.75);
}
/* form.less
Champ text + Champ textarea
            ================================================== */
.form-control,
.custom-select {
  background-color: transparent;
  border: 0px;
  border-bottom: 1px solid #202020;
  border-radius: 0;
  height: auto;
  padding: 0 15px;
}
.form-control [disabled],
.custom-select [disabled] {
  color: #a4a4a4;
}
.form-control select,
.custom-select select {
  outline: 0;
  width: 100%;
}
.form-control::placeholder,
.custom-select::placeholder {
  color: transparent !important;
}
.form-control option:disabled,
.custom-select option:disabled {
  color: #a4a4a4;
}
.form-control:focus,
.custom-select:focus {
  box-shadow: none;
  background-color: transparent;
  border-color: #0483a3;
  outline: 0;
  color: #0483a3;
}
.category-title {
  background-color: transparent;
  border: 0px;
  height: auto;
  padding: 0 24px;
  font-size: 1.4rem;
  font-weight: 800;
  color: #0d4c5b;
  width: 100%;
  margin-bottom: 0rem;
  font-family: montserrat, sans-serif;
}
.form-category-title.neverTopMargin {
  margin-top: 0px !important;
}
.form-category-title {
  margin-bottom: 15px;
  position: relative;
  font-size: 1.2rem;
  margin-top: 60px !important;
}
.form-category-title .trash {
  position: absolute;
  right: 20px;
  top: -5px;
  z-index: 100;
  padding-bottom: 20px;
}
.form-category-title input.form-profil-information,
.form-category-title textarea.form-profil-information {
  /*&:not(:placeholder-shown) ~ label,
		&:focus ~ label {
			color: #0483a3;
			top: -30px;
			left: 10px;
		}*/
}
.form-category-title input.form-profil-information ~ label,
.form-category-title textarea.form-profil-information ~ label {
  position: absolute;
  top: -30px;
  left: 10px;
  padding: 0 24px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #0483a3;
  transform-origin: 0 0;
  transition: all 0.25s ease 0s;
}
.form-label {
  position: absolute;
  top: -30px;
  left: 10px;
  padding: 0 24px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #0483a3;
  transform-origin: 0 0;
  transition: all 0.25s ease 0s;
}
.form-select-countries {
  margin: 10px 0 10px 39px;
  border: 0;
  background-color: transparent;
  font-size: 1rem;
  color: #0483a3;
  cursor: pointer;
}
.form-profil-information {
  background-color: transparent;
  border: 0px;
  border-bottom: 1px solid #202020;
  margin-left: 34px;
  padding: 0 14px;
  font-size: 1.2rem;
  width: 82%;
  color: #0483a3;
  padding-bottom: 10px;
}
.form-profil-information select {
  outline: 0;
  width: 100%;
}
.form-profil-information:focus {
  box-shadow: none;
  background-color: transparent;
  color: #0483a3;
  border-color: #0483a3;
  outline: 0;
}
.form-profil-information::placeholder {
  font-style: italic;
  font-size: 1.2rem;
}
.profil-information {
  background-color: transparent;
  border: 0px;
  height: auto;
  padding: 0 34px 12px;
  font-size: 1.4rem;
  font-weight: 500;
  color: #0483a3;
  font-family: montserrat, sans-serif;
}
.deconnexion-globale {
  padding-top: 30px;
  font-size: 22px;
  font-weight: 800;
  color: #0d4c5b;
}
.deconnexion-globale-info {
  font-size: 12px;
  font-weight: 400;
  color: #0d4c5b;
}
.btn-deconnexion-globale {
  background-color: transparent;
  border: 1px solid #db3c3b;
  height: 40px;
  width: 100%;
}
.btn-deconnexion-globale span {
  color: #db3c3b !important;
}
/* code nicolasC */
.formControlStep {
  border-bottom: 1px solid #20202070;
  margin: 0 20px 0 20px;
  width: 90%;
}
.inputInfo {
  border-bottom: none;
  color: #0d4c5b !important;
  font-size: 1.2rem;
}
.labelInfo {
  color: #0d4c5b !important;
}
textarea.form-control {
  border: 1px solid #a4a4a4;
  border-radius: 5px;
}
/* form.less
Bouton select - font blanc
            ================================================== */
.number {
  padding-bottom: 10px;
}
.custom-select.number {
  border: none;
  position: relative;
  left: 0;
  padding-bottom: 0;
}
.custom-select.number select {
  display: inline-block;
  width: 50%;
  cursor: pointer;
  padding: 5px 15px;
  outline: 0;
  border: 0.5px solid #0483a3;
  border-radius: 9px;
  background: #ffffff;
  color: #202020;
}
@media (max-width: 576px) {
  .custom-select.number select {
    width: 75%;
    padding: 5px 5px;
    background: transparent;
  }
}
.custom-select.number select::-ms-expand {
  display: none;
}
/* form.less
Bouton checkbox
            ================================================== */
.custom-checkbox {
  position: relative;
  padding-left: 25px;
}
.custom-checkbox input[type="checkbox"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-checkbox input[type="checkbox"],
.custom-checkbox input[type="checkbox"] ~ label:after,
.custom-checkbox input[type="checkbox"] ~ label:before {
  left: -25px;
  top: 3px;
}
.custom-checkbox input[type="checkbox"] ~ label::before,
.custom-checkbox input[type="checkbox"] ~ label::after {
  display: block;
  content: "";
  position: absolute;
  width: 17px;
  height: 17px;
  border: 1px solid #0483a3;
  background-color: #ffffff;
  border-radius: 3px;
}
.custom-checkbox input[type="checkbox"] ~ label::after {
  background-image: url("../img/check.png");
  background-color: #0483a3;
}
.custom-checkbox input[type="checkbox"]:not(:checked) ~ label::after {
  opacity: 0;
  transition: opacity 0.25s ease 0s;
}
.custom-checkbox input[type="checkbox"]:checked ~ label::after {
  opacity: 1;
}
.custom-checkbox input[type="checkbox"]:focus,
.custom-checkbox input[type="checkbox"]:focus ~ label::before {
  outline: none;
  box-shadow: none;
}
/*  form.less
Champ recherche
            ================================================== */
div.input-recherche {
  position: relative;
}
div.input-recherche::before {
  content: url("../img/search.png");
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(18px, -40%);
}
div.input-recherche input[type="search"] {
  border: 1px solid #0d83a3;
  border-radius: 36px;
  padding: 10px;
  padding-left: 40px;
  width: 100%;
}
div.input-recherche input[type="search"]::placeholder {
  color: #a4a4a4 !important;
}
@media (max-width: 576px) {
  div.input-recherche input[type="search"] {
    padding: 5px 5px 5px 35px;
  }
}
/* form.less
Module Accordeon - Page FAQ
            ================================================== */
.card {
  border: 0;
}
.card-header {
  margin: 0;
  background: none;
  border: 0;
  padding: 0;
  margin-bottom: 0 !important;
}
.card-header h2 {
  border-bottom: 1px solid #a4a4a4;
  position: relative;
}
.card-header h2 > button.btn.btn-link.btn-block {
  padding: 0;
  margin: 30px 0 0;
  color: #202020;
  text-decoration: none;
  text-align: left;
}
.card-header h2 > button.btn.btn-link.btn-block::after {
  content: url("../img/arrow-title.png");
  position: absolute;
  bottom: 5%;
  right: 15px;
  transform: rotate(0deg);
  transition: transform 0.25s ease 0s;
}
.card-header h2 > button.btn.btn-link.btn-block.collapsed[aria-expanded="false"]::after {
  transform: rotate(-90deg);
}
.card-header h3 {
  position: relative;
  padding-top: 1rem;
}
.card-header h3 button,
.card-header h3 button:hover {
  text-align: left;
  font-size: 20px;
  line-height: 24px;
  color: #202020;
  text-decoration: none;
  font-family: "roboto-light";
  padding: 0;
  margin: 0;
}
.card-header h3 button:hover,
.card-header h3 button:hover:hover {
  text-decoration: underline;
}
.card-header h3 button::after,
.card-header h3 button:hover::after {
  content: url("../img/arrow.png");
  position: absolute;
  bottom: 5%;
  right: 15px;
  transform: rotate(0deg);
  transition: transform 0.25s ease 0s;
}
.card-header h3 button.collapsed[aria-expanded="false"]::after,
.card-header h3 button:hover.collapsed[aria-expanded="false"]::after {
  transform: rotate(-90deg);
}
.card-body {
  padding: 1rem 0;
}
.accordion {
  margin-bottom: 30px;
}
.accordion ol {
  margin-left: 15px;
}
/* form.less
Dropdown SELECT 
            ================================================== */
.custom-select .dropdown-toggle::after {
  display: none;
}
.custom-select .select.phone {
  margin-top: 56px;
}
.custom-select .select {
  background: #ffffff;
  position: absolute;
  top: 8px !important;
  left: 0% !important;
  padding: 0;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.custom-select .select .dropdown-item {
  padding: 1rem 1.5rem;
}
.custom-select .select .dropdown-item:hover,
.custom-select .select .dropdown-item:focus {
  background: #0483a3;
  color: #ffffff;
}
.custom-select .select .dropdown-item.add {
  text-align: right;
  color: #a4a4a4;
}
.custom-select .select .dropdown-divider {
  background: #e8e8e8;
  margin: 0;
}
/* form.less
Validation des données
            ================================================== */
.valide {
  position: relative;
}
.valide:after {
  content: "";
  background-image: url("../img/check-valid.png");
  background-repeat: no-repeat;
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
}
.custom-select.is-valid,
.was-validated .custom-select:valid,
.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #43cf36;
  background: none;
  background-image: none;
}
.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus,
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  outline: none;
  border-color: transparent;
  box-shadow: none;
  border-bottom-color: #43cf36;
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus,
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #e6233e;
  background: rgba(230, 35, 62, 0.1);
  box-shadow: none;
  background-image: none;
}
.tooltipInfo {
  margin-left: 16px;
  display: inline-block;
  width: 24px;
  height: 24px;
  color: #fff;
  background-color: #073e4b;
  border-radius: 12px;
  text-align: center;
  vertical-align: middle;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.7rem 24px;
  margin-left: 10px;
  font-size: 12px;
  line-height: 1;
  color: #e6233e;
  background-color: transparent;
  border-radius: 0;
}
input[type="checkbox"]:not(required).custom-control-input.is-valid ~ .custom-control-label,
.was-validated input[type="checkbox"]:not(required).custom-control-input:valid ~ .custom-control-label {
  color: #fff;
}
input[type="checkbox"]:not(required).custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated input[type="checkbox"]:not(required).custom-control-input:valid ~ .custom-control-label::before {
  background: inherit;
  border-color: inherit;
}
/* form.less
TimePicker
            ================================================== */
.table-timepicker {
  border-collapse: inherit;
  padding: 0 0.75rem;
}
.table-timepicker .timepicker-group {
  display: inline-block;
}
/* form.less
Select - Scan sonnette
            ================================================== */
.select-mob {
  border: 0.5px solid #0483a3;
  border-radius: 10px;
  padding: 8px;
}
.select-mob select {
  padding: 10px;
  background-color: #ffffff;
  border: 0;
}
/* form.less
Mise a jour - Select durée
            ================================================== */
.maj-cont select {
  text-align-last: center;
  font-size: 22px;
  line-height: 26px;
  font-family: "roboto-regular";
}
/* form.less
Bouton radio - Radio-Number
            ================================================== */
.radio-number {
  max-width: 300px;
  margin: auto;
}
.radio-number .form-check.form-check-inline .form-check-label {
  cursor: pointer;
}
.radio-number .form-check {
  padding: 0;
  margin: 0;
}
.radio-number .form-check input[type="radio"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.radio-number .form-check input[type="radio"] ~ label {
  padding: 6px 14px;
  margin: 0 1rem 1rem;
  font-size: 26px;
  line-height: 30px;
  font-family: "roboto-regular";
  color: #0483a3;
  border: 1px solid #0483a3;
  border-radius: 6px;
  background: transparent;
}
.radio-number .form-check input[type="radio"]:checked ~ label {
  background: rgba(13, 131, 163, 0.2);
}
.radio-number .form-check.other input[type="radio"] ~ label {
  width: 7.5rem;
  font-size: 16px;
}
.radio-number .form-check.other input[type="text"] {
  position: absolute;
  width: 30%;
  top: 10px;
  right: 30px;
  border-color: #0483a3;
  line-height: 16px;
}
/* form.less
Bouton Checkbox - checkbox-block (gestion de contact)
            ================================================== */
.checkbox-block {
  padding: 0;
  margin: 0;
  background-color: #ffffff;
}
.checkbox-block input[type="checkbox"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.checkbox-block input[type="checkbox"] ~ label {
  display: block;
  padding: 1rem 0;
  border: 1px solid #0483a3;
  border-radius: 10px;
  font-size: 22px;
  font-family: "roboto-regular";
  background-color: transparent;
  color: #0483a3;
  transition: all 0.25s ease 0s;
}
.checkbox-block input[type="checkbox"] ~ label::before,
.checkbox-block input[type="checkbox"] ~ label::after {
  display: none;
}
.checkbox-block input[type="checkbox"]:checked ~ label {
  color: #fff;
  background-color: #0483a3;
}
/* form.less
Block numéro contact
            ================================================== */
.counter-bloc {
  position: absolute;
  top: -0.75rem;
  left: -0.5rem;
}
.counter-bloc span {
  display: inline-block;
  border-radius: 50%;
  font-size: 12px;
  line-height: 24px;
  width: 24px;
  text-align: center;
  margin-right: 10px;
}
.block-gestion-contact {
  padding: 0;
  position: relative;
}
.block-gestion-contact .trash {
  position: absolute;
  right: 10px;
  top: 0px;
  z-index: 100;
}
@media screen and (max-width: 450px) {
  .block-gestion-contact .trash {
    top: -25px;
    right: -10px;
  }
  .block-gestion-contact .trash button img {
    height: 18px !important;
  }
}
.block-gestion-contact input[type="number"],
.block-gestion-contact input[type="mail"],
.block-gestion-contact input[type="tel"] {
  padding: 1rem 0;
  color: #202020;
  font-size: 18px;
  line-height: 22px;
  border: 0;
  text-align: center;
  border-bottom: 1px solid #202020;
}
.block-gestion-contact input[type="number"]:focus,
.block-gestion-contact input[type="mail"]:focus,
.block-gestion-contact input[type="tel"]:focus,
.block-gestion-contact input[type="number"]:active,
.block-gestion-contact input[type="mail"]:active,
.block-gestion-contact input[type="tel"]:active {
  color: #0483a3;
  border: 1px solid #0483a3;
}
/* form.less
Block numéro contact
            ================================================== */
.btn-modal {
  padding: 0;
  margin: 0;
  position: absolute;
  top: -15px;
  right: 10px;
  font-size: 16px;
  font-family: "roboto-bold";
  width: 22px;
  line-height: 16px;
  height: 22px;
  box-shadow: none;
}
.droit-attr {
  position: relative;
  font-size: 14px;
  font-family: "roboto-light";
  padding-left: 20px;
}
.modal-body .droit-attr {
  font-size: inherit;
}
.droit-attr::before {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 5px;
}
.droit-attr.adm::before {
  background-color: #43cf36;
}
.droit-attr.utl::before {
  background-color: #d6ae37;
}
.droit-attr.rst::before {
  background-color: #e6233e;
}
.num-attr {
  text-align: left;
}
.num-attr .form-check {
  padding: 20px;
}
.num-attr .form-check input[type="radio"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 0;
}
.num-attr .form-check input[type="radio"] ~ label {
  background-color: #fff;
  border: 1px solid #0483a3;
  color: #0483a3;
  font-size: 18px;
  font-family: "roboto-medium";
  line-height: 31px;
  width: 170px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: all 0.25s ease 0s;
}
.num-attr .form-check input[type="radio"]:checked ~ label {
  background-color: #0483a3;
  color: #ffffff;
}
/* form.less
Block numéro contact
            ================================================== */
input[type="password"] ~ label {
  width: 100%;
}
input[type="password"] ~ label:after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 5px;
}
/* form.less 
Checkbox - Démenagement offre
            ================================================== */
input[type="checkbox"]#elec ~ label,
input[type="checkbox"]#sav ~ label {
  border: 5px solid transparent;
  transition: all 0.25s ease 0s;
}
input[type="checkbox"]#elec:checked ~ label,
input[type="checkbox"]#sav:checked ~ label {
  border: 5px solid #0483a3;
}
/* Icones Document */
/* icones.less
Icones
            ================================================== */
.ico-title,
.ico-title-no-bg {
  display: block;
  width: 150px;
  height: 150px;
  background: #ffffff;
  border-radius: 50%;
  position: relative;
  margin: 0 auto -25px;
}
.ico-title img,
.ico-title-no-bg img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.ico-title img.img-check,
.ico-title-no-bg img.img-check {
  transform: translate(-52.5%, -60%) scale(0.65);
}
.ico-title p,
.ico-title-no-bg p {
  padding: 25px 0;
  margin: 0;
}
.ico-title p .title-ico,
.ico-title-no-bg p .title-ico {
  font-family: "roboto-bold";
  font-size: 36px;
  line-height: 36px;
  color: #0483a3;
}
@media (max-width: 576px) {
  .ico-title p .title-ico,
  .ico-title-no-bg p .title-ico {
    font-size: 36px;
    line-height: 36px;
  }
}
.ico-title p .txt-ico,
.ico-title-no-bg p .txt-ico {
  font-family: "roboto-bold";
  font-size: 20px;
  line-height: 36px;
  color: #0483a3;
}
@media (max-width: 576px) {
  .ico-title p .txt-ico,
  .ico-title-no-bg p .txt-ico {
    font-size: 20px;
    line-height: 36px;
  }
}
.ico-title-no-bg {
  background: none;
  width: 130px;
  height: 120px;
}
.ico-title-no-bg img {
  width: 65%;
}
.ico-shadow {
  display: block;
  width: 166px;
  height: 166px;
  background: #ffffff;
  border-radius: 50%;
  position: relative;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  margin: 0 auto 30px;
}
@media (max-width: 576px) {
  .ico-shadow {
    width: 110px;
    height: 110px;
    top: 10%;
  }
}
.ico-shadow img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}
.ico-shadow .txt-ico {
  font-family: "roboto-bold";
  font-size: 64px;
  line-height: 166px;
  color: #0483a3;
}
@media (max-width: 576px) {
  .ico-shadow .txt-ico {
    font-size: 36px;
    line-height: 110px;
  }
}
/* ===== IMPORT ====== */
/* ===== VARIABLES ====== */
/* ===== MODAL ====== */
#modal {
  position: fixed;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 150;
  background-color: rgba(32, 32, 32, 0.4) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#modal.bg-light {
  background-color: rgba(255, 255, 255, 0.4) !important;
}
/* ===== SIGN IN MODAL - CONTINUE PURCHASE MODAL ====== */
#sign-in-modal,
#continue-purchase-modal,
#continue-updated-modal,
#bank-card-registration-modal,
#cart-updated-modal {
  width: 857px;
  background: #0483a3;
  -webkit-box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25);
  border-radius: 120px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 60px;
  position: relative;
}
@media screen and (max-width: 860px) {
  #sign-in-modal,
  #continue-purchase-modal,
  #continue-updated-modal,
  #bank-card-registration-modal,
  #cart-updated-modal {
    width: 500px !important;
  }
}
@media screen and (max-width: 450px) {
  #sign-in-modal,
  #continue-purchase-modal,
  #continue-updated-modal,
  #bank-card-registration-modal,
  #cart-updated-modal {
    width: 98vw !important;
    height: 460px;
    border-radius: 20px !important;
    padding: 20px 10px;
  }
}
#sign-in-modal .close-button,
#continue-purchase-modal .close-button,
#continue-updated-modal .close-button,
#bank-card-registration-modal .close-button,
#cart-updated-modal .close-button {
  height: 15px;
  width: 15px;
  position: absolute;
  right: 65px;
  top: 35px;
  cursor: pointer;
}
#sign-in-modal .close-button path,
#continue-purchase-modal .close-button path,
#continue-updated-modal .close-button path,
#bank-card-registration-modal .close-button path,
#cart-updated-modal .close-button path {
  fill: #ffffff;
}
@media screen and (max-width: 450px) {
  #sign-in-modal .close-button,
  #continue-purchase-modal .close-button,
  #continue-updated-modal .close-button,
  #bank-card-registration-modal .close-button,
  #cart-updated-modal .close-button {
    top: 15px;
    right: 15px;
  }
}
#sign-in-modal .title,
#continue-purchase-modal .title,
#continue-updated-modal .title,
#bank-card-registration-modal .title,
#cart-updated-modal .title {
  font-family: "montserrat-bold", sans-serif !important;
  font-weight: bold !important;
  font-size: 40px !important;
  width: 723px;
  line-height: 46px;
  color: #ffffff;
  text-transform: none;
  text-align: center;
  margin-top: 20px;
}
@media screen and (max-width: 860px) {
  #sign-in-modal .title,
  #continue-purchase-modal .title,
  #continue-updated-modal .title,
  #bank-card-registration-modal .title,
  #cart-updated-modal .title {
    width: 460px;
    font-size: 26px !important;
    line-height: 34px;
  }
}
@media screen and (max-width: 450px) {
  #sign-in-modal .title,
  #continue-purchase-modal .title,
  #continue-updated-modal .title,
  #bank-card-registration-modal .title,
  #cart-updated-modal .title {
    width: 100% !important;
    font-size: 22px !important;
    line-height: 26px;
  }
}
#sign-in-modal .text,
#continue-purchase-modal .text,
#continue-updated-modal .text,
#bank-card-registration-modal .text,
#cart-updated-modal .text {
  font-family: "montserrat-regular", sans-serif !important;
  font-weight: normal !important;
  font-size: 18px;
  line-height: 24px;
  color: #d1f6ff;
  text-align: center;
  margin: 30px 0;
}
@media screen and (max-width: 860px) {
  #sign-in-modal .text,
  #continue-purchase-modal .text,
  #continue-updated-modal .text,
  #bank-card-registration-modal .text,
  #cart-updated-modal .text {
    font-size: 18px !important;
    line-height: 25px;
  }
}
@media screen and (max-width: 450px) {
  #sign-in-modal .text,
  #continue-purchase-modal .text,
  #continue-updated-modal .text,
  #bank-card-registration-modal .text,
  #cart-updated-modal .text {
    font-size: 14px !important;
    line-height: 20px;
  }
}
@media screen and (max-width: 860px) {
  #sign-in-modal .text,
  #continue-purchase-modal .text,
  #continue-updated-modal .text,
  #bank-card-registration-modal .text,
  #cart-updated-modal .text {
    width: 460px;
  }
}
@media screen and (max-width: 450px) {
  #sign-in-modal .text,
  #continue-purchase-modal .text,
  #continue-updated-modal .text,
  #bank-card-registration-modal .text,
  #cart-updated-modal .text {
    width: 100% !important;
    margin: 10px 0 !important;
  }
}
/* ===== SIGN IN MODAL ====== */
#sign-in-modal {
  height: 560px;
}
#sign-in-modal form {
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 860px) {
  #sign-in-modal form {
    width: 380px;
  }
}
@media screen and (max-width: 450px) {
  #sign-in-modal form {
    width: 100%;
  }
}
#sign-in-modal form input {
  height: 52px;
  background: #ffffff;
  border-radius: 25.5px;
  border: none;
  margin: 10px 0;
  font-family: "worksans-light", sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  width: 475px;
  color: #202020;
}
#sign-in-modal form input::placeholder {
  color: rgba(0, 0, 0, 0.26);
}
@media screen and (max-width: 860px) {
  #sign-in-modal form input {
    width: 100%;
  }
}
@media screen and (max-width: 450px) {
  #sign-in-modal form input {
    width: 100%;
  }
}
#sign-in-modal form .button {
  margin: 20px 0;
  width: 400px;
}
@media screen and (max-width: 860px) {
  #sign-in-modal form .button {
    width: 100%;
  }
}
#sign-in-modal form .error-message {
  height: 20px;
  width: 100%;
  color: #a30142;
  font-family: worksans-bold, sans-serif !important;
  font-weight: bold !important;
  font-size: 15px;
  visibility: hidden;
  text-align: center;
}
#sign-in-modal form .error-message.display {
  visibility: visible;
}
@media screen and (max-width: 450px) {
  #sign-in-modal form .error-message {
    font-size: 10px;
  }
}
/* ===== CONTINUE PURCHASE MODAL ====== */
#continue-purchase-modal,
#continue-updated-modal {
  height: 480px;
}
#continue-purchase-modal .buttons-container,
#continue-updated-modal .buttons-container {
  width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
@media screen and (max-width: 860px) {
  #continue-purchase-modal .buttons-container,
  #continue-updated-modal .buttons-container {
    flex-direction: column !important;
    justify-content: flex-start !important;
  }
}
#continue-purchase-modal .buttons-container span,
#continue-updated-modal .buttons-container span {
  font-family: "montserrat-regular", sans-serif !important;
  font-weight: normal !important;
  font-size: 18px;
  line-height: 24px;
  color: #d1f6ff;
  text-align: center;
}
@media screen and (max-width: 860px) {
  #continue-purchase-modal .buttons-container span,
  #continue-updated-modal .buttons-container span {
    font-size: 18px !important;
    line-height: 25px;
  }
}
@media screen and (max-width: 450px) {
  #continue-purchase-modal .buttons-container span,
  #continue-updated-modal .buttons-container span {
    font-size: 14px !important;
    line-height: 20px;
  }
}
@media screen and (max-width: 860px) {
  #continue-purchase-modal .buttons-container span,
  #continue-updated-modal .buttons-container span {
    margin: 25px 0;
  }
}
/* ===== BANK CARD REGISTRATION MODAL ====== */
#bank-card-registration-modal .card-element-container {
  width: 350px;
  height: 40px;
  margin: 20px 0 40px 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 66px;
  border: 1px solid #073e4b;
}
@media screen and (max-width: 450px) {
  #bank-card-registration-modal .card-element-container {
    width: 100%;
  }
}
#bank-card-registration-modal .card-element-container .StripeElement {
  height: 50%;
  width: 90%;
}
/* ===== CART UPDATED MODAL ====== */
@media screen and (max-width: 450px) {
  #cart-updated-modal {
    height: auto;
  }
}
@media screen and (max-width: 450px) and screen and (max-width: 450px) {
  #cart-updated-modal .buttons-container .button {
    width: 90%;
  }
}
#cart-updated-modal .buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#cart-updated-modal .buttons-container .button {
  margin: 20px;
}
@media screen and (max-width: 450px) {
  #cart-updated-modal .buttons-container .button {
    width: 90%;
  }
}
/* ===== COOKIES MODAL ====== */
#cookies-modal {
  position: relative;
  width: 30vw;
  background-color: #ffffff;
  border-radius: 60px;
  padding: 50px 50px 0 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 1680px) {
  #cookies-modal {
    width: 50vw;
  }
}
@media screen and (max-width: 1024px) {
  #cookies-modal {
    width: 70vw;
  }
}
@media screen and (max-width: 576px) {
  #cookies-modal {
    width: 90vw;
  }
}
#cookies-modal button {
  margin: 35px 0;
}
#cookies-modal svg {
  height: 25px;
  width: 25px;
  cursor: pointer;
  position: absolute;
  top: 50px;
  right: 50px;
}
#cookies-modal .cookies-modal-content {
  width: 100%;
  height: 410px;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
#cookies-modal .cookies-modal-content h1,
#cookies-modal .cookies-modal-content h2 {
  font-family: worksans-bold, sans-serif;
  font-weight: bold;
  margin: 0 0 15px 0;
  padding: 0;
}
#cookies-modal .cookies-modal-content h1 {
  font-size: 1.4rem;
  line-height: 25px;
}
#cookies-modal .cookies-modal-content h1 ~ p {
  max-width: 80%;
}
#cookies-modal .cookies-modal-content p {
  font-family: worksans-light, sans-serif;
  font-weight: lighter;
}
#cookies-modal .cookies-modal-content .cookie-presentation {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
#cookies-modal .cookies-modal-content .cookie-presentation .textual-content {
  max-width: 70%;
}
#cookies-modal .cookies-modal-content .cookie-presentation .textual-content h2 {
  margin-top: 25px;
}
#cookies-modal .cookies-modal-content .cookie-presentation svg,
#cookies-modal .cookies-modal-content .cookie-presentation .toggle {
  position: absolute;
  top: 25px;
  right: 0;
}
#cookies-modal .cookies-modal-content .cookie-presentation svg {
  height: 35px;
  width: 35px;
}
/* modal.less
Modal Cookies
            ================================================== */
.modal {
  background: rgba(13, 76, 91, 0.7);
}
.modal-content {
  border: 1px solid #a4a4a4;
  border-radius: 10px;
  outline: 0;
}
.modal-header {
  padding: 0;
  border-bottom: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: 10;
}
.modal-header .close {
  color: #0d83a3;
  opacity: 1;
  position: absolute;
  margin: 0px;
  right: 0;
  font-family: "roboto-light";
}
.modal-header .close:focus {
  outline: none;
}
.modal-body {
  padding-bottom: 0;
}
.modal-body img {
  margin: 0.5rem;
  margin-bottom: 1.2rem;
}
.modal-body p {
  margin: 0;
}
.modal-footer {
  padding-top: 0;
  padding-bottom: 0;
  border-top: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
/* modal.less
Modal Pop-Up
            ================================================== */
.info-refuse {
  background: #e8e8e8;
  border: 1px solid #a4a4a4;
  border-radius: 10px;
  padding: 12px 21px;
  position: absolute;
  bottom: -105px;
  font-size: 14px;
  text-align: center;
  display: none;
}
:hover ~ .info-refuse {
  display: block;
}
/* INFO ETAPE - Parcours scan _ Document */
/* info-etape.less
Dropdown header
            ================================================== */
.drop-step .dropdown-toggle {
  position: relative;
  margin: 0 2rem;
  width: 100%;
}
.drop-step .dropdown-toggle::after {
  display: none;
}
.drop-step .dropdown-toggle::before {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  content: url("../img/chevron.png");
  z-index: 10;
}
.drop-step .dropdown-toggle .h3 {
  font-size: 14px;
}
.dropdown-menu {
  padding: 0 2rem;
  border: 0;
  background: #0483a3;
  border-radius: 0;
  width: 100%;
  margin-top: 16px;
  transform: translate(0) !important;
  left: inherit !important;
  will-change: inherit !important;
}
.dropdown-menu h2.actif::before {
  display: block;
  position: absolute;
  top: 11px;
  left: 2rem;
  transform: translateY(-50%);
  content: url("../img/chevron.png");
  opacity: 1 !important;
  z-index: 10;
}
.dropdown-menu .h3 {
  font-size: 14px;
}
/* info-etape.less
Icones - phone & mail
            ================================================== */
.ico-phone:before {
  content: "";
  position: absolute;
  width: 21px;
  height: 21px;
  background-image: url("../img/phone.png");
  background-repeat: no-repeat;
  left: 0;
}
.custom-switch {
  padding-left: 0;
  margin-right: 10px;
  width: 120px;
  display: inline-block;
}
.custom-switch .ico-alt:before {
  content: "";
  position: absolute;
  width: 21px;
  height: 21px;
  background-image: url("../img/alt.png");
  background-repeat: no-repeat;
  background-size: cover;
  left: 0;
}
.custom-switch .custom-control-label {
  width: 100%;
}
.custom-switch .custom-control-label::before,
.custom-switch .custom-control-label::after {
  position: absolute;
  top: 25%;
  left: inherit;
  right: 0;
  display: block;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #0483a3;
  box-shadow: none;
}
.custom-switch .custom-control-label::before {
  width: 2.3rem;
  height: 1.2rem;
  border-radius: 2.3rem;
  background-color: #0d4b5b3b;
  border: 1px solid #0d4b5b60;
}
.custom-switch .custom-control-label::after {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  transform-origin: center;
  transform: translateX(-1.15rem) scale(1);
  background-color: #ffffff;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffffff;
  -webkit-transform: translateX(0) scale(2);
  transform: translateX(0) scale(1);
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #0483a3;
  border-color: #0483a3;
  box-shadow: none;
}
.custom-switch .custom-control-input:checked + div {
  display: block;
}
.custom-switch .custom-control-input:not(:checked) ~ .custom-control-label::before {
  box-shadow: none;
}
.custom-switch .custom-control-input:not(:checked) ~ .custom-control-label + div {
  display: none;
}
/* info-etape.less
hr - counter
            ================================================== */
.counter {
  margin-bottom: 1.5rem;
  border-bottom: 1.5px dashed #26c5f0;
  margin-left: 10%;
}
.counter span {
  position: relative;
  display: inline-block;
  transform: translate(-150%, 50%);
  font-size: 12px;
  font-family: "roboto-bold";
  padding: 3px;
  border-radius: 50%;
  width: 24px;
  text-align: center;
}
/* info-etape.less
Icones - poubelle
            ================================================== */
.trash {
  position: absolute;
  right: 10px;
  top: 10px;
}
/* SCAN Document */
/* Block Scan
            ================================================== */
section.scan p {
  font-size: 22px;
  line-height: 26px;
}
/* ===== IMPORT ====== */
/* ===== BLOCK CARD ====== */
.card {
  border-radius: 10px;
}
.card-title {
  margin: 0;
  font-family: montserrat, sans-serif;
  font-size: 26px;
  line-height: 30px;
}
/* ===== TIMER ====== */
div.time-picker {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 7px 0;
}
div.time-picker .prefix {
  font-size: 1.2rem;
  width: 50px;
}
div.time-picker .selector {
  border-radius: 5px;
  padding: 5px;
  margin: 0 15px;
  color: gray;
  border: 1px solid lightgrey;
}
/* ===== ALERTS - DASHBOARD ====== */
#active-alerts-dashboard .title {
  line-height: 32px !important;
}
/* ===== BOARD ====== */
.subtitle-spoors {
  padding: 20px 0;
  text-align: left;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 800;
  font-family: montserrat-bold, sans-serif;
  color: #0d4c5b;
}
.board-bg-building {
  background-color: #ffffff !important;
  color: #0483a3;
  border: 0.3px solid #0483a3;
  margin-bottom: 10px;
  cursor: pointer;
  font-family: montserrat, sans-serif;
}
.board-card-body {
  padding: 15px 0;
  font-family: montserrat, sans-serif;
}
.board-card-title {
  margin: 0;
  font-size: 18px;
  font-family: montserrat, sans-serif;
}
/* ===== CONSUM_SITUATION ====== */
.title-appels-sms {
  font-size: 20px;
  font-weight: 800;
  text-transform: uppercase;
  text-align: left;
  font-family: montserrat-bold, sans-serif;
  color: #0d4c5b;
  padding-top: 30px;
}
.consum-text {
  padding-top: 20px;
  padding-left: 20px;
  color: #0483a3;
  font-size: 18px;
  font-family: montserrat, sans-serif;
}
.consum-text-green {
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
  color: #08a045;
  margin-bottom: 5px;
  font-family: montserrat, sans-serif;
}
.consum-text-orange {
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
  color: #f75c02;
  margin-bottom: 5px;
  font-family: montserrat, sans-serif;
}
.consum-values {
  font-weight: 600;
}
.consum-total {
  font-weight: 400;
}
.board-progressBar-green,
.board-progressBar-orange {
  width: 100%;
  height: 15px;
  border: 0 none;
  background: #cae6ec;
  border-radius: 8.5px;
}
.board-progressBar-green::-webkit-progress-bar,
.board-progressBar-orange::-webkit-progress-bar {
  background: transparent;
}
.board-progressBar-green::-webkit-progress-value {
  border-radius: 8.5px;
  background: #08a045;
}
.board-progressBar-orange::-webkit-progress-value {
  border-radius: 8.5px;
  background: #f75c02;
}
.detail-conso {
  padding-top: 30px;
}
.detail-conso-text {
  font-style: italic;
  font-size: 18px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #0483a3;
  font-family: montserrat-bold, sans-serif;
}
.text-solde-faible {
  color: #f75c02;
  font-family: montserrat-bold, sans-serif;
  font-size: 18px;
}
.text-explication-unite {
  font-family: montserrat-regular, sans-serif;
  color: #0d4c5b;
}
.btn-solde-faible {
  width: 100%;
  background-color: #fff;
  color: #f75c02;
  border: 0.3px solid #f75c02;
  font-size: 17px;
  font-family: montserrat-bold, sans-serif;
  margin-top: 10px;
}
/* ===== ACCOUNT_CONSUM ====== */
.list-row {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
.label-list {
  font-family: montserrat-bold, sans-serif;
  font-size: 22px;
  color: #0483a3;
}
.detail-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 0.5px solid #0483a3;
  color: #0483a3;
  font-size: 16px;
  padding-bottom: 10px;
  font-family: montserrat, sans-serif;
}
.detail-block label {
  cursor: pointer;
}
.label-date {
  width: 100%;
  font-family: montserrat-bold, sans-serif;
}
.label-bell-title {
  width: 50%;
}
.consum-radio-groupe {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-top: 5px;
}
.consum-label-radio {
  font-family: roboto-light;
  font-size: 0.9rem;
  color: #0c4f5e;
  width: 80%;
}
/* ===== PURCHASE_UNITS ====== */
.purchase-box {
  min-height: 250px;
  background: #fffafa;
  margin: 50px 20px 20px;
  border-radius: 30px;
  display: flex;
  align-items: baseline;
}
.img-purchase-box {
  padding: 15px;
}
.img-purchase-box .glasses {
  position: absolute;
  left: 60px;
  top: 78px;
}
.text-purchase-box {
  margin-left: 5px;
  margin-bottom: 15px;
  margin-right: 10px;
  color: #0d4c5b;
}
.units-text {
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 10px;
  font-size: 10px;
  font-family: montserrat, sans-serif;
  color: #0483a3;
}
.units-price-row {
  display: flex;
}
.price-display {
  display: flex;
  justify-content: center;
  width: 100%;
  color: #0d4c5b;
}
.price-display .price {
  font-family: montserrat-bold, sans-serif;
  font-size: 34px;
}
.price-display .currency {
  margin-top: 5px;
  margin-left: 5px;
}
.btn-buy {
  font-family: montserrat-bold, sans-serif;
  font-size: 14px;
  height: 45px;
}
/* CONNEXION Document */
/* connexion.less
Buttons
            ================================================== */
.carton {
  background: #ffffff;
  border-radius: 10px;
}
.carton-head {
  padding: 10px 0;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px 10px 0 0;
}
.carton-body {
  padding: 20px 30px 30px;
  border-width: 1px;
  border-style: solid;
  border-top: 0;
  border-radius: 0 0 10px 10px;
}
.carton-body.body-connexion .form-row {
  padding: 0;
  border: none;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
}
.carton-body.body-connexion .form-row.connexion {
  padding-top: 40px;
}
.carton-body.body-connexion .form-row.connexion label[for="id"] img {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.carton-body.body-connexion .form-row .form-group {
  padding: 0 15px;
  margin-bottom: 20px;
}
.carton-body.body-connexion .form-row .form-group:last-child {
  padding: 0 15px;
}
.carton-body.body-connexion .form-row .form-group .new-mdp {
  margin: 0;
  padding: 0;
  font-size: 10px;
  line-height: 12px;
}
.carton-body.body-connexion .form-row .form-group .new-mdp ~ img.voir-mdp {
  transform: translateY(0) !important;
}
.carton-body.body-connexion .form-row .form-group label {
  padding: 5px 18px;
  border: 1px solid #0483a3;
  border-radius: 10px 0 0 10px;
  margin: 0;
}
.carton-body.body-connexion .form-row .form-group input.form-control {
  background-color: rgba(13, 131, 163, 0.1);
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #0483a3;
  border-left: 0;
  border-radius: 0 10px 10px 0;
  height: inherit;
  line-height: 20px;
  font-family: montserrat, sans-serif;
}
.carton-body.body-connexion .form-row .form-group input.form-control::placeholder {
  color: #a4a4a4 !important;
}
.carton-body.body-connexion .form-row .form-group .password {
  position: relative;
}
.carton-body.body-connexion .form-row .form-group .password img.voir-mdp {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.carton-body.body-connexion .form-row .form-group .invalid-tooltip {
  top: initial;
  bottom: -15px;
  padding: 0;
}
.forget-pw {
  color: #202020;
  text-decoration: underline;
  font-family: "roboto-regular";
  font-size: 10px;
}
img.voir-mdp {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.r-0 {
  right: 0 !important;
}
.spaceSection {
  padding: 50px 0;
}
/* NicolasC */
.forgetPassword {
  font-size: 0.8rem;
  text-decoration: underline !important;
  padding-left: 10px !important;
}
@media screen and (max-width: 576px) {
  .forgetPassword {
    padding-left: 0 !important;
  }
}
.labelLeft {
  padding-left: 0 !important;
}
/* NAV-ONGLET Document */
/* nav-onglet.less
Nav-onglet - Mon-profil
            ================================================== */
a[role="tab"] {
  display: block;
}
a[role="tab"].disabled {
  cursor: default;
}
a[role="tab"].move-away.disabled {
  pointer-events: none;
}
a[role="tab"] div.icon {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #e8e8e8;
  transition: all 0.25s ease 0s;
}
a[role="tab"] div.icon::after {
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;
}
a[role="tab"] div.icon.info::after {
  background-image: url("../img/ico-edit.png");
}
a[role="tab"] div.icon.mdp::after {
  background-image: url("../img/ico-change-mdp.png");
}
a[role="tab"] div.icon.demenagement::after {
  background-image: url("../img/ico-demenage.png");
}
a[role="tab"] div.icon.phone,
a[role="tab"] div.icon.alt {
  width: 45px;
  height: 45px;
  transform: scale(1);
}
a[role="tab"] div.icon.phone::after,
a[role="tab"] div.icon.alt::after {
  width: 45px !important;
  height: 45px !important;
  transform: scale(1);
}
a[role="tab"].active {
  cursor: default;
}
a[role="tab"].active div.icon {
  background-color: #0483a3;
  transition: all 0.25s ease 0s;
}
a[role="tab"].active div.icon.info::after {
  background-image: url("../img/ico-edit-white.png");
}
a[role="tab"].active div.icon.mdp::after {
  background-image: url("../img/ico-change-mdp-white.png");
}
a[role="tab"].active div.icon.demenagement::after {
  background-image: url("../img/ico-demenage-white.png");
}
a[role="tab"].active div.icon.phone,
a[role="tab"].active div.icon.alt {
  width: 45px;
  height: 45px;
  transform: scale(1.2);
  background-color: #0d4c5b;
  transition: all 0.25s ease 0s;
}
a[role="tab"].active div.icon.phone::after,
a[role="tab"].active div.icon.alt::after {
  transform: scale(1.2);
  transition: all 0.25s ease 0s;
}
/* nav-onglet.less
Nav-onglet - Modifications sonnette
            ================================================== */
ul.mod-son,
ul.nav-contact,
.nav-contact {
  position: relative;
  max-width: 20rem;
  margin: auto;
}
ul.mod-son::after,
ul.nav-contact::after,
.nav-contact::after {
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: 1px;
  background-color: #0483a3;
  bottom: 0;
  left: 0;
  transform: scaleX(1.18);
}
ul.mod-son div.icon.bell::after,
ul.nav-contact div.icon.bell::after,
.nav-contact div.icon.bell::after {
  background-image: url("../img/ico-bell.png");
}
ul.mod-son div.icon.user::after,
ul.nav-contact div.icon.user::after,
.nav-contact div.icon.user::after {
  background-image: url("../img/ico-user.png");
}
ul.mod-son div.icon.message::after,
ul.nav-contact div.icon.message::after,
.nav-contact div.icon.message::after {
  background-image: url("../img/ico-message.png");
}
ul.mod-son div.icon.resilie::after,
ul.nav-contact div.icon.resilie::after,
.nav-contact div.icon.resilie::after {
  background-image: url("../img/ico-resilie.png");
}
ul.mod-son div.icon.clock::after,
ul.nav-contact div.icon.clock::after,
.nav-contact div.icon.clock::after {
  background-image: url("../img/ico-clock.png");
}
ul.mod-son div.icon.phone::after,
ul.nav-contact div.icon.phone::after,
.nav-contact div.icon.phone::after {
  background-image: url("../img/phone.png");
  background-size: 50%;
}
ul.mod-son div.icon.alt::after,
ul.nav-contact div.icon.alt::after,
.nav-contact div.icon.alt::after {
  background-image: url("../img/alt.png");
  background-size: 50%;
}
ul.mod-son div.icon.sms::after,
ul.nav-contact div.icon.sms::after,
.nav-contact div.icon.sms::after {
  background-image: url("../img/sms.png");
  background-size: 50%;
}
ul.mod-son .active div.icon.bell::after,
ul.nav-contact .active div.icon.bell::after,
.nav-contact .active div.icon.bell::after {
  background-image: url("../img/ico-bell-white.png");
}
ul.mod-son .active div.icon.user::after,
ul.nav-contact .active div.icon.user::after,
.nav-contact .active div.icon.user::after {
  background-image: url("../img/ico-user-white.png");
}
ul.mod-son .active div.icon.message::after,
ul.nav-contact .active div.icon.message::after,
.nav-contact .active div.icon.message::after {
  background-image: url("../img/ico-message-white.png");
}
ul.mod-son .active div.icon.resilie::after,
ul.nav-contact .active div.icon.resilie::after,
.nav-contact .active div.icon.resilie::after {
  background-image: url("../img/ico-resilie-white.png");
}
ul.mod-son .active div.icon.clock::after,
ul.nav-contact .active div.icon.clock::after,
.nav-contact .active div.icon.clock::after {
  background-image: url("../img/ico-clock-white.png");
}
ul.mod-son .active div.icon.phone::after,
ul.nav-contact .active div.icon.phone::after,
.nav-contact .active div.icon.phone::after {
  background-image: url("../img/phone-white.png");
}
ul.mod-son .active div.icon.alt::after,
ul.nav-contact .active div.icon.alt::after,
.nav-contact .active div.icon.alt::after {
  background-image: url("../img/alt-white.png");
}
ul.mod-son .active div.icon.sms::after,
ul.nav-contact .active div.icon.sms::after,
.nav-contact .active div.icon.sms::after {
  background-image: url("../img/sms-white.png");
}
ul.nav-contact::after {
  display: none;
}
.tunnel-demenagement .carton-body {
  padding: 20px 25px 0;
}
div#carouselModifSonnette.carousel .carousel-item {
  height: inherit;
}
div#carouselModifSonnette .carton-body {
  padding: 20px 25px 30px;
}
div#carouselModifSonnette ol li {
  padding-left: 0;
  width: 22px;
  height: 22px;
  background-color: #0483a3;
  opacity: 0.25;
  transition: opacity 0.25s ease 0s;
}
div#carouselModifSonnette ol li.active {
  opacity: 1;
}
/* nav-onglet.less
Nav-onglet - Tableau mes visites
            ================================================== */
.visite tr {
  border: 0;
}
.visite tr td {
  border: 0;
  padding: 0;
}
.visite tr td:first-child {
  width: 150px;
}
/* nav-onglet.less

            ================================================== */
.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label,
.form-control:disabled,
.form-control[readonly],
.custom-select:disabled {
  color: #202020;
  background-color: transparent;
}
/* OFFRES Document */
/* OFFRES - bloc image
            ================================================== */
.offre-elec {
  background-image: url("../img/offre-elec-engie.png");
}
.offre-sav {
  background-image: url("../img/offre-sav-engie.png");
}
.offre-elec,
.offre-sav {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}
.offre-elec .offre-text,
.offre-sav .offre-text {
  margin-top: 16px;
  margin-bottom: 26px;
}
.offre-elec .offre-text.txt-small,
.offre-sav .offre-text.txt-small {
  margin-top: 42px;
}
.offre-elec .offre-text p,
.offre-sav .offre-text p {
  color: #ffffff;
  padding: 0;
  margin: 0;
}
.offre-elec .offre-text p:first-child,
.offre-sav .offre-text p:first-child {
  font-family: "roboto-bold";
  font-size: 22px;
  line-height: 26px;
}
.btn-offre {
  margin: 0 -5px 0 0;
  font-size: 16px;
  line-height: 18px;
  padding: 10px 9px;
  border-radius: 5px 0 0 5px;
  background: #0483a3;
  display: inline-block;
  color: #ffffff;
}
/* MODAL Document */
/* modal-step.less
================================================== */
#ModaleStep {
  position: absolute;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: rgba(13, 76, 91, 0.7);
}
#ModaleStep .modal-step-content {
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 0px 12px #073e4b;
  border-radius: 20px;
  padding: 25px;
  width: 300px;
}
#ModaleStep .modal-step-content p {
  font-size: 1.2rem;
  font-family: montserrat, sans-serif !important;
  text-align: center;
  color: #0d4c5b;
}
#ModaleStep .modal-step-content button {
  margin: 30px 0;
}
#ModaleStep .modal-step-content button:active {
  background-color: #073e4b !important;
}
#ModaleStep .modal-step-content .big-title {
  font-weight: bold !important;
  margin-top: 30px;
  font-size: 2rem;
  font-weight: 600;
}
#ModaleStep .modal-step-content .big-text {
  font-weight: bold !important;
}
.display-none {
  display: none !important;
}
/* MODAL Document */
/* modal-call.less
================================================== */
#modal-call {
  position: fixed;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #0d4b5b;
  top: 0;
}
#modal-call.modal-call-customize p:first-child {
  margin-bottom: 4rem;
}
#modal-call.modal-call-customize p {
  color: white;
  text-align: center;
  margin-bottom: 1rem;
}
#modal-call.modal-call-customize p.big-number {
  font-size: 3rem;
  font-weight: 100;
  margin-bottom: 7rem;
  line-height: 3.1rem;
}
#modal-call.modal-call-customize p.big-number span {
  font-size: 1.2rem;
}
#modal-call.modal-call-customize button {
  color: red;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  width: 200px;
}
#modal-call.modal-call-customize button img {
  height: 20px;
  margin-right: 10px;
}
.phone-img button {
  border: none;
}
.phone-img .btn-primary,
.phone-img .btn-secondary {
  box-shadow: none;
  width: 270px;
}
@media screen and (min-width: 576px) {
  .phone-img .btn-primary,
  .phone-img .btn-secondary {
    width: 330px;
  }
}
.phone-img .btn-primary img,
.phone-img .btn-secondary img {
  width: 1.5rem !important;
  margin-right: 1rem !important;
}
/* @mobilePhoneScreen: 450px;
@smallScreen: 576px;
@mediumScreen: 768px;
@largeScreen: 992px;
@extraLargeScreen: 1200px; */
@media screen and (max-width: 450px) {
  #btn-call-rattachement .btn-rattachement {
    width: 40% !important;
  }
}
#btn-call-rattachement .btn-rattachement {
  width: 45%;
  margin: 10px;
  padding: 0 10px;
}
#visit-call-back {
  padding-left: 15px;
  padding-right: 15px;
}
#visit-call-back .row:first-child .form-group {
  padding-bottom: 0;
}
#visit-call-back .form-container {
  background: none !important;
  padding: 0 !important;
  margin: 0 !important;
}
#visit-call-back .form-container h3 {
  display: none;
}
#visit-call-back .form-container .w-100 > div:first-child {
  display: none;
}
.color-btn {
  display: flex;
  justify-content: center;
}
.color-btn button {
  width: 270px;
  color: white;
}
.color-btn button:hover {
  color: white;
}
/* ---------- *\
   VisitDone
\* ---------- */
#visitDone p {
  font-size: 1.2rem;
  line-height: 1.5rem;
}
#modal-visit {
  position: absolute;
  display: block;
  top: 0;
  z-index: 10000;
  background-color: red;
}
/* ===== IMPORT ====== */
#modalCookies .modal-content {
  width: 90%;
  margin: 0 auto;
}
#modalCookies .img-spoors img {
  height: 75px;
}
#modalCookies .btn-rattachement {
  background-color: #f2f9fa;
  margin: 40px 0;
  color: #0c4f5e;
}
#modalCookies .btn-rattachement:hover,
#modalCookies .btn-rattachement:active {
  background-color: #ffdeca;
}
#modalCookies p {
  color: #0c4f5e;
}
/* PAGE VIEW */
.margin-title .etape {
  margin-bottom: 5px;
}
.edit-info {
  font-size: 0.75rem;
  line-height: 1rem;
  color: grey;
}
.custom-btn {
  width: 100%;
}
.custom-btn .custom-contact {
  font-size: 1rem !important;
  border-radius: 10px;
  border: 1px dashed #0d4b5b60 !important;
  width: 100% !important;
  margin-top: 0.25rem;
}
.custom-btn .custom-contact img {
  width: 15px;
  margin-right: 10px;
}
#bell-view .bell-btn img {
  margin-left: 0;
}
#bell-view .withNext {
  align-items: center;
}
#bell-view button.edit {
  background-color: #0483a3;
  color: white;
  height: 40px;
}
#bell-view button.edit img {
  width: 15px;
  margin-right: 10px;
  margin-left: 0;
}
#bell-view .title-welcome {
  font-size: 1.2rem;
  margin: 0 10px;
}
#bell-view .btn-rattachement {
  width: 100%;
  color: #0483a3;
}
#bell-view .btn-rattachement .name-edit {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
}
#bell-view .btn-rattachement .name {
  font-size: 1.2rem;
  font-weight: bold;
  color: #0483a3;
}
#bell-view .btn-rattachement button.btn-edit {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  min-width: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0483a3;
}
#bell-view .btn-rattachement button.btn-edit img {
  width: 15px;
}
#bell-view .btn-rattachement .btn-edit-info {
  width: 100%;
  padding: 0 20px;
}
#bell-view .btn-rattachement .btn-edit-info strong > div {
  margin-top: 10px;
}
#bell-view .btn-rattachement .btn-edit-info .tel-edit {
  font-size: 1.2rem;
}
#bell-view .btn-rattachement .btn-edit-info .info-edit {
  margin-left: 10px;
}
#bell-view .btn-rattachement .btn-edit-info .day-edit {
  font-size: 0.9rem;
}
#bell-view .btn-rattachement .btn-name-icon {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
#bell-view .btn-rattachement .btn-name-icon div {
  padding: 0 20px;
  align-items: center;
}
#bell-view .btn-rattachement .btn-name-icon div img {
  height: 25px;
  margin-left: 10px;
}
#bell-view .btn-rattachement .info-edit-undefined {
  color: #bbb;
}
#bell-view .btn-height {
  min-height: 260px;
  flex-direction: column;
}
#bell-view button.btn-border {
  font-size: 1rem;
  border: 1px solid #0483a3;
  background: none;
  color: #0483a3;
  box-shadow: none;
  width: 330px;
}
#bell-view button.trash-border {
  border: 1px solid #e6233e;
  color: #e6233e;
}
@media screen and (max-width: 768px) {
  #bell-view .etape {
    font-size: 1.4rem !important;
  }
  #bell-view button.btn-border {
    width: 270px;
  }
}
/* PAGE EDIT */
#bell-edit .bell-btn img {
  margin-left: 0;
}
#bell-edit .inputTop {
  height: 100px;
  background-color: #0c4f5e;
  display: flex;
  align-items: center;
  font-family: montserrat, sans-serif;
}
#bell-edit .inputTop a img {
  width: 20px;
}
#bell-edit .inputTop img {
  width: 40px;
  cursor: pointer;
}
#bell-edit .inputTop input {
  font-weight: 700;
  font-size: 1.5rem;
  color: white;
  border-bottom: 0px;
  text-align: center;
}
#bell-edit .formControlStep {
  border-bottom: 1px solid #20202070;
  margin: 0;
  width: 100%;
}
#bell-edit .btn-rattachement {
  width: 100%;
  color: #0483a3;
}
#bell-edit .btn-rattachement .name-edit {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-left: 0px;
  padding-right: 10px;
}
#bell-edit .btn-rattachement .name {
  font-size: 1.2rem;
  font-weight: bold;
  color: #0483a3;
}
#bell-edit .btn-rattachement button.btn-edit {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  min-width: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0483a3;
}
#bell-edit .btn-rattachement button.btn-edit img {
  width: 15px;
}
#bell-edit .btn-rattachement .btn-edit-info {
  width: 100%;
  padding: 0 20px;
}
#bell-edit .btn-rattachement .btn-edit-info strong > div {
  margin-top: 10px;
}
#bell-edit .btn-rattachement .btn-edit-info .tel-edit {
  font-size: 1.2rem;
}
#bell-edit .btn-rattachement .btn-edit-info .info-edit {
  margin-left: 10px;
}
#bell-edit .btn-rattachement .btn-edit-info .day-edit {
  font-size: 0.9rem;
}
#bell-edit .btn-rattachement .btn-name-icon {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
#bell-edit .btn-rattachement .btn-name-icon div {
  padding: 0 10px 0 0;
  align-items: center;
}
#bell-edit .btn-rattachement .btn-name-icon div img {
  height: 25px;
  margin-left: 10px;
}
#bell-edit .btn-rattachement .btn-name-icon div:first-child {
  padding: 0;
}
#bell-edit .btn-rattachement .info-edit-undefined {
  color: #bbb;
}
#bell-edit .btn-height {
  min-height: 320px;
  flex-direction: column;
}
#bell-edit .btn-name-icon {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
#bell-edit .btn-name-icon div {
  padding: 0 20px;
  align-items: center;
}
#bell-edit .btn-name-icon div img {
  height: 25px;
  margin-left: 10px;
}
#bell-edit .column-drag-handle {
  margin-right: 0px !important;
  padding-right: 20px !important;
}
#bell-edit button.btn-border {
  font-size: 1rem;
  border: 1px solid #0483a3;
  background: none;
  color: #0483a3;
  box-shadow: none;
  width: 330px;
}
#bell-edit button.trash-border {
  border: 1px solid #e6233e;
  color: #e6233e;
}
@media screen and (max-width: 768px) {
  #bell-edit button.btn-border {
    width: 270px;
  }
}
#bell-edit .scans-title-block {
  display: flex;
  align-items: center;
}
#bell-edit .all-scans-arrow {
  border-radius: 0;
  background: transparent;
  padding: 0;
  min-width: 0;
  width: 25px;
  padding-right: 35px;
}
#bell-edit .all-scans-arrow .arrow {
  transform: translateX(5px) rotate(135deg);
  border: solid #0d4c5b;
  border-width: 0 4px 4px 0;
  padding: 6px;
}
#bell-edit .scans-title {
  margin: 0;
}
#bell-edit .btn-info-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#bell-edit .btn-info-block .edit-info {
  padding: 10px 0;
}
#bell-edit .blacklist-btn-block {
  display: flex;
  align-items: center;
}
#bell-edit .btn-filter-blue {
  background: transparent;
  border: 1px solid #0483a3;
  color: #0483a3;
  margin-right: 10px;
  width: 110px;
  height: 40px;
  font-size: 12px;
  padding: 0;
}
#bell-edit .blacklist-toggle {
  font-size: 14px;
  padding-top: 5px;
}
/* PAGE ALERT */
#bell-alert {
  /* Redirection provisoire - début */
  /* Redirection provisoire - fin */
}
#bell-alert .content-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#bell-alert .content-info img.img-info {
  width: 20px;
  margin-left: 10px;
  margin-bottom: 13px;
  cursor: pointer;
}
#bell-alert .validate-info {
  font-size: 0.8rem;
  color: #0483a3;
  font-weight: 900;
}
#bell-alert .modal-none {
  display: none;
}
#bell-alert .modal-block {
  display: block;
}
#bell-alert #ModaleStep {
  justify-content: flex-start;
}
#bell-alert #ModaleStep .modal-step-content {
  margin-top: 150px;
  width: 350px;
}
#bell-alert .redirect-moment-content img {
  width: 20px;
}
#bell-alert .redirect-element {
  width: 100%;
  padding: 10px 20px;
  cursor: pointer;
  background-color: white;
  color: #bbb;
  display: flex;
  justify-content: space-between;
  border-top: 0.5px;
  border-right: 0.5px;
  border-left: 0.5px;
  border-bottom: 0;
  border-style: solid;
  border-color: #0483a3;
}
#bell-alert .redirect-first {
  border-radius: 10px 10px 0 0;
  border-top: 0.5px;
  border-right: 0.5px;
  border-left: 0.5px;
  border-bottom: 0;
  border-style: solid;
  border-color: #0483a3;
}
#bell-alert .redirect-last {
  border-radius: 0 0 10px 10px;
  border-top: 0.5px;
  border-right: 0.5px;
  border-left: 0.5px;
  border-bottom: 0.5px;
  border-color: #0483a3;
  border-style: solid;
}
#bell-alert .redirect-check {
  color: #0483a3;
}
#bell-alert .bell-btn img {
  margin-left: 0;
}
#bell-alert a#step-revenir {
  min-width: 0;
}
#bell-alert button.btn-border {
  border: 1px solid #0483a3;
  background: none;
  color: #0483a3;
  box-shadow: none;
  width: 330px;
}
#bell-alert button.trash-border {
  border: 1px solid #e6233e;
  color: #e6233e;
}
#bell-alert .alert-title {
  color: #0483a3;
  font-size: 1.2rem;
}
#bell-alert .alert-politique {
  font-size: 0.9rem;
  color: #0c4f5e;
}
#bell-alert .alert-title-off {
  color: #bbb;
  font-size: 1.2rem;
}
#bell-alert .alert-description-off {
  color: #bbb;
}
#bell-alert .alert-week {
  color: #0483a3;
  font-size: 0.9rem;
}
#bell-alert .responsive-toggle {
  text-align: center !important;
}
#bell-alert .custom-switch {
  width: 300px;
  display: inline-block;
  margin-bottom: 15px;
}
#bell-alert .width-week {
  width: 90% !important;
  margin: 8px auto;
}
#bell-alert .content-hour {
  background-color: white;
  padding: 5px 0px;
  border: 0.3px solid #0483a3;
  border-radius: 10px;
}
#bell-alert #timepicker-content {
  width: 90%;
}
@media screen and (min-width: 576px) {
  #bell-alert .responsive-toggle {
    width: 100% !important;
  }
}
@media screen and (max-width: 768px) {
  #bell-alert #timepicker-content {
    width: 90%;
  }
  #bell-alert #timepicker-content .timepicker-group:first-child {
    margin-right: 5px;
  }
  #bell-alert .custom-switch {
    width: 50%;
  }
  #bell-alert .etape {
    font-size: 1.4rem !important;
  }
}
@media screen and (min-width: 768px) {
  #bell-alert .content-hour {
    width: 500px;
    margin: 0 auto;
  }
}
/* ===== IMPORT ====== */
/* ===== ACCOUNT_INVOICES =====*/
.facture-year-row {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.facture-year {
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  color: #0483a3;
  font-family: montserrat, sans-serif;
  padding-left: 5px;
}
.arrow-img {
  margin-right: 10px;
}
.facture-year-block {
  padding-bottom: 20px !important;
  display: flex;
  flex-wrap: wrap;
}
.facture-date {
  width: 100%;
  margin-left: 25px;
  font-weight: 600;
  color: #6595a1;
  font-family: montserrat, sans-serif;
}
.btn-ddl-facture {
  height: 45px;
  width: 50px;
  min-width: 0;
  padding: 0;
  margin-left: 23px;
  background-color: #ffffff;
  border: 0.3px solid #0483a3;
  border-radius: 17px;
}
.facture-num-somme {
  width: 75%;
  display: flex;
  justify-content: space-between;
}
.label-num-facture {
  margin-left: 15px;
  font-size: 15px;
  font-weight: 500;
  color: #0483a3;
  font-family: montserrat, sans-serif;
}
.label-somme-facture {
  margin-left: 25px;
  font-size: 20px;
  font-weight: 600;
  color: #0483a3;
  font-family: montserrat, sans-serif;
}
.label-paye {
  font-size: 15px;
  color: #6595a1;
  font-family: montserrat, sans-serif;
}
.img-add {
  transform: scale(1.1);
  padding-right: 5px;
}
.add-text {
  color: #0483a3;
  font-family: montserrat, sans-serif;
}
.no-invoce {
  font-family: montserrat-bold, sans-serif;
  color: #0483a3;
}
/* ===== ACCOUNT_EMAIL_COMFIRM =====*/
.comfirm_msg {
  color: #0483a3;
  font-family: montserrat, sans-serif;
}
/* ===== IMPORT ====== */
/* ===== CONTACTS =====*/
.btn-contact,
.btn-spoors {
  padding-bottom: 15px;
}
.contact-btn-add {
  width: 100%;
  border: 1px dashed #0483a3;
  border-radius: 10px;
  font-size: 22px;
  font-family: montserrat, sans-serif;
  background-color: transparent;
}
.contact-btn-add span {
  color: #0483a3;
}
.input-top-name {
  font-size: 1.5rem;
  color: white;
  border-bottom: 0px;
  text-align: center;
  margin-top: -15px;
  font-family: montserrat, sans-serif;
}
/* ===== CONTACTS_EDIT =====*/
.btn-del-contact {
  width: 90% !important;
}
.btn-confirm {
  width: 90% !important;
}
.gestion-droits {
  text-decoration: underline;
  font-size: 18px;
  font-weight: 600;
  font-family: montserrat, sans-serif;
}
/* ===== CONTACTS_ROLE =====*/
hr {
  border-top: 0.3px solid #0483a3;
  margin: 0;
}
.role-inputTop {
  height: 135px;
  background-color: #0c4f5e;
  display: flex;
  align-items: center;
  font-family: montserrat, sans-serif;
}
.role-inputTop a img {
  width: 20px;
}
.role-inputTop img {
  width: 40px;
  cursor: pointer;
}
.role-inputTop input {
  font-weight: 700;
  font-size: 1.5rem;
  color: white;
  border-bottom: 0px;
  text-align: center;
}
.role-inputTop label {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.5rem;
  color: white;
  border-bottom: 0px;
  text-align: center;
}
.role-radio-groupe {
  display: flex;
  justify-content: space-between;
}
.role-description {
  margin-left: 20px;
  color: #0483a3;
  font-family: montserrat, sans-serif;
}
.toggle-att-off {
  font-size: 19px;
  font-weight: 600;
  font-family: montserrat, sans-serif;
  color: #0483a3;
  opacity: 0.3;
}
.toggle-att {
  font-size: 19px;
  font-weight: 600;
  font-family: montserrat, sans-serif;
  color: #0483a3;
}
.role-bg-building {
  background-color: #ffffff !important;
  color: #0483a3;
  border: 0.3px solid #0483a3;
  font-family: montserrat, sans-serif;
}
.role-card-title {
  margin: 0;
  font-size: 24px;
  line-height: 30px;
  font-family: montserrat, sans-serif;
}
.role-liste-row {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}
.role-label {
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  color: #0483a3;
  font-family: montserrat, sans-serif;
}
.role-attr {
  text-align: left;
  background-color: #ffffff;
  border: 1px solid #0483a3;
  border-radius: 10px;
  font-family: montserrat, sans-serif;
}
.role-attr .role-form-check {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.role-attr .role-form-check input[type="radio"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 0;
}
.role-attr .role-form-check input[type="radio"] ~ label {
  width: 90%;
  padding: 5px 0;
  color: rgba(4, 131, 163, 0.3);
  font-size: 18px;
  font-family: "roboto-medium";
  line-height: 31px;
  cursor: pointer;
  transition: all 0.25s ease 0s;
}
.role-attr .role-form-check input[type="radio"] ~ p {
  color: rgba(4, 131, 163, 0.3);
  font-size: 16px;
}
.role-attr .role-form-check input[type="radio"]:checked ~ label {
  color: #0483a3;
  padding-bottom: 15px;
}
.role-attr .role-form-check input[type="radio"]:checked ~ p {
  color: #0483a3;
}
.contact-droits-info {
  padding-top: 20px;
  padding-left: 20px;
  color: #0483a3;
  font-size: 18px;
  font-family: montserrat, sans-serif;
}
.role-switch {
  width: 45px;
}
